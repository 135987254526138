import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Chip, CircularProgress, Grid, IconButton, Paper, Popover, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/de';
import { memo, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { withAuthenticationRequired } from 'react-oidc-context';
import { Navigate, useNavigate } from "react-router-dom";
import ErrorDialog from './Dialog/ErrorDialog';
import HelpDialog from './Dialog/HelpDialog';
import InfoDialog from './Dialog/InfoDialog';
import { useAxios } from '../utils/useAxios';
import CustomActionBar from '../utils/CustomActionBar';
import Markdown from 'markdown-to-jsx';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';

const Report = memo(() => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));
    const below1315px = useMediaQuery('(max-width:1315px)');

    const startDate = moment().set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0);
    const [content, setContent] = useState("");
    const [email, setEmail] = useState("");
    const [date, setDate] = useState(startDate);
    const [openDate, setOpenDate] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [textError, setTextError] = useState(false);
    const [displayInfo, setDisplayInfo] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [displayHelp, setDisplayHelp] = useState(false);
    const [maxRows, setMaxRows] = useState(1);
    const [mobileAnchorEl, setMobileAnchorEl] = useState(null);
    const [desktopAnchorEl, setDesktopAnchorEl] = useState(null);

    const mobileOpen = Boolean(mobileAnchorEl);
    const mobileId = mobileOpen ? 'mobile-popover' : undefined;

    const desktopOpen = Boolean(desktopAnchorEl);
    const desktopId = desktopOpen ? 'desktop-popover' : undefined;

    const infoRef = useRef(null);
    const helpRef = useRef(null);
    const errorRef = useRef(null);
    const fileInputRef = useRef(null);
    const photoInputRef = useRef(null);
    const contractInputRef = useRef();
    const conditionInputRef = useRef();
    const displayHelpRef = useRef(null);

    const navigate = useNavigate();

    const { t } = useTranslation();
    const infoMarkdown = `## ${t("requiredInformation")}\n* ${t("accidentDate")}\n* ${t("time")}\n* ${t("accidentLocation")}\n* ${t("accidentStoryline")}\n* ${t("licensePlateForCar")}\n* ${t("policyNummerOrInsuredNameAndInsuredAddress")}\n* ${t("optionalAccidentImages")}\n* ${t("optionalAccidentDocuments")}`;

    useEffect(() => {
        document.title = t("reportTitle");
        const rows = calculateMaxRows();
        setMaxRows(rows);
        //eslint-disable-next-line
    }, [t, attachments, contracts, conditions]);

    useLayoutEffect(() => {
        if (!belowsm) {
            handleMobileClose();
        } else {
            handleDesktopClose();
        }
        if (!belowmd) {
            handleDesktopClose();
        };
        const handleResize = () => {
            const rows = calculateMaxRows();
            setMaxRows(rows);
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('deviceorientation', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('deviceorientation', handleResize);
        };
        //eslint-disable-next-line
    }, [belowsm, belowmd]);

    const axiosInstance = useAxios();

    const handleEmail = (event) => {
        if (validate(event.target.value) || event.target.value === "") {
            setEmailError(false);
        } else {
            setEmailError(true);
        }
        setEmail(event.target.value);
    };

    const handleChange = (event) => {
        setContent(event.target.value);
        setTextError(false);
    };

    const handleDate = newValue => {
        setDate(newValue);
    };

    const handleDateOpen = () => setOpenDate(!openDate);

    const handleDisplayInfo = () => setDisplayInfo(!displayInfo);

    const handleDisplayHelpDialog = () => helpRef.current.open();

    const handleDislayInfoDialog = () => infoRef.current.open();

    const handleFiles = () => fileInputRef.current.click();

    const handlePhotos = () => photoInputRef.current.click();

    const handleContracts = () => contractInputRef.current.click();

    const handleConditions = () => conditionInputRef.current.click();

    const onFileChange = (event) => {
        // console.log(event.target.files)
        if (event.target?.files?.length > 0) {
            const items = [...attachments];
            items.push(...event.target?.files);
            setAttachments(items);
        };
        if (belowsm) {
            handleMobileClose();
        };
        if (belowmd) {
            handleDesktopClose();
        };
    };

    const onContractChange = (event) => {
        if (event.target?.files?.length > 0) {
            const items = [...contracts];
            items.push(...event.target?.files);
            setContracts(items);
        };
        if (belowsm) {
            handleMobileClose();
        };
        if (belowmd) {
            handleDesktopClose();
        };
    };

    const onConditionsChange = (event) => {
        if (event.target?.files?.length > 0) {
            const items = [...conditions];
            items.push(...event.target?.files);
            setConditions(items);
        };
        if (belowsm) {
            handleMobileClose();
        };
        if (belowmd) {
            handleDesktopClose();
        };
    };

    const handleDisplayHelpIn = () => {
        displayHelpRef.current = setTimeout(() => setDisplayHelp(true), 250);
    };

    const handleDisplayHelpOut = () => {
        clearTimeout(displayHelpRef.current);
        setDisplayHelp(false);
    };

    const calculateMaxRows = () => {
        let height = belowsm ? window.innerHeight - 578 : belowmd ? window.innerHeight - 509 : window.innerHeight - 463;

        if (belowsm && (attachments?.length > 0 || contracts?.length > 0 || conditions?.length > 0)) {
            height = window.innerHeight - 624;
        };

        return Math.floor(height / 23);
    };

    const onFileDelete = (i) => {
        const items = [...attachments];
        items.splice(i, 1);
        setAttachments(items);
    }

    const onContractDelete = (i) => {
        const items = [...contracts];
        items.splice(i, 1);
        setContracts(items);
    }

    const onConditionDelete = (i) => {
        const items = [...contracts];
        items.splice(i, 1);
        setConditions(items);
    };

    const validate = (email) => {
        const regex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    const handleMobileClick = (event) => {
        setMobileAnchorEl(event.currentTarget);
    };

    const handleMobileClose = () => {
        setMobileAnchorEl(null);
    };

    const handleDesktopClick = (event) => {
        setDesktopAnchorEl(event.currentTarget);
    };

    const handleDesktopClose = () => {
        setDesktopAnchorEl(null);
    };

    const submit = async () => {
        setLoading(true);

        if (email === "") {
            setEmailError(true);
            setLoading(false);
            return null;
        };

        if (content === "") {
            setTextError(true);
            setLoading(false);
            return null;
        };

        const formData = new FormData();

        const json = {
            "sender": email,
            "sentAt": date.toISOString(),
            "initialMessage": content,
            "conversations": [],
            "version": 1
        }
        const jsonBlob = new Blob([JSON.stringify(json)], { type: 'application/json' });
        formData.append('conversations', jsonBlob, 'conversations.json');
        if (attachments?.length > 0) {
            for (const file of attachments) {
                formData.append('file', file);
            };
        };
        if (contracts?.length > 0) {
            for (const contract of contracts) {
                formData.append('contract', contract);
            };
        };
        if (conditions?.length > 0) {
            for (const condition of conditions) {
                formData.append('conditions', condition);
            };
        };

        await axiosInstance.current({
            url: "/chat/nonblocking",
            method: "POST",
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        }).then((res) => {
            sessionStorage.removeItem("source");
            localStorage.setItem("claim", res.data);
            navigate(`/claim/display/${res.data}`)
        }).catch(err => errorRef.current.open(err.response.status));

        setLoading(false);
    };

    const H2 = ({ children, ...props }) => <h2 {...props} style={{ fontFamily: "Ubuntu", fontSize: 18, textAlign: "center", color: theme.palette.widget.main }}>{children}</h2>
    const P = ({ children, ...props }) => <p {...props} style={{ fontFamily: "Ubuntu", color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary" }}>{children}</p>

    const markdownOptions = {
        overrides: {
            h2: H2,
            p: P
        }
    };

    return (
        <Grid container sx={{ pt: belowsm ? 2 : 2, px: 2, maxWidth: "1440px" }}>
            <Paper
                elevation={2}
                sx={{
                    mt: 1,
                    width: "100%",
                    height: belowsm ? "calc(100vh - 203px)" : belowmd ? "calc(100vh - 228px)" : "calc(100vh - 174px)",
                    overflow: "auto",
                    background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                    borderRadius: "14.362px",
                    boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none",
                }}
            >
                <Grid container justifyContent={belowsm ? "center" : "flex-start"} sx={{ pb: belowmd ? .75 : 0, borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                    <Typography
                        noWrap
                        sx={{
                            color: theme.palette.widget.main,
                            fontSize: belowsm ? "20px" : "24px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                            pt: belowmd ? 1 : belowsm ? 1.75 : 1.5,
                            pl: 1
                        }}
                    >
                        {t("reportClaim")}
                    </Typography>
                    <IconButton onClick={handleDisplayInfo} sx={{ ml: .5, mt: .6, display: belowmd ? "none" : "flex" }}>
                        <InfoOutlinedIcon sx={{ fontSize: "28px", color: theme.palette.widget.main }} />
                    </IconButton>
                </Grid>
                <Box sx={{ p: 1, pb: 0 }}>
                    <Grid container>
                        <Grid item xs={displayInfo && !belowmd ? 8 : 12}>
                            <Grid container sx={{ mb: belowsm ? 1 : .5 }}>
                                <Typography variant="body1" color={theme.palette.mode === "dark" ? "text.primary" : theme.palette.widget.contrastText} sx={{ textAlign: belowsm ? "center" : "start", pl: belowsm ? 0 : .5, flexGrow: belowsm ? 0 : 1, pb: belowsm ? 0 : 2 }}>{t("assessClaim")}</Typography>
                                <Typography onClick={handleDislayInfoDialog} variant="body1" noWrap color={theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main} sx={{ textAlign: "center", display: belowsm ? "block" : "none", flexGrow: 1, textDecoration: "underline", cursor: "pointer" }}>{t("requiredInformation")}</Typography>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={6} sx={{ mb: emailError ? 1 : -0.5, mt: belowsm ? 1 : 0 }}>
                                    <TextField
                                        label={t("emailAddress")}
                                        value={email}
                                        onChange={handleEmail}
                                        color="widget"
                                        fullWidth
                                        required
                                        error={emailError}
                                        helperText={emailError ? t("invalidEmail") : " "}
                                        InputLabelProps={{ shrink: true }}
                                        disabled={loading}
                                        sx={{
                                            "& label": {
                                                color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    borderColor: theme.palette.widget.divider,
                                                },
                                                "&:hover fieldset": {
                                                    borderColor: theme.palette.widget.divider,
                                                },
                                                "&.Mui-focused fieldset": {
                                                    borderColor: theme.palette.widget.divider,
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{ mb: 1, pl: belowsm ? 0 : 1.5 }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
                                        <DatePicker
                                            label={t("submissionDate")}
                                            format="DD.MM.YYYY"
                                            value={date}
                                            onChange={handleDate}
                                            open={openDate}
                                            onClose={handleDateOpen}
                                            disableFuture
                                            disabled={loading}
                                            slots={{
                                                actionBar: CustomActionBar,
                                            }}
                                            slotProps={{
                                                textField: {
                                                    onClick: handleDateOpen,
                                                    InputLabelProps: {
                                                        shrink: true
                                                    },
                                                    inputProps: {
                                                        readOnly: true
                                                    },
                                                    fullWidth: true,
                                                    required: true,
                                                    helperText: belowsm ? "" : displayHelp ? t("displayHelpText") : t("displayHelp"),
                                                    FormHelperTextProps: {
                                                        onMouseOver: () => handleDisplayHelpIn(),
                                                        onMouseOut: () => handleDisplayHelpOut(),
                                                        sx: {
                                                            color: theme.palette.mode === "dark" ? theme.palette.text.secondary : theme.palette.widget.contrastText, // Custom color for helper text
                                                        }
                                                    },
                                                    sx: {
                                                        "& label": {
                                                            color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                                        },
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: theme.palette.widget.divider,
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: theme.palette.widget.divider,
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: theme.palette.widget.divider,
                                                            }
                                                        }
                                                    }
                                                },
                                                day: {
                                                    sx: {
                                                        "&.Mui-selected": {
                                                            bgcolor: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                                        },
                                                    }
                                                },
                                                openPickerIcon: {
                                                    sx: { display: "none" }
                                                },
                                                toolbar: {
                                                    hidden: true
                                                },
                                                mobilePaper: {
                                                    sx: { borderRadius: "14.362px" }
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                    <Button
                                        onClick={handleDisplayHelpDialog}
                                        size="small"
                                        fullWidth
                                        sx={{
                                            display: belowsm ? "inline-flex" : "none",
                                            py: 0,
                                            textTransform: "none",
                                            justifyContent: "flex-start",
                                            color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.6)",
                                            "&:hover": {
                                                color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.6)"
                                            }
                                        }}
                                    >
                                        <Typography fontSize={12} >{t("displayHelp")}</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                            <TextField
                                label={t("claimMessage")}
                                value={content}
                                onChange={handleChange}
                                multiline
                                fullWidth
                                required
                                error={textError}
                                helperText={textError ? t("invalidClaimMessage") : " "}
                                placeholder={t("claimMessagePlaceholder")}
                                rows={maxRows}
                                InputLabelProps={{ shrink: true }}
                                disabled={loading}
                                sx={{
                                    mb: textError ? 1 : -0.5,
                                    "& label": {
                                        color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: theme.palette.widget.divider,
                                        },
                                        "&:hover fieldset": {
                                            borderColor: theme.palette.widget.divider,
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: theme.palette.widget.divider,
                                        }
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} sx={{ pl: 1.5, display: displayInfo && !belowmd ? "flex" : "none" }}>
                            <Box sx={{ pr: 1.5, overflow: "auto", border: 1, borderColor: theme.palette.widget.divider, mt: "44px", height: "calc(100% - 64px)", width: "100%", borderRadius: "6px" }}>
                                <Markdown options={markdownOptions}>
                                    {infoMarkdown}
                                </Markdown>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container columnGap={1} sx={{ mt: -1, ml: .5 }}>
                        <Grid item>
                            <Typography variant="body1" color={theme.palette.mode === "dark" ? "text.primary" : theme.palette.widget.contrastText} sx={{ display: attachments?.length > 0 || contracts.length > 0 || conditions?.length > 0 ? "flex" : "none", mt: .5, mb: 1, flexGrow: belowsm ? 1 : 0 }}>{(attachments?.length === 1 || contracts.length === 1) && attachments?.length + contracts.length <= 1 ? t("attachment") : t("attachments")}</Typography>
                            <Typography variant="body1" color={theme.palette.mode === "dark" ? "text.primary" : theme.palette.widget.contrastText} sx={{ display: attachments?.length > 0 || contracts.length > 0 || conditions?.length > 0 ? "none" : "flex", mt: .5, mb: 1 }}>{t("noAttachments")}</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container columnGap={1} sx={{ width: belowsm ? "calc(100vw - 55px)" : "calc(100vw - 140px)", maxWidth: "1300px", overflow: "auto", flexWrap: "nowrap" }}>
                                {attachments?.map((f, i) => { return <Chip key={`file-${i}`} label={f?.name || t("unknownAttachmentError")} variant={theme.palette.mode === "dark" ? "outlined" : "filled"} onClick={belowsm ? () => onFileDelete(i) : null} onDelete={() => onFileDelete(i)} deleteIcon={<DeleteOutlineIcon />} disabled={loading} sx={{ mb: 1, borderRadius: "4px", maxWidth: displayInfo ? "61.5vw" : "92vw", "& .MuiChip-label": { textOverflow: "ellipsis" } }} /> })}
                                {contracts?.map((f, i) => { return <Chip key={`contract-${i}`} label={f?.name || t("unknownAttachmentError")} variant={theme.palette.mode === "dark" ? "outlined" : "filled"} onClick={belowsm ? () => onContractDelete(i) : null} onDelete={() => onContractDelete(i)} deleteIcon={<DeleteOutlineIcon />} disabled={loading} sx={{ mb: 1, borderRadius: "4px", maxWidth: displayInfo ? "61.5vw" : "92vw", "& .MuiChip-label": { textOverflow: "ellipsis" } }} /> })}
                                {conditions?.map((f, i) => { return <Chip key={`condition-${i}`} label={f?.name || t("unknownAttachmentError")} variant={theme.palette.mode === "dark" ? "outlined" : "filled"} onClick={belowsm ? () => onConditionDelete(i) : null} onDelete={() => onConditionDelete(i)} deleteIcon={<DeleteOutlineIcon />} disabled={loading} sx={{ mb: 1, borderRadius: "4px", maxWidth: displayInfo ? "61.5vw" : "92vw", "& .MuiChip-label": { textOverflow: "ellipsis" } }} /> })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
            <Grid container columnSpacing={belowsm ? 1 : 2} sx={{ my: 2, display: belowsm ? "none" : "flex" }}>
                <Grid item md={2.4} sx={{ display: belowmd && !belowsm ? "none" : "flex" }}>
                    <Button
                        onClick={handlePhotos}
                        disableElevation
                        variant="outlined"
                        size="large"
                        startIcon={<AddAPhotoOutlinedIcon />}
                        fullWidth
                        disabled={loading}
                        sx={{
                            height: "42.25px",
                            textTransform: "none",
                            border: 1.5,
                            borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            "&:hover": {
                                border: 1.5,
                                borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                            }
                        }}>
                        <Typography fontSize="15px" fontWeight={500} noWrap>{(below1315px && !belowsm) ? t("photos") : t("uploadPhotos")}</Typography>
                    </Button>
                </Grid>
                <Grid item md={2.4} sx={{ display: belowmd && !belowsm ? "none" : "flex" }}>
                    <Button
                        onClick={handleFiles}
                        disableElevation
                        variant="outlined"
                        size="large"
                        startIcon={<UploadFileIcon />}
                        fullWidth
                        disabled={loading}
                        sx={{
                            mt: belowsm ? 1 : 0,
                            height: "42.25px",
                            textTransform: "none",
                            border: 1.5,
                            borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            "&:hover": {
                                border: 1.5,
                                borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                            }
                        }}>
                        <Typography fontSize="15px" fontWeight={500} noWrap>{(below1315px && !belowsm) ? t("documents") : t("uploadDocuments")}</Typography>
                    </Button>
                </Grid>
                <Grid item md={2.4} sx={{ display: belowmd && !belowsm ? "none" : "flex" }}>
                    <Button
                        onClick={handleContracts}
                        disableElevation
                        variant="outlined"
                        size="large"
                        startIcon={<DescriptionOutlinedIcon />}
                        fullWidth
                        disabled={loading}
                        sx={{
                            mt: belowsm ? 1 : 0,
                            height: "42.25px",
                            textTransform: "none",
                            border: 1.5,
                            borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            "&:hover": {
                                border: 1.5,
                                borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                            }
                        }}>
                        <Typography fontSize="15px" fontWeight={500} noWrap>{(below1315px && !belowsm) ? t("contracts") : t("uploadContracts")}</Typography>
                    </Button>
                </Grid>
                <Grid item md={2.4} sx={{ display: belowmd && !belowsm ? "none" : "flex" }}>
                    <Button
                        onClick={handleConditions}
                        disableElevation
                        variant="outlined"
                        size="large"
                        startIcon={<InsertDriveFileOutlinedIcon />}
                        fullWidth
                        disabled={loading}
                        sx={{
                            mt: belowsm ? 1 : 0,
                            height: "42.25px",
                            textTransform: "none",
                            border: 1.5,
                            borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            "&:hover": {
                                border: 1.5,
                                borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                            }
                        }}>
                        <Typography fontSize="15px" fontWeight={500} noWrap>{(below1315px && !belowsm) ? t("conditions") : t("uploadConditions")}</Typography>
                    </Button>
                </Grid>
                <Grid item sm={6} sx={{ display: belowmd && !belowsm ? "flex" : "none" }}>
                    <Button
                        aria-describedby={desktopId}
                        onClick={handleDesktopClick}
                        disableElevation
                        variant="outlined"
                        size="large"
                        endIcon={desktopOpen ? <CircularProgress color="inherit" size="20px" /> : <NoteAddOutlinedIcon />}
                        fullWidth
                        disabled={loading}
                        sx={{
                            mt: belowsm ? 1 : 0,
                            height: "42.25px",
                            textTransform: "none",
                            border: 1.5,
                            borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            "&:hover": {
                                border: 1.5,
                                borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                            }
                        }}>
                        <Typography fontSize="15px" fontWeight={500} noWrap>{desktopOpen ? t("chooseAttachment") : t("uploadAttachment")}</Typography>
                    </Button>
                    <Popover
                        id={desktopId}
                        open={desktopOpen}
                        anchorEl={desktopAnchorEl}
                        onClose={handleDesktopClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        sx={{
                            mt: -.5
                        }}
                    >
                        <Grid container sx={{ width: "47vw", p: 1 }}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={handlePhotos}
                                    disableElevation
                                    variant="outlined"
                                    size="large"
                                    startIcon={<AddAPhotoOutlinedIcon />}
                                    fullWidth
                                    disabled={loading}
                                    sx={{
                                        height: "42.25px",
                                        textTransform: "none",
                                        border: 1.5,
                                        borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        "&:hover": {
                                            border: 1.5,
                                            borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                            color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                                        }
                                    }}>
                                    <Typography fontSize="15px" fontWeight={500} noWrap>{t("uploadPhotos")}</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    onClick={handleFiles}
                                    disableElevation
                                    variant="outlined"
                                    size="large"
                                    startIcon={<UploadFileIcon />}
                                    fullWidth
                                    disabled={loading}
                                    sx={{
                                        mt: 1,
                                        height: "42.25px",
                                        textTransform: "none",
                                        border: 1.5,
                                        borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        "&:hover": {
                                            border: 1.5,
                                            borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                            color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                                        }
                                    }}>
                                    <Typography fontSize="15px" fontWeight={500} noWrap>{t("uploadDocuments")}</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    onClick={handleContracts}
                                    disableElevation
                                    variant="outlined"
                                    size="large"
                                    startIcon={<DescriptionOutlinedIcon />}
                                    fullWidth
                                    disabled={loading}
                                    sx={{
                                        mt: 1,
                                        height: "42.25px",
                                        textTransform: "none",
                                        border: 1.5,
                                        borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        "&:hover": {
                                            border: 1.5,
                                            borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                            color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                                        }
                                    }}>
                                    <Typography fontSize="15px" fontWeight={500} noWrap>{t("uploadContracts")}</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    onClick={handleConditions}
                                    disableElevation
                                    variant="outlined"
                                    size="large"
                                    startIcon={<InsertDriveFileOutlinedIcon />}
                                    fullWidth
                                    disabled={loading}
                                    sx={{
                                        mt: 1,
                                        height: "42.25px",
                                        textTransform: "none",
                                        border: 1.5,
                                        borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        "&:hover": {
                                            border: 1.5,
                                            borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                            color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                                        }
                                    }}>
                                    <Typography fontSize="15px" fontWeight={500} noWrap>{t("uploadConditions")}</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Popover>
                </Grid>
                <Grid item sm={6} md={2.4}>
                    <LoadingButton
                        onClick={submit}
                        disableElevation
                        variant="contained"
                        size="large"
                        endIcon={<ArrowCircleRightOutlinedIcon />}
                        fullWidth
                        loading={loading}
                        sx={{
                            mt: belowsm ? 1 : 0,
                            textTransform: "none",
                            fontWeight: 600,
                            bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                        }}>{t("forward")}</LoadingButton>
                </Grid>
            </Grid>
            <Grid container sx={{ mb: 1, mt: .5, display: belowsm ? "flex" : "none" }}>
                <Grid item xs={12}>
                    <Button
                        aria-describedby={mobileId}
                        onClick={handleMobileClick}
                        disableElevation
                        variant="outlined"
                        size="large"
                        endIcon={mobileOpen ? <CircularProgress color="inherit" size="20px" /> : <NoteAddOutlinedIcon />}
                        fullWidth
                        disabled={loading}
                        sx={{
                            mt: belowsm ? 1 : 0,
                            height: "42.25px",
                            textTransform: "none",
                            border: 1.5,
                            borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            "&:hover": {
                                border: 1.5,
                                borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                            }
                        }}>
                        <Typography fontSize="15px" fontWeight={500} noWrap>{mobileOpen ? t("chooseAttachment") : t("uploadAttachment")}</Typography>
                    </Button>
                    <Popover
                        id={mobileId}
                        open={mobileOpen}
                        anchorEl={mobileAnchorEl}
                        onClose={handleMobileClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        sx={{
                            mt: -.5
                        }}
                    >
                        <Grid container sx={{ width: "calc(100vw - 30px)", p: 1 }}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={handlePhotos}
                                    disableElevation
                                    variant="outlined"
                                    size="large"
                                    startIcon={<AddAPhotoOutlinedIcon />}
                                    fullWidth
                                    disabled={loading}
                                    sx={{
                                        height: "42.25px",
                                        textTransform: "none",
                                        border: 1.5,
                                        borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        "&:hover": {
                                            border: 1.5,
                                            borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                            color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                                        }
                                    }}>
                                    <Typography fontSize="15px" fontWeight={500} noWrap>{t("uploadPhotos")}</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    onClick={handleFiles}
                                    disableElevation
                                    variant="outlined"
                                    size="large"
                                    startIcon={<UploadFileIcon />}
                                    fullWidth
                                    disabled={loading}
                                    sx={{
                                        mt: 1,
                                        height: "42.25px",
                                        textTransform: "none",
                                        border: 1.5,
                                        borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        "&:hover": {
                                            border: 1.5,
                                            borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                            color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                                        }
                                    }}>
                                    <Typography fontSize="15px" fontWeight={500} noWrap>{t("uploadDocuments")}</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    onClick={handleContracts}
                                    disableElevation
                                    variant="outlined"
                                    size="large"
                                    startIcon={<DescriptionOutlinedIcon />}
                                    fullWidth
                                    disabled={loading}
                                    sx={{
                                        mt: 1,
                                        height: "42.25px",
                                        textTransform: "none",
                                        border: 1.5,
                                        borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        "&:hover": {
                                            border: 1.5,
                                            borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                            color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                                        }
                                    }}>
                                    <Typography fontSize="15px" fontWeight={500} noWrap>{t("uploadContracts")}</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    onClick={handleConditions}
                                    disableElevation
                                    variant="outlined"
                                    size="large"
                                    startIcon={<InsertDriveFileOutlinedIcon />}
                                    fullWidth
                                    disabled={loading}
                                    sx={{
                                        mt: 1,
                                        height: "42.25px",
                                        textTransform: "none",
                                        border: 1.5,
                                        borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        "&:hover": {
                                            border: 1.5,
                                            borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                            color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                                        }
                                    }}>
                                    <Typography fontSize="15px" fontWeight={500} noWrap>{t("uploadConditions")}</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Popover>
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton
                        onClick={submit}
                        disableElevation
                        variant="contained"
                        size="large"
                        endIcon={<ArrowCircleRightOutlinedIcon />}
                        fullWidth
                        loading={loading}
                        sx={{
                            mt: belowsm ? 1 : 0,
                            textTransform: "none",
                            fontWeight: 600,
                            bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                        }}>{t("forward")}</LoadingButton>
                </Grid>
            </Grid>
            <input
                accept="image/png, image/jpeg, image/jpg"
                type="file"
                multiple
                onChange={onFileChange}
                onClick={event => event.target.value = null}
                ref={photoInputRef}
                hidden
            />
            <input
                accept=".pdf"
                type="file"
                multiple
                onChange={onFileChange}
                onClick={event => event.target.value = null}
                ref={fileInputRef}
                hidden
            />
            <input
                accept=".pdf, .md, .txt"
                type="file"
                multiple
                onChange={onContractChange}
                onClick={event => event.target.value = null}
                ref={contractInputRef}
                hidden
            />
            <input
                accept=".pdf, .md, .txt"
                type="file"
                multiple
                onChange={onConditionsChange}
                onClick={event => event.target.value = null}
                ref={conditionInputRef}
                hidden
            />
            <InfoDialog ref={infoRef} />
            <HelpDialog ref={helpRef} />
            <ErrorDialog ref={errorRef} />
        </Grid>
    );
});

export default withAuthenticationRequired(Report, {
    OnRedirecting: () => {
        const redirect = `${window.location?.pathname ?? ""}${window.location?.search ?? ""}`;
        localStorage.setItem("redirect", redirect);
        <Navigate replace to="/login" />;
    }
});