import { styled, TextField } from "@mui/material";

const WidgetTextField = styled(TextField)(({ theme }) => ({
    "& .MuiFormLabel-root": {
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal"
    },
    "& .MuiInput-root::before": {
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.widget.divider
    },
    "& .MuiInput-root:hover::before": {
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.widget.divider
    },
    "& .MuiInput-root::after": {
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.widget.divider,
        transform: "none"
    }
}));

export default WidgetTextField;