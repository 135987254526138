import { Button, Dialog, DialogActions, DialogTitle, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAxios } from "../../utils/useAxios";
import { LoadingButton } from "@mui/lab";

const ConditionDeleteDialog = forwardRef(({ reset, setRefetch, setLoading, snackBarRef }, ref) => {

    const theme = useTheme();

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [version, setVersion] = useState("");

    const axiosInstance = useAxios();

    useImperativeHandle(ref, () => ({
        delete(version) {
            setOpen(true);
            setVersion(version);
        }
    }));

    const deleteCondition = async () => {
        setLoading(true);
        setDeleteLoading(true);
        await axiosInstance.current({
            url: `/admin/insuranceproductdefinitions/${version}`,
            method: "DELETE"
        }).then(() => snackBarRef.current.custom("warning", t("conditionDeleted", { version: version }))).catch(err => console.error(err)).finally(() => {
            setRefetch(true);
            setLoading(false);
            setDeleteLoading(false);
            setOpen(false);
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: { borderRadius: "14.362px" }
            }}
        >
            <DialogTitle fontWeight={600} sx={{ textAlign: "center" }}>{t("deleteCondition", { version: version })}</DialogTitle>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }} >
                <LoadingButton variant="contained" disableElevation fullWidth loading={deleteLoading} onClick={deleteCondition} sx={{ textTransform: "none", bgcolor: "error.main", "&:hover": { bgcolor: "error.main" } }}>{t("confirm")}</LoadingButton>
                <Button variant="contained" disableElevation fullWidth onClick={handleClose} sx={{ textTransform: "none", bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main", "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" } }}>{t("cancel")}</Button>
            </DialogActions>
        </Dialog>
    );

});

export default ConditionDeleteDialog;