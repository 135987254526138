import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Avatar, Backdrop, Box, Button, CircularProgress, Grid, Paper, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { RichTreeView, TreeItem2 } from '@mui/x-tree-view';
import hexToRgba from 'hex-to-rgba';
import { isEqual } from "lodash";
import { forwardRef, Fragment, memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from 'react-oidc-context';
import TriangleIcon from '../../../icons/Triangle';
import SnackBar from "../../../utils/SnackBar";
import { useAuthorityCheck } from "../../../utils/useAuthorityCheck";
import { useAxios } from "../../../utils/useAxios";
import InsuranceTypeDialog from "../../Dialog/InsuranceTypeDialog";
import ViewHandler from './ViewHandler';

const InsuranceType = memo(() => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const { t } = useTranslation();

    const [insuranceTypes, setInsuranceTypes] = useState([]);
    const [tree, setTree] = useState([]);
    //eslint-disable-next-line
    const [failed, setFailed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [forward, setForward] = useState(false);
    const [selected, setSelected] = useState("");
    const [expanded, setExpanded] = useState([]);

    const { verifyRedirect } = useAuthorityCheck();

    const attempted = useRef(false);
    const viewHandlerRef = useRef(null);
    const insuranceTypesRef = useRef([]);
    const insuranceRef = useRef(null);
    // const deleteRef = useRef([]);
    const headingRef = useRef([]);
    const SnackBarRef = useRef(null);

    const axiosInstance = useAxios();

    useEffect(() => {
        document.title = t("insuranceTypeTitle");
        verifyRedirect();
        if (insuranceTypes?.length === 0 && !attempted.current) {
            fetchInsuranceTypes();
        };
        if (belowsm) {
            setForward(true);
        };
        if (forward && !belowsm) {
            viewHandlerRef.current.forwardInsuranceTypes(insuranceTypes);
        };
        //eslint-disable-next-line
    }, [verifyRedirect, belowsm, selected]);

    const fetchInsuranceTypes = async () => {
        if (!attempted.current) {
            setLoading(true);
        };
        attempted.current = true;
        await axiosInstance.current({
            url: "/admin/insurancetypes",
            method: "GET"
        }).then(res => {
            //client state including edits
            setInsuranceTypes(res.data);
            //state with unique ids for tree
            setTree(convert(res.data));
            //synced state of api
            insuranceTypesRef.current = res.data;
            //copy of state for detail page to properly load individual types
            viewHandlerRef.current.forwardInsuranceTypes(res.data);
        }).catch(() => setFailed(true)).finally(() => setLoading(false));
    };

    const saveInsuranceType = async (updatedInsuranceType, label, created, deleted, parentId, treeId) => {
        setLoading(true);
        await axiosInstance.current({
            url: "/admin/insurancetypes/insurancetype",
            method: "PUT",
            data: updatedInsuranceType
        }).then(res => {
            const newInsuranceTypes = [...insuranceTypesRef.current];
            const index = newInsuranceTypes?.findIndex(i => i?.id === res?.data?.id);
            if (index === -1 && res?.data) {
                //add new insuranceType to list and open it
                newInsuranceTypes?.push(res?.data);
                setSelected(`${res?.data?.id}.insurance`);
                viewHandlerRef.current.open(res?.data?.id, null, null, null, null, null, "insurance", newInsuranceTypes);
                SnackBarRef.current.custom("success", t("insuranceTypeCreated", { label: res?.data?.label ?? "" }));
            } else {
                //update insuranceType in list
                newInsuranceTypes?.splice(index, 1, res?.data);
                const newExpanded = [...expanded];
                if (deleted) {
                    setSelected(parentId);
                    SnackBarRef.current.custom("warning", t("insuranceTypeChildDeleted", { label: label ?? "" }));
                } else if (created) {
                    const typeArr = treeId.split(".");
                    const type = typeArr[typeArr.length - 1];
                    switch (type) {
                        case "product":
                            newExpanded.push(`${res?.data?.id}.insurance`, `${res?.data?.id}.products`);
                            viewHandlerRef.current.open(typeArr[0], typeArr[1], null, null, null, null, "product", newInsuranceTypes);
                            break;
                        case "risk":
                            newExpanded.push(`${res?.data?.id}.insurance`, `${res?.data?.id}.products`, parentId);
                            viewHandlerRef.current.open(typeArr[0], typeArr[1], typeArr[2], null, null, null, "risk", newInsuranceTypes);
                            break;
                        case "cause":
                            newExpanded.push(`${res?.data?.id}.insurance`, `${res?.data?.id}.products`, `${res?.data?.id}.${typeArr[1]}.product`, parentId);
                            viewHandlerRef.current.open(typeArr[0], typeArr[1], typeArr[2], typeArr[3], null, null, "cause", newInsuranceTypes);
                            break;
                        case "category":
                            newExpanded.push(`${res?.data?.id}.insurance`, `${res?.data?.id}.damagedObjectCategories`);
                            viewHandlerRef.current.open(typeArr[0], null, null, null, typeArr[1], null, "category", newInsuranceTypes);
                            break;
                        case "object":
                            newExpanded.push(`${res?.data?.id}.insurance`, `${res?.data?.id}.damagedObjectCategories`, parentId);
                            viewHandlerRef.current.open(typeArr[0], null, null, null, typeArr[1], typeArr[2], "object", newInsuranceTypes);
                            break;
                        default:
                            break;
                    }
                    //remove duplicates
                    setExpanded([...new Set(newExpanded)]);
                    setSelected(treeId);
                    SnackBarRef.current.custom("success", t("insuranceTypeChildCreated", { label: label ?? "" }));
                } else {
                    SnackBarRef.current.custom("success", t("insuranceTypeSaved", { label: label ?? "" }));
                };
            };
            setInsuranceTypes(newInsuranceTypes);
            setTree(convert(newInsuranceTypes));
            insuranceTypesRef.current = newInsuranceTypes;
            viewHandlerRef.current.forwardInsuranceTypes(newInsuranceTypes);
        }).catch(() => setFailed(true)).finally(() => setLoading(false));
    };

    const deleteInsuranceType = async (deletedInsuranceType) => {
        if (deletedInsuranceType?.insuranceType) {
            setLoading(true);
            await axiosInstance.current({
                url: `/admin/insurancetypes/insurancetype/${deletedInsuranceType?.insuranceType}`,
                method: "DELETE",
            }).then(() => {
                const newInsuranceTypes = [...insuranceTypesRef.current];
                const index = newInsuranceTypes?.findIndex(i => i?.id === deletedInsuranceType?.id);
                //deleted insuranceType exists in list
                if (index !== -1) {
                    //remove deleted insuranceType from list
                    newInsuranceTypes?.splice(index, 1);
                    SnackBarRef.current.custom("warning", t("insuranceTypeDeleted", { label: deletedInsuranceType?.label ?? "" }));
                };
                setInsuranceTypes(newInsuranceTypes);
                setTree(convert(newInsuranceTypes));
                insuranceTypesRef.current = newInsuranceTypes;
                viewHandlerRef.current.forwardInsuranceTypes(newInsuranceTypes);
            }).catch(() => setFailed(true)).finally(() => setLoading(false));
        };
    };

    const convert = (data) => {
        const headingIds = [];

        const transformNode = (node, type, parentId) => {
            let nodeId;
            const children = [];

            switch (type) {
                case "product":
                    nodeId = `${parentId}.${node?.product}.product`;
                    break;
                case "risk":
                    nodeId = `${parentId}.${node?.risk}.risk`;
                    break;
                case "cause":
                    nodeId = `${parentId}.${node?.cause}.cause`;
                    break;
                case "category":
                    nodeId = `${parentId}.${node?.category}.category`;
                    break;
                case "object":
                    nodeId = `${parentId}.${node?.object}.object`;
                    break;
                default:
                    nodeId = node?.id;
            };

            if (node?.products?.length > 0) {
                const productChildren = node.products.map((product) => {
                    const productNode = transformNode(product, "product", nodeId);
                    if (product?.risks?.length > 0) {
                        const riskChildren = product.risks.map((risk) => {
                            const riskNode = transformNode(risk, "risk", `${nodeId}.${product?.product}`);
                            if (risk?.causes?.length > 0) {
                                const causeChildren = risk.causes.map((cause) => transformNode(cause, "cause", `${nodeId}.${product?.product}.${risk?.risk}`));
                                // headingIds.push(`${nodeId}-${product?.product}-${risk?.risk}-causes`);
                                // riskNode.children.push({
                                //     id: `${nodeId}-${product?.product}-${risk?.risk}-causes`,
                                //     label: risk?.causes?.length === 1 ? t("cause") : t("causes"),
                                //     children: causeChildren,
                                // });
                                riskNode.children.push(...causeChildren);
                            }
                            return riskNode;
                        });
                        // headingIds.push(`${nodeId}-${product?.product}-${product?.risk}-risks`);
                        // productNode.children.push({
                        //     id: `${nodeId}-${product?.product}-${product?.risk}-risks`,
                        //     label: product?.risks?.length === 1 ? t("risk") : t("risks"),
                        //     children: riskChildren,
                        // });
                        productNode.children.push(...riskChildren);
                    }
                    return productNode;
                });
                headingIds.push(`${nodeId}.products`);
                children.push({
                    id: `${nodeId}.products`,
                    label: node?.products?.length === 1 ? t("insurance") : t("insurances"),
                    children: productChildren,
                });
            };

            if (node?.damagedObjectCategories?.length > 0 && node?.id) {
                const categoryChildren = node.damagedObjectCategories.map((category) => {
                    const categoryNode = transformNode(category, "category", nodeId);
                    if (category?.objects?.length > 0) {
                        const objectChildren = category.objects.map((object) => transformNode(object, "object", `${nodeId}.${category?.category}`));
                        // headingIds.push(`${nodeId}-${category?.category}-objects`);
                        // categoryNode.children.push({
                        //     id: `${nodeId}-${category?.category}-objects`,
                        //     label: category?.objects?.length === 1 ? t("object") : t("objects"),
                        //     children: objectChildren,
                        // });
                        categoryNode.children.push(...objectChildren);
                    }
                    return categoryNode;
                });
                headingIds.push(`${nodeId}.damagedObjectCategories`);
                children.push({
                    id: `${nodeId}.damagedObjectCategories`,
                    label: node?.damagedObjectCategories?.length === 1 ? t("damageObjectCategory") : t("damageObjectCategories"),
                    children: categoryChildren,
                });
            };

            return {
                id: node?.id ? `${nodeId}.insurance` : nodeId,
                label: node?.label,
                children,
            };
        };

        const converted = data.map((item) => transformNode(item));
        headingRef.current = headingIds;
        return converted;
    };

    const getKeyValuesFromId = (itemId) => {
        let result = { id: null, product: null, risk: null, cause: null, category: null, object: null, type: null };

        const split = itemId?.split(".");
        const type = split[split?.length - 1];
        result.id = split[0];
        result.product = type === "product" || type === "risk" || type === "cause" ? split[1] : null;
        result.risk = type === "risk" || type === "cause" ? split[2] : null;
        result.cause = type === "cause" ? split[3] : null;
        result.category = type === "category" || type === "object" ? split[1] : null;
        result.object = type === "object" ? split[2] : null;
        result.type = type;

        return result;
    };

    const handleNewInsuranceType = () => insuranceRef.current.open();

    const updateInsuranceTypes = async newInsuranceType => saveInsuranceType(newInsuranceType);

    const updateParent = (newInsuranceType, newLabel, created, parentId, treeId) => saveInsuranceType(newInsuranceType, newLabel, created, false, parentId, treeId);

    const updateDeleteParent = (newInsuranceType, label, isInsuranceType, parentId) => {
        if (isInsuranceType) {
            //delete insuranceType and children
            deleteInsuranceType(newInsuranceType);
        } else {
            //update insuranceType with new children
            saveInsuranceType(newInsuranceType, label, false, true, parentId);
        };
    };

    const handleSelected = (event, itemId) => {
        const isHeading = headingRef.current?.find(h => h === itemId) !== undefined;
        const className = event.target.className.toString();

        if (className?.split(' ')[0] === "MuiTypography-root" && !isHeading) {
            setSelected(itemId);
            const { id, product, risk, cause, category, object, type } = getKeyValuesFromId(itemId);
            viewHandlerRef.current.open(id, product, risk, cause, category, object, type);
        };
    };

    const handleExpanded = (event, itemIds) => setExpanded(itemIds);

    const TreeItem = forwardRef(function CustomTreeItem(props, ref) {

        const isHeading = headingRef.current?.find(h => h === props?.itemId) !== undefined;
        const isSelected = selected === props?.itemId;

        return (
            <TreeItem2
                {...props}
                ref={ref}
                slots={{
                    label: () => {
                        return (
                            <Grid container sx={{ flexWrap: "nowrap", overflow: "hidden" }}>
                                <Tooltip title={props?.label} placement="right">
                                    <Typography color="inherit" fontSize={16} noWrap fontWeight={isHeading ? 600 : 400} sx={{ flexGrow: 1 }}>{props?.label ?? t("noTreeItemLabel")}</Typography>
                                </Tooltip>
                                <Avatar sx={{ m: .5, height: "16px", width: "16px", bgcolor: "secondary.main", display: isSelected ? "flex" : "none", "& .MuiAvatar-fallback": { display: "none" } }} />
                            </Grid>
                        );
                    }
                }}
                sx={{
                    "& .Mui-selected": {
                        bgcolor: hexToRgba(theme.palette.secondary.main, 0.1),
                        "&:hover": { bgcolor: hexToRgba(theme.palette.secondary.main, 0.1) }
                    }
                }}
            />
        );
    });

    if (belowsm) {
        return (
            <Grid container sx={{ height: "calc(100vh - 180px)", maxWidth: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography color="text.primary" sx={{ textAlign: "center", px: 2 }}>{t("adminDesktopOnly")}</Typography>
            </Grid>
        );
    };

    return (
        <Fragment>
            <Grid container sx={{ p: 2, pt: 0, height: belowsm ? "calc(100vh - 277px)" : belowmd ? "calc(100vh - 178px)" : "calc(100vh - 124px)", maxWidth: "1440px", visibility: loading ? "hidden" : "visible" }}>
                <Grid item xs={3} sx={{ height: belowmd && !belowsm ? "calc(100vh - 218px)" : "calc(100vh - 160px)", mt: 2, pb: 1 }}>
                    <Paper
                        elevation={2}
                        sx={{
                            color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary",
                            height: "100%",
                            background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                            borderRadius: "14.362px",
                            boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none",
                        }}
                    >
                        <Box sx={{ pb: 0, pt: 1, borderTopLeftRadius: "14.362px", borderTopRightRadius: "14.362px", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                            <Typography variant="h6" noWrap fontWeight={600} color="inherit" sx={{ mb: .5, ml: 1.5 }}>{t("serviceKey")}</Typography>
                        </Box>
                        <RichTreeView
                            items={tree}
                            // onItemSelectionToggle={handleSelectionToggle}
                            selectedItems={selected}
                            onSelectedItemsChange={handleSelected}
                            expansionTrigger="iconContainer"
                            expandedItems={expanded}
                            onExpandedItemsChange={handleExpanded}
                            slots={{
                                expandIcon: () => <Box sx={{ transform: "rotate(90deg)" }}><TriangleIcon /></Box>,
                                collapseIcon: () => <Box sx={{ transform: "rotate(180deg)" }}><TriangleIcon /></Box>,
                                item: TreeItem
                            }}
                            sx={{
                                height: "calc(100% - 45px)",
                                overflow: "auto"
                            }}
                        />
                    </Paper>
                    <Button
                        onClick={handleNewInsuranceType}
                        disableElevation
                        variant="contained"
                        size="large"
                        fullWidth
                        color="secondary"
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        sx={{
                            mt: 2,
                            mb: 2,
                            textTransform: "none",
                            borderRadius: "8px"
                        }}>
                        <Typography color="inherit" fontSize={15} noWrap>{t("newInsuranceType")}</Typography>
                    </Button>
                </Grid>
                <Grid item xs={9} sx={{ height: belowmd ? "calc(100vh - 178px)" : "calc(100vh - 124px)", pl: 2, pt: 2 }}>
                    <ViewHandler updateParent={updateParent} updateDeleteParent={updateDeleteParent} ref={viewHandlerRef} />
                </Grid>
            </Grid>
            <SnackBar ref={SnackBarRef} />
            <InsuranceTypeDialog update={updateInsuranceTypes} ref={insuranceRef} />
            <Backdrop
                sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Fragment>
    );

}, (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data));
export default withAuthenticationRequired(InsuranceType);