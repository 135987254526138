import { Box, Collapse, Grid, IconButton, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TriangleIcon from '../../../icons/Triangle';
import WidgetTextField from "../WidgetTextField";

const DamageObjectsWidget = ({ obj }) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const [collapse, setCollapse] = useState(true);

    const { t } = useTranslation();

    const handleCollapse = () => setCollapse(!collapse);

    const TextField = ({ value, index }) => {

        const getValue = () => {
            const object = value?.specificObject;
            const category = value?.objectCategory;
            const description = value?.damageDescription;
            if (object && category && description) {
                return `${object} (${category})\n${description}`;
            };
            if (!object && category && description) {
                return `${t("noObject")} (${category})\n${description}`;
            };
            if (object && !category && description) {
                return `${object}\n${description}`;
            };
            if (object && category && !description) {
                return `${object} (${category})\n${t("noDescription")}`;
            };
            if (object && !category && !description) {
                return `${object}\n${t("noDescription")}`;
            };
            if (!object && !category && description) {
                return `${t("noObject")}\n${description}`;
            };
            if (!object && category && !description) {
                return `${t("noObject")} (${category})\n${t("noDescription")}`;
            };
            return `${t("noObject")}\n${t("noDescription")}`;
        };

        const isLast = (key) => {
            return obj?.data?.length - 1 === key;
        };

        const InputComponent = forwardRef(({ value, ...props }, ref) => {
            const [firstLine, ...otherLines] = value.split('\n');
            return (
                <Box {...props}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{firstLine}</Typography>
                    {otherLines.length > 0 && (
                        <Typography variant="body1">{'\n' + otherLines.join('\n')}</Typography>
                    )}
                </Box>
            );
        });

        return <WidgetTextField
            color="widget"
            value={getValue()}
            variant="standard"
            fullWidth
            focused
            multiline
            maxRows={3}
            InputLabelProps={{
                sx: { px: 1 }
            }}
            InputProps={{
                readOnly: true,
                disableUnderline: isLast(index),
                inputComponent: InputComponent,
                sx: {
                    px: 1,
                    color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"
                }
            }}
            sx={{ mt: 1 }}
        />
    };

    return (
        <Grid item xs={12}>
            <Paper
                elevation={2}
                sx={{
                    pt: 1,
                    pb: 1,
                    background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                    borderRadius: "14.362px",
                    boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                }}
            >
                <Grid container
                    sx={{
                        px: 1,
                        pb: collapse ? 1 : 0,
                        borderBottom: collapse ? 1 : 0,
                        borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                    }}
                >
                    <Grid item xs={11}>
                        <Typography
                            noWrap
                            sx={{
                                color: collapse ? theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary" : theme.palette.widget.main,
                                fontSize: belowsm ? "20px" : "24px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                mt: belowsm ? .75 : .5,
                                pr: 2
                            }}
                        >
                            {obj?.title ?? ""}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end", pr: belowsm ? 0 : 2 }}>
                        <IconButton
                            onClick={handleCollapse}
                            sx={{
                                transform: collapse ? 'rotate(0deg)' : 'rotate(180deg)',
                                transition: 'transform 250ms ease-in-out',
                            }}>
                            <TriangleIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Collapse
                    in={collapse}
                    mountOnEnter
                >
                    <Grid container>
                        {obj?.data?.map((c, i) => { return <TextField key={`TextField-${i}`} value={c} index={i} /> })}
                    </Grid>
                </Collapse>
            </Paper>
        </Grid>
    )
};

export default DamageObjectsWidget;