import { Box, Grid, Paper, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import TriangleIcon from "../../../icons/Triangle";

const Object = ({ title, data, updateObject }) => {

    const theme = useTheme();
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const { t } = useTranslation();

    const [object, setObject] = useState({});
    const [name, setName] = useState(" ");
    const [description, setDescription] = useState("");

    const viewRef = useRef(null);

    useEffect(() => {
        setObject(data ?? {});
        setName(data?.label);
        setDescription(data?.description ?? "");
    }, [data])

    const handleNameChange = event => {
        setName(event.target.value);
        const newObject = {
            ...object,
            "label": event.target.value
        };
        setObject(newObject);
        updateObject(newObject);
    };

    const handleDescriptionChange = event => {
        setDescription(event.target.value);
        const newObject = {
            ...object,
            "description": event.target.value
        };
        setObject(newObject);
        updateObject(newObject);
    };

    return (
        <Fragment>
            <Box ref={viewRef} sx={{ height: belowmd ? "calc(100vh - 228px)" : "calc(100vh - 170px)", overflow: "auto", borderRadius: "14.362px", pb: .5 }}>
                <Paper
                    elevation={2}
                    sx={{
                        background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                        height: "100%",
                        width: "100%",
                        borderRadius: "14.362px",
                        boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                    }}
                >
                    {title?.length < 3 ?
                        <Grid container
                            sx={{
                                p: 1,
                                borderBottom: 1,
                                borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider",
                                flexWrap: "nowrap",
                                overflow: "auto"
                            }}
                        >
                            <Typography
                                noWrap
                                sx={{
                                    color: theme.palette.widget.main,
                                    fontSize: "20px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "normal",
                                    mt: .75,
                                }}
                            >
                                {t("missingPath")}
                            </Typography>
                        </Grid> :
                        <Grid container
                            sx={{
                                p: 1,
                                borderBottom: 1,
                                borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider",
                                flexWrap: "nowrap",
                                overflow: "auto"
                            }}
                        >
                            <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                                <Typography
                                    noWrap
                                    sx={{
                                        color: theme.palette.widget.main,
                                        fontSize: "20px",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "normal",
                                        mt: .75,
                                        mr: .5
                                    }}
                                >
                                    {title[0]}
                                </Typography>
                                <TriangleIcon fontSize="16px" transform={true} mt={true} />
                            </Grid>
                            <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                                <Typography
                                    noWrap
                                    sx={{
                                        color: theme.palette.widget.main,
                                        fontSize: "20px",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "normal",
                                        mt: .75,
                                        mx: .5
                                    }}
                                >
                                    {title[1]}
                                </Typography>
                                <TriangleIcon fontSize="16px" transform={true} mt={true} />
                            </Grid>
                            <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                                <Typography
                                    noWrap
                                    sx={{
                                        color: theme.palette.widget.main,
                                        fontSize: "20px",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "normal",
                                        mt: .75,
                                        ml: .5
                                    }}
                                >
                                    {title[2]}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    <Grid container direction="column">
                        <Grid item sx={{ px: 1, pt: 2 }}>
                            <TextField
                                label={t("name")}
                                value={name}
                                onChange={handleNameChange}
                                fullWidth
                                required
                                error={name === ""}
                                helperText={name === "" ? t("missingName") : ""}
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                    "& label.Mui-focused": {
                                        color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "&:hover fieldset": {
                                            borderColor: theme.palette.mode === "dark" ? theme.palette.action.disabled : theme.palette.action.active,
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                        }
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item sx={{ px: 1, pt: 2 }}>
                            <TextField
                                label={t("description")}
                                value={description}
                                onChange={handleDescriptionChange}
                                fullWidth
                                helperText=" "
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                    "& label.Mui-focused": {
                                        color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "&:hover fieldset": {
                                            borderColor: theme.palette.mode === "dark" ? theme.palette.action.disabled : theme.palette.action.active,
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                        }
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Fragment>
    );

};
export default Object;