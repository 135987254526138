import { createContext, useMemo, useState } from "react";

export const AdminContext = createContext({
    admin: {
        "enabled": false
    },
    setAdmin: () => { }
});

export default function AdminProvider({ children }) {

    const [admin, setAdmin] = useState({
        "enabled": false
    });

    const value = useMemo(
        () => ({ admin, setAdmin }),
        [admin]
    );

    return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
};