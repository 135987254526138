import { Collapse, Grid, IconButton, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import TriangleIcon from '../../../icons/Triangle';
import WidgetTextField from "../WidgetTextField";

const CoverageSourceWidget = ({ obj }) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const [collapse, setCollapse] = useState(true);

    const handleCollapse = () => setCollapse(!collapse);

    const TextField = ({ value, index }) => {

        const isLast = (key) => {
            return obj?.data?.length - 1 === key;
        };

        return <WidgetTextField
            color="widget"
            value={`${value?.source ?? ""}${value?.version ? ` (${value?.version})` : ""}${value?.conditionType ? ` (${value?.conditionType})` : ""}`}
            variant="standard"
            fullWidth
            focused
            multiline
            maxRows={3}
            InputLabelProps={{
                sx: { px: 1 }
            }}
            InputProps={{
                readOnly: true,
                disableUnderline: belowsm ? isLast(index) : true,
                sx: {
                    px: 1,
                    color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"
                }
            }}
            sx={{ mt: 1 }}
        />
    };

    return (
        <Grid item xs={12}>
            <Paper
                elevation={2}
                sx={{
                    pt: 1,
                    pb: 1,
                    background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                    borderRadius: "14.362px",
                    boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                }}
            >
                <Grid container
                    sx={{
                        px: 1,
                        pb: collapse ? 1 : 0,
                        borderBottom: collapse ? 1 : 0,
                        borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                    }}
                >
                    <Grid item xs={11}>
                        <Typography
                            noWrap
                            sx={{
                                color: collapse ? theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary" : theme.palette.widget.main,
                                fontSize: belowsm ? "20px" : "24px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                mt: belowsm ? .75 : .5,
                                pr: 2
                            }}
                        >
                            {obj?.title ?? ""}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end", pr: belowsm ? 0 : 2 }}>
                        <IconButton
                            onClick={handleCollapse}
                            sx={{
                                transform: collapse ? 'rotate(0deg)' : 'rotate(180deg)',
                                transition: 'transform 250ms ease-in-out',
                            }}>
                            <TriangleIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Collapse
                    in={collapse}
                    mountOnEnter
                >
                    <Grid container>
                        {obj?.data?.map((c, i) => { return <TextField key={`TextField-${i}`} value={c} index={i} /> })}
                    </Grid>
                </Collapse>
            </Paper>
        </Grid>
    )
};

export default CoverageSourceWidget;