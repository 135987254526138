import { Backdrop, CircularProgress, Grid, useMediaQuery, useTheme } from "@mui/material";
import { memo, useContext } from "react";
import { ClaimContext } from "../../../utils/ClaimContext";
import { useConvertDto } from '../../../utils/useConvertDto';
import CompletenessWidget from "./CompletenessWidget";
import SummaryWidget from "./SummaryWidget";
import Widget from "../Widget";

const Completeness = memo(() => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));

    const { claim } = useContext(ClaimContext);

    const { getWuestenrotCompleteness, getClaimCompleteness } = useConvertDto();

    if (!claim?.claimUUID) {
        return (
            <Backdrop
                sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    };

    return (
        <Grid container direction={belowsm ? "row" : "column"} sx={{ mb: 1, width: belowsm ? "calc(100vw - 35px)" : "auto" }}>
            <Grid item xs={12}>
                {claim?.completenessData ?
                    <Grid container>
                        <CompletenessWidget obj={getClaimCompleteness()} />
                        <SummaryWidget />
                    </Grid> :
                    <Grid container>
                        <Widget obj={getWuestenrotCompleteness()} noMaxRows={true} fullSize={true} />
                    </Grid>
                }
            </Grid>
        </Grid>
    );
});

export default Completeness;