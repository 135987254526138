import { createTheme, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import { deDE } from '@mui/material/locale';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Condition from './components/Admin/Condition/Condition';
import InsuranceType from './components/Admin/InsuranceType/InsuranceType';
import Claims from './components/Claims';
import Complete from './components/Complete/Complete';
import ExpiredDialog from './components/Dialog/ExpiredDialog';
import Login from './components/Login';
import Nav from './components/Nav';
import Report from './components/Report';
import AdminProvider from './utils/AdminContext';
import ClaimProvider from './utils/ClaimContext';
import ProccessingProvider from './utils/ProcessingContext';
import Logout from './components/Logout';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  //theming and color palette
  const theme = createTheme({
    palette: {
      mode: prefersDarkMode ? "dark" : "light",
      primary: {
        main: "#335F9A"
      },
      secondary: {
        main: prefersDarkMode ? "#EFDB3A" : "#9d00ff"
      },
      warning: {
        main: "#FFD700"
      },
      profile: {
        main: "#2C2C2C",
        contrastText: "#F5F5F5"
      },
      signout: {
        main: "#E3E3E3",
        border: "#767676",
        contrastText: "#1E1E1E"
      },
      widget: {
        main: prefersDarkMode ? "rgb(91, 127, 174)" : "#335F9A",
        paper: "#F8F6F4",
        divider: prefersDarkMode ? "rgba(255, 255, 255, 0.08)" : "#f0e6dd",
        title: "#3E3C3C",
        contrastText: "#615F5E",
        triangleIcon: "#474444",
        icon: prefersDarkMode ? "rgba(255, 255, 255, 0.70)" : "#4B4F56"
      },
      complete: {
        border: "#FFD9D9",
        primary: "#3E3C3C",
        secondary: "#00000070",
        progress: "#15461980",
        progressBackground: "#E7DCD2"
      }
    },
    typography: {
      fontFamily: 'Ubuntu'
    }
  },
    deDE
  );

  //auth
  const [displayLogout, setDisplayLogout] = useState(false);
  const { user } = useAuth();
  const expiredRef = useRef(null);

  useEffect(() => {
    if (user && user?.expired && window.location.pathname !== "/login") {
      expiredRef.current.open();
    }
  }, [user, displayLogout])

  const Redirect = () => {
    return <Navigate replace to="/login" />;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AdminProvider>
        <ClaimProvider>
          <ProccessingProvider>
            <BrowserRouter>
              <Nav setDisplayLogout={setDisplayLogout} />
              {displayLogout ?
                <Logout /> : null}
              <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/claim/report" element={<Report />} />
                <Route exact path="/claim/display/:claimUUID?" element={<Complete />} />
                <Route exact path="/claim/myclaims" element={<Claims />} />
                <Route exact path="/admin/configuration/insurancetypes" element={<InsuranceType />} />
                <Route exact path="/admin/configuration/conditions" element={<Condition />} />
                <Route exact path="*" element={<Redirect />} />
              </Routes>
              <ExpiredDialog ref={expiredRef} />
            </BrowserRouter>
          </ProccessingProvider>
        </ClaimProvider>
      </AdminProvider>
    </ThemeProvider >
  );
};

export default App;