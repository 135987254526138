import { Box, Button, Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { deDE } from '@mui/x-data-grid/locales';
import moment from "moment";
import { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth, withAuthenticationRequired } from "react-oidc-context";
import { useLocation, useNavigate } from "react-router-dom";
import ClaimDialog from "./Dialog/ClaimDialog";
import DeleteDialog from "./Dialog/DeleteDialog";
import { useAxios } from "../utils/useAxios";

const Claims = memo(() => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const navigate = useNavigate();
    const location = useLocation();

    const params = new URLSearchParams(location.search);

    const { t } = useTranslation();

    const [claims, setClaims] = useState([]);
    const [activePage, setActivePage] = useState(+params.get("page") || 0);
    const [loading, setLoading] = useState(false);
    const [failed, setFailed] = useState(false);
    const [pageable, setPageable] = useState({
        "size": 20,
        "currentPage": +params.get("page") || 0,
        "empty": true,
        "totalElements": 0,
        "totalPages": 0
    });
    
    const [paginationModel, setPaginationModel] = useState({
        page: +params.get("page") || 0,
        pageSize: 20,
    });
    const [selected, setSelected] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const attempted = useRef(false);
    const claimRef = useRef(null);
    const deleteRef = useRef(null);

    const { signoutRedirect } = useAuth();

    const axiosInstance = useAxios();

    useEffect(() => {
        document.title = t("claimsTitle");
        if (refetch) {
            attempted.current = false;
            setClaims([]);
            setRefetch(false);
        }
        if (!attempted.current && claims?.length === 0) {
            fetchClaims(20, +params.get("page") || 0);
        };
        //eslint-disable-next-line
    }, [claims, refetch, attempted.current]);

    const fetchClaims = async (size, page) => {
        setLoading(true);
        navigate({
            search: `?page=${page || 0}`
        });
        if (claims?.filter(c => c.page === page)?.length > 0) {
            setActivePage(page || 0);
            setLoading(false);
            return;
        };

        await axiosInstance.current({
            url: `/claims${size ? `?size=${size}` : ""}${page && !size ? `?page=${page}` : page && size ? `&page=${page}` : ""}`,
            method: "GET"
        }).then((res) => {
            const newClaims = [
                ...claims,
                {
                    "page": page ? page : 0,
                    "content": res.data?.content
                }];
            setClaims(newClaims);
            setActivePage(page || 0);
            setPageable({
                ...pageable,
                "size": res.data?.size,
                "currentPage": page ? page : 0,
                "empty": res.data?.empty,
                "totalElements": res.data?.totalElements,
                "totalPages": res.data?.totalPages
            });
            const claim = localStorage.getItem("claim") ?? undefined;
            const source = sessionStorage.getItem("source") ?? undefined;
            if (claim !== undefined && source !== undefined) {
                setSelected([claim]);
                sessionStorage.removeItem("source");
            };
        }).catch(err => {
            if (err?.response?.status === 401) {
                signoutRedirect();
            };
            setFailed(true);
        });
        attempted.current = true;
        setLoading(false);
    };

    const handleChangePage = (newPage) => {
        fetchClaims(newPage.pageSize, newPage.page);
        setPaginationModel(newPage);
    };

    const columns = [
        {
            field: "sentAt",
            headerName: t("sentAtString"),
            width: 100,
            valueGetter: (value) => moment(value).format("DD.MM.YYYY"),
        },
        {
            field: "sender",
            headerName: t("reporter"),
            width: 150
        },
        {
            field: "policy",
            headerName: t("policy"),
            sortable: false,
            width: 125
        },
        {
            field: "insurance",
            headerName: t("insurance"),
            flex: belowsm ? null : 1.2,
            width: belowsm ? 200 : null,
            valueGetter: (value, row) => value ? value : row?.insuranceType ?? ""
        },
        {
            field: "risk",
            headerName: t("risk"),
            // description: 'This column has a value getter and is not sortable.',
            flex: belowsm ? null : 1,
            width: belowsm ? 200 : null
        },
        {
            field: "cause",
            headerName: t("cause"),
            flex: belowsm ? null : 1,
            width: belowsm ? 200 : null
        },
        {
            field: "lossDescription",
            headerName: t("lossDescription"),
            sortable: false,
            flex: belowsm ? null : 4,
            width: belowsm ? 1000 : null
        }
    ];

    const getRowId = row => {
        return row.claimUUID;
    };

    const handleClick = (row) => {
        const source = `${window.location?.pathname ?? ""}${window.location?.search ?? ""}`;
        if (belowsm) {
            const currentClaim = claims?.filter(c => c.page === activePage)[0]?.content.filter(c => c.claimUUID === row.id)[0];
            claimRef.current.open(currentClaim, source);
        } else {
            sessionStorage.setItem("source", source)
            localStorage.setItem("claim", row.id);
            navigate(`/claim/display/${row.id}`)
        }
    };

    const handleDeleteSelected = () => deleteRef.current.deleteSelected(selected);

    const handleDeleteAll = () => deleteRef.current.deleteAll();

    const handleSelecton = (newSelection) => {
        setSelected(newSelection);
    };

    return (
        <Grid container sx={{ p: 2, height: belowmd && !belowsm ? "calc(100vh - 130px)" : "calc(100vh - 76px)", maxWidth: "1440px" }}>
            <Grid item xs={12}>
                <Paper
                    elevation={2}
                    sx={{
                        mt: 1,
                        width: "100%",
                        height: belowmd && !belowsm ? "80vh" : "87vh",
                        background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                        borderRadius: "14.362px",
                        boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none",
                    }}
                >
                    <Typography
                        noWrap
                        sx={{
                            color: theme.palette.widget.main,
                            fontSize: belowsm ? "20px" : "24px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                            pt: belowsm ? 1.75 : 1.5,
                            px: 1,
                            pb: 1,
                            borderBottom: 1,
                            borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                        }}
                    >
                        {t("myClaims")}
                    </Typography>
                    <Box sx={{ pb: 1 }}>
                        {failed ?
                            <Box sx={{ width: "100%", height: "75vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography color={theme.palette.mode === "dark" ? theme.palette.text.primary : theme.palette.widget.contrastText} sx={{ textAlign: "center" }}>{t("unkownClaimsError")}</Typography>
                            </Box>
                            :
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    height: belowmd && !belowsm ? "74vh" : "81vh",
                                    flexGrow: 1
                                }}
                            >
                                <Grid container sx={{ justifyContent: belowsm ? "center" : "flex-end", mt: 1 }}>
                                    <Grid item xs={6} sm="auto" sx={{ pl: belowsm ? 1 : 0, pr: .5 }}>
                                        <Button
                                            onClick={handleDeleteSelected}
                                            variant="contained"
                                            disableElevation
                                            fullWidth
                                            disabled={selected.length === 0}
                                            sx={{
                                                textTransform: "none",
                                                borderRadius: "8px",
                                                bgcolor: theme.palette.widget.main,
                                                "&:hover": { bgcolor: "error.main" }
                                            }}>
                                            <Typography noWrap color="inherit" sx={{ fontSize: "14px" }}>{t("deleteSelected")}</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} sm="auto" sx={{ pl: .5, pr: 1 }}>
                                        <Button
                                            onClick={handleDeleteAll}
                                            variant="contained"
                                            disableElevation
                                            fullWidth
                                            disabled={claims?.length === 0 ?? true}
                                            sx={{
                                                textTransform: "none",
                                                borderRadius: "8px",
                                                bgcolor: theme.palette.widget.main,
                                                "&:hover": { bgcolor: "error.main" }
                                            }}>
                                            <Typography noWrap color="inherit" sx={{ fontSize: "14px" }}>{t("deleteAll")}</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                                <DataGrid
                                    rows={claims?.filter(c => c.page === activePage)[0]?.content ?? []}
                                    onRowClick={handleClick}
                                    columns={columns}
                                    checkboxSelection
                                    rowSelectionModel={selected}
                                    onRowSelectionModelChange={handleSelecton}
                                    disableColumnFilter
                                    getRowId={getRowId}
                                    disableRowSelectionOnClick
                                    getRowHeight={() => belowsm ? null : 'auto'}
                                    autosizeOptions={{
                                        expand: true
                                    }}
                                    ignoreDiacritics
                                    loading={loading}
                                    paginationMode="server"
                                    filterMode="server"
                                    rowCount={pageable.totalElements}
                                    pageSizeOptions={[20]}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={handleChangePage}
                                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                                    sx={{
                                        border: 0,
                                        "--DataGrid-rowBorderColor": theme.palette.mode === "light" ? theme.palette.widget.divider : "rgb(62 62 62)",
                                        "& .MuiDataGrid-columnHeader": {
                                            color: theme.palette.mode === "dark" ? theme.palette.text.primary : theme.palette.widget.contrastText,
                                            background: theme.palette.mode === "light" ? theme.palette.widget.paper : "rgb(40 40 40)"
                                        },
                                        "& .MuiDataGrid-cell": {
                                            color: theme.palette.mode === "dark" ? theme.palette.text.primary : theme.palette.widget.contrastText
                                        },
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            fontWeight: 600
                                        },
                                        "& .MuiDataGrid-footerContainer": {
                                            borderTopColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "rgb(62 62 62)"
                                        }
                                    }}
                                />
                            </Box>
                        }
                    </Box>
                    <ClaimDialog ref={claimRef} />
                    <DeleteDialog setClaims={setClaims} setRefetch={setRefetch} setLoading={setLoading} ref={deleteRef} />
                </Paper>
            </Grid>
        </Grid>
    );
});

export default withAuthenticationRequired(Claims, {
    OnRedirecting: () => {
        const redirect = `${window.location?.pathname ?? ""}${window.location?.search ?? ""}`;
        localStorage.setItem("redirect", redirect);
    }
});