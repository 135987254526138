import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BadgeIcon from '@mui/icons-material/Badge';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import CategoryIcon from '@mui/icons-material/Category';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EmailIcon from '@mui/icons-material/Email';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import GppGoodIcon from '@mui/icons-material/GppGood';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import PolicyIcon from '@mui/icons-material/Policy';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ShieldIcon from '@mui/icons-material/Shield';
import { Box, Collapse, Grid, IconButton, Paper, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { isEqual } from 'lodash';
import { forwardRef, memo, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CalendarIcon from '../../icons/CalendarIcon';
import CarIcon from "../../icons/CarIcon";
import CarReversedIcon from "../../icons/CarReversedIcon";
import EuroCurrencyIcon from "../../icons/EuroCurrencyIcon";
import InsuranceIcon from "../../icons/InsuranceIcon";
import NoteIcon from "../../icons/NoteIcon";
import ToolboxIcon from "../../icons/ToolboxIcon";
import TriangleIcon from '../../icons/Triangle';
import { ClaimContext } from '../../utils/ClaimContext';
import { ProcessingContext } from '../../utils/ProcessingContext';
import WidgetTextField from "./WidgetTextField";

const Widget = memo(({ obj, display, size, noMaxRows, oneLine, fullSize }) => {

    const { t } = useTranslation();

    const { claim } = useContext(ClaimContext);
    const { processing, setProcessing } = useContext(ProcessingContext);

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const [collapse, setCollapse] = useState(true);
    const [updated, setUpdated] = useState([]);

    useEffect(() => {
        if (claim?.successfulStates?.length === claim?.scheduledStates) {
            const newProcessing = {
                ...processing,
                [obj?.title]: obj?.data
            };
            setProcessing(newProcessing);
        };
        //eslint-disable-next-line
    }, [claim?.successfulStates, claim?.scheduledStates])

    useEffect(() => {
        if (claim?.successfulStates?.length !== claim?.scheduledStates || claim?.lastSuccessfulState !== "ERROR" || claim?.lastSuccessfulState !== "END_ERROR") {
            const keys = [];
            const data = obj?.data;
            const corresponding = processing[obj?.title] ?? {};
            Object.keys(data).forEach(key => {
                if (data[key]?.api !== corresponding[key]?.api && Object.keys(corresponding)?.length > 0) {
                    keys.push(key);
                }
            });
            if (keys.length > 0) {
                //temporarily disabled
                // setUpdated(keys);
            };
            const newProcessing = {
                ...processing,
                [obj?.title]: obj?.data
            };
            setProcessing(newProcessing);
        };
        //eslint-disable-next-line
    }, [obj]);

    const handleCollapse = () => {
        setCollapse(!collapse);
    };

    const isLast = (key) => {
        const keys = Object.keys(obj?.data ?? {});
        if (keys.length === 0) {
            return true
        };
        return keys[keys.length - 1] === key;
    };

    const isLastTwo = (key) => {
        const keys = Object.keys(obj?.data ?? {});
        if (keys.length === 0) {
            return true
        };
        if (keys[keys.length - 2] === key) {
            return true;
        };
        if (keys[keys.length - 1] === key) {
            return true;
        };
        return false;
    };

    const TextField = ({ dataKey, value }) => {

        //eslint-disable-next-line
        const Icon = ({ dataKey }) => {
            let icon;

            switch (dataKey) {
                case "policy":
                    icon = <ShieldIcon sx={{ color: "inherit" }} />;
                    break;
                case "claimNumber":
                    icon = <ConfirmationNumberIcon sx={{ color: "inherit" }} />;
                    break;
                case "reporter":
                    icon = <AdminPanelSettingsIcon sx={{ color: "inherit" }} />;
                    break;
                case "contactEmail":
                    icon = <EmailIcon sx={{ color: "inherit" }} />;
                    break;
                case "contactPhone":
                    icon = <PhoneIcon sx={{ color: "inherit" }} />;
                    break;
                case "sentAt":
                    icon = <CalendarIcon sx={{ color: "inherit" }} />;
                    break;
                case "messageType":
                    icon = <EmailIcon sx={{ color: "inherit" }} />;
                    break;
                case "insuranceType":
                    icon = <InsuranceIcon sx={{ color: "inherit" }} />;
                    break;
                case "insurance":
                    icon = <GppGoodIcon sx={{ color: "inherit" }} />;
                    break;
                case "risk":
                    icon = <GppMaybeIcon sx={{ color: "inherit" }} />;
                    break;
                case "cause":
                    icon = <PolicyIcon sx={{ color: "inherit" }} />;
                    break;
                case "lossDate":
                    icon = <CalendarIcon sx={{ color: "inherit" }} />;
                    break;
                case "lossTime":
                    icon = <AccessTimeFilledIcon sx={{ color: "inherit" }} />;
                    break;
                case "lossLocation":
                    icon = <LocationOnIcon sx={{ color: "inherit" }} />;
                    break;
                case "damagedObject":
                    icon = <ToolboxIcon sx={{ color: "inherit" }} />;
                    break;
                case "damagedObjectCategory":
                    icon = <CategoryIcon sx={{ color: "inherit" }} />;
                    break;
                case "damageSum":
                    icon = <EuroCurrencyIcon sx={{ color: "inherit" }} />;
                    break;
                case "lossDescription":
                    icon = <NoteIcon sx={{ color: "inherit" }} />;
                    break;
                case "carLicensePlate":
                    icon = <CarIcon sx={{ color: "inherit" }} />;
                    break;
                case "carVin":
                    icon = <CarIcon sx={{ color: "inherit" }} />;
                    break;
                case "carYear":
                    icon = <CalendarIcon sx={{ color: "inherit" }} />;
                    break;
                case "carMileage":
                    icon = <CarIcon sx={{ color: "inherit" }} />;
                    break;
                case "carMake":
                    icon = <CarIcon sx={{ color: "inherit" }} />;
                    break;
                case "carModel":
                    icon = <CarIcon sx={{ color: "inherit" }} />;
                    break;
                case "carColor":
                    icon = <FormatColorFillIcon sx={{ color: "inherit" }} />;
                    break;
                case "carThirdLicensePlate":
                    icon = <CarReversedIcon sx={{ color: "inherit" }} />;
                    break;
                case "carThirdVin":
                    icon = <CarReversedIcon sx={{ color: "inherit" }} />;
                    break;
                case "carThirdYear":
                    icon = <CalendarIcon sx={{ color: "inherit" }} />;
                    break;
                case "carThirdMake":
                    icon = <CarReversedIcon sx={{ color: "inherit" }} />;
                    break;
                case "carThirdModel":
                    icon = <CarReversedIcon sx={{ color: "inherit" }} />;
                    break;
                case "carThirdColor":
                    icon = <FormatColorFillIcon sx={{ color: "inherit" }} />;
                    break;
                case "carThirdDriverName":
                    icon = <PersonIcon sx={{ color: "inherit" }} />;
                    break;
                case "carThirdDriverAddress":
                    icon = <PersonPinCircleIcon sx={{ color: "inherit" }} />;
                    break;
                case "carThirdDriverLicense":
                    icon = <BadgeIcon sx={{ color: "inherit" }} />;
                    break;
                case "carThirdDamage":
                    icon = <CarCrashIcon sx={{ color: "inherit" }} />;
                    break;
                case "coverage":
                    icon = <PrivacyTipIcon sx={{ color: "inherit" }} />
                    break;
                case "thirdName":
                    icon = <PersonIcon sx={{ color: "inherit" }} />;
                    break;
                case "thirdAddress":
                    icon = <PersonPinCircleIcon sx={{ color: "inherit" }} />;
                    break;
                case "paymentIban":
                    icon = <AccountBalanceIcon sx={{ color: "inherit" }} />;
                    break;
                case "paymentRecipient":
                    icon = <PersonIcon sx={{ color: "inherit" }} />;
                    break;
                case "paymentInfo":
                    icon = <ReceiptIcon sx={{ color: "inherit" }} />;
                    break;
                case "paymentAmount":
                    icon = <EuroCurrencyIcon sx={{ color: "inherit" }} />;
                    break;
                default:
                    icon = null;
            }

            return (
                <Box sx={{ color: "widget.icon", cursor: "default", pl: .5, mb: .5 }}>
                    {icon}
                </Box>
            );
        };

        const [isUpdated, setIsUpdated] = useState(false);
        const [finished, setFinished] = useState(false);

        useEffect(() => {
            const hasUpdated = updated.filter(u => u === dataKey)?.length;
            if (hasUpdated > 0 && !finished) {
                setIsUpdated(true);
                setFinished(true);
            };
            if (isUpdated) {
                const timer = setTimeout(() => {
                    setIsUpdated(false);
                    setUpdated([]);
                }, 2500);
                return () => clearTimeout(timer);
            };
            //eslint-disable-next-line
        }, [isUpdated, dataKey])

        return <WidgetTextField
            color="widget"
            label={t(value?.internal ?? "missingInternal")}
            value={value?.api ?? ""}
            variant="standard"
            fullWidth
            focused
            multiline={!size ? true : false}
            maxRows={noMaxRows ? null : oneLine && size ? 1 : 3}
            InputLabelProps={{
                sx: { px: 1 }
            }}
            InputProps={{
                readOnly: true,
                disableUnderline: isLast(dataKey),
                // endAdornment: <Icon dataKey={value?.internal} />,
                sx: {
                    pl: 1,
                    pr: 1,
                    // pr: belowsm ? 2 : 3.5,
                    color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary",
                    backgroundColor: isUpdated ? theme.palette.mode === "dark" ? "rgba(102, 187, 106, 0.3)" : "rgba(190, 255, 190, 0.6)" : "transparent",
                    transition: isUpdated ? "background-color 1s ease-in" : "background-color 0.5s ease-out",
                    borderTopRightRadius: "12px",
                    borderTopLeftRadius: "12px"
                }
            }}
            sx={{ mt: 1 }}
        />
    };

    const HalfSizedTextField = ({ dataKey, value }) => {

        const [isUpdated, setIsUpdated] = useState(false);
        const [finished, setFinished] = useState(false);

        useEffect(() => {
            const hasUpdated = updated.filter(u => u === dataKey)?.length;
            if (hasUpdated > 0 && !finished) {
                setIsUpdated(true);
                setFinished(true);
            };
            if (isUpdated) {
                const timer = setTimeout(() => {
                    setIsUpdated(false);
                    setUpdated([]);
                }, 2500);
                return () => clearTimeout(timer);
            };
            //eslint-disable-next-line
        }, [isUpdated, dataKey])

        return (
            <Grid item xs={6}>
                <WidgetTextField
                    color="widget"
                    label={t(value?.internal ?? "missingInternal")}
                    value={value?.api ?? ""}
                    variant="standard"
                    fullWidth
                    focused
                    multiline={!size ? true : false}
                    maxRows={noMaxRows ? null : oneLine ? 1 : 3}
                    InputLabelProps={{
                        sx: { px: 1 }
                    }}
                    InputProps={{
                        readOnly: true,
                        disableUnderline: isLastTwo(dataKey),
                        sx: {
                            px: 1,
                            color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary",
                            backgroundColor: isUpdated ? theme.palette.mode === "dark" ? "rgba(102, 187, 106, 0.3)" : "rgba(190, 255, 190, 0.6)" : "transparent",
                            transition: isUpdated ? "background-color 1s ease-in" : "background-color 0.5s ease-out",
                            borderTopRightRadius: "12px",
                            borderTopLeftRadius: "12px"
                        }
                    }}
                    sx={{ mt: 1 }}
                />
            </Grid>
        );
    };

    const SkeletonTextField = ({ dataKey, value }) => {

        return <WidgetTextField
            color="widget"
            label={t(value?.internal ?? "missingInternal")}
            variant="standard"
            fullWidth
            focused
            InputLabelProps={{
                sx: { px: 1 }
            }}
            InputProps={{
                inputComponent: forwardRef((props, ref) => { return <Skeleton variant="text" animation="wave" sx={{ width: "100%", pb: .1 }} /> }),
                disableUnderline: true,
                sx: {
                    px: 1
                }
            }}
            sx={{ mt: 1 }}
        />
    };

    const HalfSizedSkeletonTextField = ({ dataKey, value }) => {

        return (
            <Grid item xs={6}>
                <WidgetTextField
                    color="widget"
                    label={t(value?.internal ?? "missingInternal")}
                    variant="standard"
                    fullWidth
                    focused
                    InputLabelProps={{
                        sx: { px: 1 }
                    }}
                    InputProps={{
                        inputComponent: forwardRef((props, ref) => { return <Skeleton variant="text" animation="wave" sx={{ width: "100%", pb: .1 }} /> }),
                        disableUnderline: isLastTwo(dataKey),
                        sx: {
                            px: 1
                        }
                    }}
                    sx={{ mt: 1 }}
                />
            </Grid>
        );
    };

    return (
        <Grid item xs={size !== undefined && !belowsm && !fullSize ? 6 : 12} sx={{ display: display !== undefined ? display ? "block" : "none" : "block" }}>
            <Paper
                elevation={2}
                sx={{
                    pt: 1,
                    pb: 1,
                    background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                    borderRadius: "14.362px",
                    ml: size?.left && !belowsm ? 1 : 0,
                    mr: size?.right && !belowsm ? 1 : 0,
                    mb: size?.right && belowsm ? 2 : 0,
                    boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                }}
            >
                <Grid container
                    sx={{
                        px: 1,
                        pb: collapse ? 1 : 0,
                        borderBottom: collapse ? 1 : 0,
                        borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                    }}
                >
                    <Grid item xs={11}>
                        <Typography
                            noWrap
                            sx={{
                                color: collapse ? theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary" : theme.palette.widget.main,
                                fontSize: belowsm ? "20px" : "24px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                mt: belowsm ? .75 : .5,
                                pr: 2
                            }}
                        >
                            {obj?.title ?? ""}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ display: size !== undefined && !belowsm ? "none" : "flex", justifyContent: "flex-end", pr: belowsm ? 0 : 2 }}>
                        <IconButton
                            onClick={handleCollapse}
                            sx={{
                                transform: collapse ? 'rotate(0deg)' : 'rotate(180deg)',
                                transition: 'transform 250ms ease-in-out',
                            }}>
                            <TriangleIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Collapse
                    in={collapse}
                    mountOnEnter
                >
                    <Grid container>
                        {claim?.successfulStates?.length === 0 && claim?.currentStage !== "COMPLETED" && claim?.lastSuccessfulState !== "BILLING_STATISTICS" ? !size && !fullSize && !belowsm ? Object.entries(obj?.data ?? {}).map(([key, value], i) => { return <HalfSizedSkeletonTextField key={`SkeletonTextField-${i}`} dataKey={key} value={value} /> })
                            : Object.entries(obj?.data ?? {}).map(([key, value], i) => { return <SkeletonTextField key={`SkeletonTextField-${i}`} dataKey={key} value={value} /> }) :
                            !size && !fullSize && !belowsm ? Object.entries(obj?.data ?? {}).map(([key, value], i) => { return <HalfSizedTextField key={`TextField-${i}`} dataKey={key} value={value} /> })
                                : Object.entries(obj?.data ?? {}).map(([key, value], i) => { return <TextField key={`TextField-${i}`} dataKey={key} value={value} /> })}
                    </Grid>
                </Collapse>
            </Paper>
        </Grid>
    )
}, (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data));

export default Widget;