import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from "@mui/material";
import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { AdminContext } from "../../utils/AdminContext";
import { useAuthorityCheck } from "../../utils/useAuthorityCheck";
import { useNavigate } from "react-router-dom";

const UserDialog = forwardRef((props, ref) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState();

    const { admin, setAdmin } = useContext(AdminContext)

    const { verify } = useAuthorityCheck();
    const isAuthorized = verify();

    useImperativeHandle(ref, () => ({
        open(data) {
            setOpen(true);
            setEmail(data);
        }
    }));

    const handleClose = () => {
        setOpen(false);
    };

    const handleAdmin = () => {
        const isAdmin = admin?.enabled ? !admin.enabled : true;
        setAdmin({ ...admin, "enabled": isAdmin });
        setOpen(false);
        if (isAdmin) {
            navigate("/admin/configuration/conditions");
        } else {
            navigate(localStorage.getItem("redirect") ?? "/claim/report");
        };
    };

    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: { borderRadius: "14.362px" }
            }}
        >
            <DialogTitle fontWeight={600} sx={{ textAlign: "center" }}>{t("profile")}</DialogTitle>
            <DialogContent sx={{ justifyContent: "center" }}>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, lineHeight: "normal", textAlign: "center" }}>{t("signedInWith", { email: email })}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" disableElevation onClick={handleAdmin} sx={{ borderRadius: "8px", textTransform: "none", display: isAuthorized && !belowsm ? "flex" : "none" }}>{admin?.enabled ? t("switchToClaim") : t("switchToAdmin")}</Button>
                <Button variant="contained" disableElevation onClick={handleClose} sx={{ borderRadius: "8px", textTransform: "none", bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main", "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" } }}>{t("close")}</Button>
            </DialogActions>
        </Dialog>
    );

});

export default UserDialog;