import { CircularProgress, Snackbar, useMediaQuery, useTheme } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Alert = forwardRef(function Alert(props, ref) {
    if (props.severity === "info") {
        return <MuiAlert elevation={2} icon={<CircularProgress color="inherit" size={16} sx={{ mt: .25 }} />} ref={ref} {...props} />;
    };
    return <MuiAlert elevation={2} ref={ref} {...props} />;
});

const SnackBar = forwardRef((props, ref) => {

    const theme = useTheme();
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState({});
    const [completed, setCompleted] = useState(false);

    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
        completed() {
            setMessage({
                severity: "success",
                message: t("processingClaimCompleted")
            });
            setOpen(true);
            setCompleted(true);
        },
        failed() {
            setMessage({
                severity: "error",
                message: t("processingClaimFailed")
            });
            setOpen(true);
        },
        stage(currentStage, scheduledStages) {
            setMessage({
                severity: "info",
                message: t("processingClaimStage", { stage: currentStage, scheduled: scheduledStages})
            });
            setOpen(true);
        },
        custom(severity, message) {
            setMessage({
                severity: severity ?? "info",
                message: message ?? ""
            });
            setOpen(true);
            setCompleted(true);
        } 
    }));

    const handleClose = () => {
        if (completed) {
            setOpen(false);
        };
    };

    return (
        <Snackbar
            open={open}
            onClose={handleClose}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: belowsm ? "center" : "right" }}
            sx={{
                mt: belowsm ? 21.25 : belowmd ? 15.25 : 8.25,
                mr: belowsm ? 0 : -1,
                width: belowsm ? "92vw" : "auto",
                left: belowsm ? 16 : 8,
                right: belowsm ? 16 : 8
            }}
        >
            <Alert severity={message.severity} sx={{ width: "100%", justifyContent: "center" }}>{message.message}</Alert>
        </Snackbar>
    )
});

export default SnackBar;