import { Backdrop, CircularProgress, Collapse, Grid, IconButton, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { memo, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import TriangleIcon from '../../icons/Triangle';
import { ClaimContext } from "../../utils/ClaimContext";
import Markdown from "markdown-to-jsx";

const Policy = memo(() => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));

    const [collapse, setCollapse] = useState(true);

    const { claim } = useContext(ClaimContext);
    const markdown = claim?.policySummary.replace(/```markdown|```/g, '') ?? '';

    const { t } = useTranslation();

    const handleCollapse = () => {
        setCollapse(!collapse);
    };

    if (!claim?.claimUUID) {
        return (
            <Backdrop
                sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    };

    const H1 = ({ children, ...props }) => <h1 {...props} style={{ fontFamily: "Ubuntu", fontSize: 22, color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary" }}>{children}</h1>
    const H2 = ({ children, ...props }) => <h2 {...props} style={{ fontFamily: "Ubuntu", fontSize: 20, color: theme.palette.widget.main }}>{children}</h2>
    const H3 = ({ children, ...props }) => <h3 {...props} style={{ fontFamily: "Ubuntu", fontSize: 18, color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary" }}>{children}</h3>
    const P = ({ children, ...props }) => <p {...props} style={{ fontFamily: "Ubuntu", color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary" }}>{children}</p>
    const Strong = ({ children, ...props }) => <strong {...props} style={{ fontFamily: "Ubuntu", color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary" }}>{children}</strong>
    const Li = ({ children, ...props }) => <li {...props} style={{ fontFamily: "Ubuntu", color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary" }}>{children}</li>

    //markdown styling overrides
    const markdownOptions = {
        overrides: {
            h1: H1,
            h2: H2,
            h3: H3,
            p: P,
            strong: Strong,
            li: Li
        }
    };

    return (
        <Grid container direction={belowsm ? "row" : "column"} sx={{ mb: 1, width: belowsm ? "calc(100vw - 35px)" : "auto" }}>
            <Grid item xs={12}>
                <Paper
                    elevation={2}
                    sx={{
                        pt: 1,
                        pb: 1,
                        background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                        borderRadius: "14.362px",
                        boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                    }}
                >
                    <Grid container
                        sx={{
                            px: 1,
                            pb: collapse ? 1 : 0,
                            borderBottom: collapse ? 1 : 0,
                            borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                        }}
                    >
                        <Grid item xs={11}>
                            <Typography
                                noWrap
                                sx={{
                                    color: collapse ? theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary" : theme.palette.widget.main,
                                    fontSize: belowsm ? "20px" : "24px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "normal",
                                    mt: belowsm ? .75 : .5,
                                    pr: 2
                                }}
                            >
                                {t("policy")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end", pr: belowsm ? 0 : 2 }}>
                            <IconButton
                                onClick={handleCollapse}
                                sx={{
                                    transform: collapse ? 'rotate(0deg)' : 'rotate(180deg)',
                                    transition: 'transform 250ms ease-in-out',
                                }}>
                                <TriangleIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Collapse
                        in={collapse}
                        mountOnEnter
                    >
                        <Grid container sx={{ px: 1 }}>
                            <Markdown options={markdownOptions}>
                                {markdown}
                            </Markdown>
                        </Grid>
                    </Collapse>
                </Paper>
            </Grid>
        </Grid>
    );
});

export default Policy;