import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

const ConditionDialog = forwardRef(({ setConditions }, ref) => {

    const theme = useTheme();

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [currentConditions, setCurrentConditions] = useState([]);
    const [condition, setCondition] = useState("");

    useImperativeHandle(ref, () => ({
        open(conditions) {
            setOpen(true);
            setCurrentConditions(conditions);
        }
    }));

    const create = () => {
        const newCondition = {
            version: condition,
            avb: false,
            bvb: false,
            conditionType: null,
            product: "",
            insuranceType: [],
            insurance: "",
            risk: "",
            markdown: ``
        };
        const newConditions = [...currentConditions];
        newConditions.push(newCondition);
        setConditions(newConditions);
        setCondition("");
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: { borderRadius: "14.362px" }
            }}
        >
            <DialogTitle fontWeight={600} sx={{ textAlign: "center" }}>{t("addNewCondition")}</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ lineHeight: "normal", textAlign: "center" }}>{t("enterNewCondition")}</Typography>
                <TextField
                    // label={t("serviceKeySingle")}
                    value={condition}
                    onChange={(event) => setCondition(event.target.value)}
                    required
                    InputLabelProps={{ shrink: true }}
                    sx={{
                        mt: 2,
                        width: "360px",
                        "& label.Mui-focused": {
                            color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                        },
                        "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                                borderColor: theme.palette.mode === "dark" ? theme.palette.action.disabled : theme.palette.action.active,
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                            }
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={create} disableElevation disabled={condition === ""} sx={{ textTransform: "none", borderRadius: "8px", bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main", "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" } }}>{t("create")}</Button>
            </DialogActions>
        </Dialog>
    );

});

export default ConditionDialog;