import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import _, { isEqual } from 'lodash';
import { Fragment, forwardRef, memo, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import InsuranceTypeDeleteDialog from '../../Dialog/InsuranceTypeDeleteDialog';
import InsuranceTypeDetailDialog from '../../Dialog/InsuranceTypeDetailDialog';
import Category from './Category';
import Cause from './Cause';
import Insurance from './Insurance';
import Object from './Object';
import Product from './Product';
import Risk from './Risk';

const ViewHandler = memo(forwardRef(({ updateParent, updateDeleteParent }, ref) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const [context, setContext] = useState({
        "id": null,
        "product": null,
        "risk": null,
        "cause": null,
        "category": null,
        "object": null,
        "type": null
    });
    const [objects, setObjects] = useState({
        "insurance": null,
        "product": null,
        "risk": null,
        "cause": null,
        "category": null,
        "object": null
    });
    const [openInsuranceType, setOpenInsuranceType] = useState(false);
    const [openInsurance, setOpenInsurance] = useState(false);
    const [openRisk, setOpenRisk] = useState(false);
    const [openCause, setOpenCause] = useState(false);
    const [openDamageObjectCategory, setOpenDamageObjectCategory] = useState(false);
    const [openDamageObject, setOpenDamageObject] = useState(false);

    const viewRef = useRef(null);
    const insuranceTypesRef = useRef([]);
    const dialogRef = useRef(null);
    const deleteRef = useRef(null);
    const objectRef = useRef({});

    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
        forwardInsuranceTypes(data) {
            insuranceTypesRef.current = data;
        },
        open(id, product, risk, cause, category, object, type, fromCreate = []) {
            // console.log(`Id: ${id}\nProduct: ${product}\nRisk: ${risk}\nCause: ${cause}\nCategory: ${category}\nObject: ${object}\nType: ${type}`);
            viewRef.current.scrollTo(0, 0);
            objectRef.current = {};
            setContext({
                "id": id,
                "product": product,
                "risk": risk,
                "cause": cause,
                "category": category,
                "object": object,
                "type": type
            });
            setObjectsFromContext(id, product, risk, cause, category, object, type, fromCreate);

            switch (type) {
                case "insurance":
                    setOpenInsuranceType(true);
                    setOpenInsurance(false);
                    setOpenRisk(false);
                    setOpenCause(false);
                    setOpenDamageObjectCategory(false);
                    setOpenDamageObject(false);
                    break;
                case "product":
                    setOpenInsurance(true);
                    setOpenInsuranceType(false);
                    setOpenRisk(false);
                    setOpenCause(false);
                    setOpenDamageObjectCategory(false);
                    setOpenDamageObject(false);
                    break;
                case "risk":
                    setOpenRisk(true);
                    setOpenInsuranceType(false);
                    setOpenInsurance(false);
                    setOpenCause(false);
                    setOpenDamageObjectCategory(false);
                    setOpenDamageObject(false);
                    break;
                case "cause":
                    setOpenCause(true);
                    setOpenInsuranceType(false);
                    setOpenInsurance(false);
                    setOpenRisk(false);
                    setOpenDamageObjectCategory(false);
                    setOpenDamageObject(false);
                    break;
                case "category":
                    setOpenDamageObjectCategory(true);
                    setOpenInsuranceType(false);
                    setOpenInsurance(false);
                    setOpenRisk(false);
                    setOpenCause(false);
                    setOpenDamageObject(false);
                    break;
                case "object":
                    setOpenDamageObject(true);
                    setOpenInsuranceType(false);
                    setOpenInsurance(false);
                    setOpenRisk(false);
                    setOpenCause(false);
                    setOpenDamageObjectCategory(false);
                    break;
                default:
                    break;
            };
        }
    }));

    const setObjectsFromContext = (id, product, risk, cause, category, object, type, fromCreate = []) => {
        let insuranceTypeObj;
        if (fromCreate?.length > 0) {
            insuranceTypeObj = fromCreate[fromCreate?.findIndex(i => i.id === id)];
        } else {
            insuranceTypeObj = insuranceTypesRef.current[insuranceTypesRef.current.findIndex(i => i.id === id)];
        };
        const insuranceObj = product ? insuranceTypeObj?.products[insuranceTypeObj?.products?.findIndex(p => p.product === product)] : null;
        const riskObj = risk ? insuranceObj?.risks[insuranceObj?.risks?.findIndex(r => r.risk === risk)] : null;
        const causeObj = cause ? riskObj?.causes[riskObj?.causes?.findIndex(c => c.cause === cause)] : null;
        const categoryObj = category ? insuranceTypeObj?.damagedObjectCategories[insuranceTypeObj?.damagedObjectCategories?.findIndex(d => d.category === category)] : null;
        const objectObj = object ? categoryObj?.objects[categoryObj?.objects?.findIndex(o => o.object === object)] : null;

        switch (type) {
            case "insurance":
                setObjects({
                    "insurance": insuranceTypeObj,
                    "product": null,
                    "risk": null,
                    "cause": null,
                    "category": null,
                    "object": null
                });
                break;
            case "product":
                setObjects({
                    "insurance": insuranceTypeObj,
                    "product": insuranceObj,
                    "risk": null,
                    "cause": null,
                    "category": null,
                    "object": null
                });
                break;
            case "risk":
                setObjects({
                    "insurance": insuranceTypeObj,
                    "product": insuranceObj,
                    "risk": riskObj,
                    "cause": null,
                    "category": null,
                    "object": null
                });
                break;
            case "cause":
                setObjects({
                    "insurance": insuranceTypeObj,
                    "product": insuranceObj,
                    "risk": riskObj,
                    "cause": causeObj,
                    "category": null,
                    "object": null
                });
                break;
            case "category":
                setObjects({
                    "insurance": insuranceTypeObj,
                    "product": null,
                    "risk": null,
                    "cause": null,
                    "category": categoryObj,
                    "object": null
                });
                break;
            case "object":
                setObjects({
                    "insurance": insuranceTypeObj,
                    "product": null,
                    "risk": null,
                    "cause": null,
                    "category": categoryObj,
                    "object": objectObj
                });
                break;
            default:
                break;
        };
    };

    const getPathFromContext = () => {
        const path = [];

        switch (context?.type) {
            case "insurance":
                path.push(objects?.insurance?.label);
                return path;
            case "product":
                path.push(objects?.insurance?.label, objects?.product?.label);
                return path;
            case "risk":
                path.push(objects?.insurance?.label, objects?.product?.label, objects?.risk?.label);
                return path;
            case "cause":
                path.push(objects?.insurance?.label, objects?.product?.label, objects?.risk?.label, objects?.cause?.label);
                return path;
            case "category":
                path.push(objects?.insurance?.label, objects?.category?.label);
                return path;
            case "object":
                path.push(objects?.insurance?.label, objects?.category?.label, objects?.object?.label);
                return path;
            default:
                break;
        };
    };

    const getObjectFromContext = () => {
        switch (context?.type) {
            case "insurance":
                return objects?.insurance;
            case "product":
                return objects?.product;
            case "risk":
                return objects?.risk;
            case "cause":
                return objects?.cause;
            case "category":
                return objects?.category;
            case "object":
                return objects?.object;
            default:
                break;
        };
    };

    const canCreate = () => {
        if (!openInsuranceType && !openInsurance && !openRisk && !openCause && !openDamageObjectCategory && !openDamageObject) {
            return false;
        };
        if (openCause || openDamageObject) {
            return false;
        }
        return true;
    };

    const getCreateText = () => {
        if (openInsuranceType) {
            return [t("createInsurance"), t("createDamageObjectCategory")];
        };
        if (openInsurance) {
            return t("createRisk");
        };
        if (openRisk) {
            return t("createCause");
        };
        if (openDamageObjectCategory) {
            return t("createDamageObject");
        };
        return t("cantCreate");
    };

    const handleCreate = (productOrDamageObjectCategory) => {
        switch (context?.type) {
            case "insurance":
                dialogRef.current.open(productOrDamageObjectCategory ?? "");
                break;
            case "product":
                dialogRef.current.open("risk");
                break;
            case "risk":
                dialogRef.current.open("cause");
                break;
            case "category":
                dialogRef.current.open("object");
                break;
            default:
                break;
        };
    };

    const handleDelete = () => {
        switch (context?.type) {
            case "insurance":
                deleteRef.current.delete(`${t("serviceKeySingle")} ${objects?.insurance?.label}`);
                break;
            case "product":
                deleteRef.current.delete(`${t("insurance")} ${objects?.product?.label}`);
                break;
            case "risk":
                deleteRef.current.delete(`${t("risk")} ${objects?.risk?.label}`);
                break;
            case "cause":
                deleteRef.current.delete(`${t("cause")} ${objects?.cause?.label}`);
                break;
            case "category":
                deleteRef.current.delete(`${t("damageObjectCategory")} ${objects?.category?.label}`);
                break;
            case "object":
                deleteRef.current.delete(`${t("damageObject")} ${objects?.object?.label}`);
                break;
            default:
                break;
        };
    };

    //eslint-disable-next-line
    const getTreeId = (object, type) => {
        const id = objects?.insurance?.id;

        if (id) {
            switch (type) {
                case "insurance":
                    return `${id}.insurance`;
                case "product":
                    return `${id}.${object.product}.product`;
                case "risk":
                    return `${id}.${objects?.product?.product}.${object?.risk}.risk`;
                case "cause":
                    return `${id}.${objects?.product?.product}.${objects?.risk?.risk}.${object?.cause}.cause`;
                case "category":
                    return `${id}.${object.category}.category`;
                case "object":
                    return `${id}.${objects?.category?.category}.${object?.object}.object`;
                default:
                    break;
            };
        };

        return null;
    };

    const getParentTreeId = () => {
        const id = objects?.insurance?.id;

        if (id) {
            switch (context?.type) {
                case "product":
                    return `${id}.insurance`;
                case "risk":
                    return `${id}.${objects?.product?.product}.product`;
                case "cause":
                    return `${id}.${objects?.product?.product}.${objects?.risk?.risk}.risk`;
                case "category":
                    return `${id}.insurance`;
                case "object":
                    return `${id}.${objects.category?.category}.category`;
                default:
                    break;
            };
        };

        return null;
    };

    const update = object => {
        const newInsuranceType = { ...objects.insurance };
        let treeId;
        let objectId;

        //update parent with new child
        switch (context?.type) {
            case "insurance":
                //handle product and parent has products array
                if (object?.product && newInsuranceType?.products?.length >= 0) {
                    newInsuranceType?.products?.push(object);
                    treeId = getTreeId({}, "insurance");
                    objectId = getTreeId(object, "product");
                    updateParent(newInsuranceType, `${t("insurance")} ${object?.label}`, true, treeId, objectId);
                };
                //handle damageObjectCategory and parent has damageObjectCategories array
                if (object?.category && newInsuranceType?.damagedObjectCategories?.length >= 0) {
                    newInsuranceType?.damagedObjectCategories?.push(object);
                    treeId = getTreeId({}, "insurance");
                    objectId = getTreeId(object, "category");
                    updateParent(newInsuranceType, `${t("damageObjectCategory")} ${object?.label}`, true, treeId, objectId);
                };
                break;
            case "product":
                //check parent has risks array
                if (objects.product?.risks?.length >= 0) {
                    const newProduct = objects.product;
                    newProduct?.risks?.push(object);
                    const productIndex = newInsuranceType?.products?.findIndex(p => p.product === newProduct?.product);
                    //verify index has been found
                    if (productIndex !== -1) {
                        newInsuranceType?.products?.splice(productIndex, 1, newProduct);
                    };
                };
                treeId = getTreeId(objects.product, "product");
                objectId = getTreeId(object, "risk");
                updateParent(newInsuranceType, `${t("product")} ${object?.label}`, true, treeId, objectId);
                break;
            case "risk":
                //check parent has risks array
                if (objects.risk?.causes?.length >= 0) {
                    const newProduct = objects.product;
                    const newRisk = objects.risk;
                    newRisk?.causes?.push(object);
                    const productIndex = newInsuranceType?.products?.findIndex(p => p.product === newProduct?.product);
                    //verify product index has been found
                    if (productIndex !== -1) {
                        const riskIndex = newInsuranceType?.products[productIndex]?.risks?.findIndex(r => r.risk === newRisk?.risk);
                        //verify risk index has been found 
                        if (riskIndex !== -1) {
                            newProduct?.risks?.splice(riskIndex, 1, newRisk);
                            newInsuranceType?.products?.splice(productIndex, 1, newProduct);
                        };
                    };
                };
                treeId = getTreeId(objects.risk, "risk");
                objectId = getTreeId(object, "cause");
                updateParent(newInsuranceType, `${t("cause")} ${object?.label}`, true, treeId, objectId);
                break;
            case "category":
                //check parent has objects array
                if (objects.category?.objects?.length >= 0) {
                    const newCategory = objects.category;
                    newCategory?.objects?.push(object);
                    const categoryIndex = newInsuranceType?.damagedObjectCategories?.findIndex(c => c.category === newCategory?.category);
                    //verify index has been found
                    if (categoryIndex !== -1) {
                        newInsuranceType?.damagedObjectCategories?.splice(categoryIndex, 1, newCategory);
                    };
                };
                treeId = getTreeId(objects.category, "category");
                objectId = getTreeId(object, "object");
                updateParent(newInsuranceType, `${t("damageObject")} ${object?.label}`, true, treeId, objectId);
                break;
            default:
                break;
        };
    };

    const updateDelete = label => {
        const newInsuranceType = { ...objects.insurance };
        const parentId = getParentTreeId();

        switch (context?.type) {
            case "insurance":
                // delete insuranceType
                updateDeleteParent(newInsuranceType, newInsuranceType?.label ?? "", true);
                setOpenInsuranceType(false);
                break;
            case "product":
                //delete product/insurance
                const deleteProduct = objects.product;
                const productIndex = newInsuranceType?.products?.findIndex(p => p.product === deleteProduct?.product);
                //verify index has been found
                if (productIndex !== -1) {
                    newInsuranceType?.products?.splice(productIndex, 1);
                };
                updateDeleteParent(newInsuranceType, label ?? "", false, parentId);
                //display parent
                objectRef.current = {};
                setContext({
                    "id": newInsuranceType?.id ?? null,
                    "product": null,
                    "risk": null,
                    "cause": null,
                    "category": null,
                    "object": null,
                    "type": "insurance"
                });
                setObjectsFromContext(newInsuranceType?.id ?? null, null, null, null, null, null, "insurance");
                setOpenInsuranceType(true);
                setOpenInsurance(false);
                break;
            case "risk":
                //delete risk
                const riskProduct = objects.product;
                const deleteRisk = objects.risk;
                const productRiskIndex = newInsuranceType?.products?.findIndex(p => p.product === riskProduct?.product);
                //verify product index has been found
                if (productRiskIndex !== -1) {
                    const riskIndex = newInsuranceType?.products[productRiskIndex]?.risks?.findIndex(r => r.risk === deleteRisk?.risk);
                    //verify risk index has been found 
                    if (riskIndex !== -1) {
                        riskProduct?.risks?.splice(riskIndex, 1);
                        newInsuranceType?.products?.splice(productRiskIndex, 1, riskProduct);
                    };
                };
                updateDeleteParent(newInsuranceType, label ?? "", false, parentId);
                //display parent
                objectRef.current = {};
                setContext({
                    "id": newInsuranceType?.id ?? null,
                    "product": riskProduct?.product ?? null,
                    "risk": null,
                    "cause": null,
                    "category": null,
                    "object": null,
                    "type": "product"
                });
                setObjectsFromContext(newInsuranceType?.id ?? null, riskProduct?.product ?? null, null, null, null, null, "product");
                setOpenInsurance(true);
                setOpenRisk(false);
                break;
            case "cause":
                //delete cause
                const causeProduct = objects.product;
                const causeRisk = objects.risk;
                const deleteCause = objects.cause;
                const productCauseIndex = newInsuranceType?.products?.findIndex(p => p.product === causeProduct?.product);
                //verify product index has been found
                if (productCauseIndex !== -1) {
                    const riskCauseIndex = newInsuranceType?.products[productCauseIndex]?.risks?.findIndex(r => r.risk === causeRisk?.risk);
                    //verify risk index has been found 
                    if (riskCauseIndex !== -1) {
                        const causeIndex = newInsuranceType?.products[productCauseIndex]?.risks[riskCauseIndex]?.causes?.findIndex(c => c.cause === deleteCause?.cause);
                        //verify cause index has been found
                        if (causeIndex !== -1) {
                            causeRisk?.causes?.splice(causeIndex, 1);
                            causeProduct?.risks?.splice(riskCauseIndex, 1, causeRisk);
                            newInsuranceType?.products?.splice(productCauseIndex, 1, causeProduct);
                        }
                    };
                };
                updateDeleteParent(newInsuranceType, label ?? "", false, parentId);
                //display parent
                objectRef.current = {};
                setContext({
                    "id": newInsuranceType?.id ?? null,
                    "product": causeProduct?.product ?? null,
                    "risk": causeRisk?.risk ?? null,
                    "cause": null,
                    "category": null,
                    "object": null,
                    "type": "risk"
                });
                setObjectsFromContext(newInsuranceType?.id ?? null, causeProduct?.product ?? null, causeRisk?.risk ?? null, null, null, null, "risk");
                setOpenRisk(true);
                setOpenCause(false);
                break;
            case "category":
                //delete category
                const deleteCategory = objects.category;
                const categoryIndex = newInsuranceType?.damagedObjectCategories?.findIndex(c => c.category === deleteCategory?.category);
                //verify index has been found
                if (categoryIndex !== -1) {
                    newInsuranceType?.damagedObjectCategories?.splice(categoryIndex, 1);
                };
                updateDeleteParent(newInsuranceType, label ?? "", false, parentId);
                //display parent
                objectRef.current = {};
                setContext({
                    "id": newInsuranceType?.id ?? null,
                    "product": null,
                    "risk": null,
                    "cause": null,
                    "category": null,
                    "object": null,
                    "type": "insurance"
                });
                setObjectsFromContext(newInsuranceType?.id ?? null, null, null, null, null, null, "insurance");
                setOpenInsuranceType(true);
                setOpenDamageObjectCategory(false);
                break;
            case "object":
                //delete object
                const objectCategory = objects.category;
                const deleteObject = objects.object;
                const categoryObjectIndex = newInsuranceType?.damagedObjectCategories?.findIndex(c => c.category === objectCategory?.category);
                //verify category index has been found
                if (categoryObjectIndex !== -1) {
                    const objectIndex = newInsuranceType?.damagedObjectCategories[categoryObjectIndex]?.objects?.findIndex(o => o.object === deleteObject.object);
                    //verify object index has been found 
                    if (objectIndex !== -1) {
                        objectCategory?.objects?.splice(objectIndex, 1);
                        newInsuranceType?.damagedObjectCategories?.splice(categoryObjectIndex, 1, objectCategory);
                    };
                };
                updateDeleteParent(newInsuranceType, label ?? "", false, parentId);
                //display parent
                objectRef.current = {};
                setContext({
                    "id": newInsuranceType?.id,
                    "product": null,
                    "risk": null,
                    "cause": null,
                    "category": objectCategory?.category ?? null,
                    "object": null,
                    "type": "category"
                });
                setObjectsFromContext(newInsuranceType?.id, null, null, null, objectCategory?.category ?? null, null, "category");
                setOpenDamageObjectCategory(true);
                setOpenDamageObject(false);
                break;
            default:
                break;
        };
    };

    const updateEdit = () => {
        const newInsuranceType = { ...objects.insurance };
        const object = objectRef.current ?? null;

        if (object !== null && !_.isEqual(object, {})) {
            //update parent with new child
            switch (context?.type) {
                case "insurance":
                    if (object?.id) {
                        updateParent(object, `${t("serviceKeySingle")} ${object?.label}`, false);
                    };
                    break;
                case "product":
                    if (object?.product && newInsuranceType?.products?.length >= 0) {
                        const productIndex = newInsuranceType?.products?.findIndex(p => p.product === object?.product);
                        //verify index has been found
                        if (productIndex !== -1) {
                            newInsuranceType?.products?.splice(productIndex, 1, object);
                        };
                    };
                    updateParent(newInsuranceType, `${t("insurance")} ${object?.label}`, false);
                    break;
                case "risk":
                    if (object?.risk && objects.product?.risks?.length >= 0) {
                        const newProduct = objects.product;
                        const productIndex = newInsuranceType?.products?.findIndex(p => p.product === newProduct?.product);
                        //verify product index has been found
                        if (productIndex !== -1) {
                            const riskIndex = newInsuranceType?.products[productIndex]?.risks?.findIndex(r => r.risk === object?.risk);
                            //verify risk index has been found 
                            if (riskIndex !== -1) {
                                newProduct?.risks?.splice(riskIndex, 1, object);
                                newInsuranceType?.products?.splice(productIndex, 1, newProduct);
                            };
                        };
                    };
                    updateParent(newInsuranceType, `${t("risk")} ${object?.label}`, false);
                    break;
                case "cause":
                    if (object?.cause && objects.risk?.causes?.length >= 0) {
                        const newProduct = objects.product;
                        const newRisk = objects.risk;
                        const productIndex = newInsuranceType?.products?.findIndex(p => p.product === newProduct?.product);
                        //verify product index has been found
                        if (productIndex !== -1) {
                            const riskIndex = newInsuranceType?.products[productIndex]?.risks?.findIndex(r => r.risk === newRisk?.risk);
                            //verify risk index has been found 
                            if (riskIndex !== -1) {
                                const causeIndex = newInsuranceType?.products[productIndex]?.risks[riskIndex]?.causes?.findIndex(c => c.cause === object?.cause);
                                if (causeIndex !== -1) {
                                    newRisk?.causes?.splice(causeIndex, 1, object);
                                    newProduct?.risks?.splice(riskIndex, 1, newRisk);
                                    newInsuranceType?.products?.splice(productIndex, 1, newProduct);
                                };
                            };
                        };
                    };
                    updateParent(newInsuranceType, `${t("cause")} ${object?.label}`, false);
                    break;
                case "category":
                    if (object.category && newInsuranceType?.products?.length >= 0) {
                        const categoryIndex = newInsuranceType?.damagedObjectCategories?.findIndex(c => c.category === object?.category);
                        //verify index has been found
                        if (categoryIndex !== -1) {
                            newInsuranceType?.damagedObjectCategories?.splice(categoryIndex, 1, object);
                        };
                    };
                    updateParent(newInsuranceType, `${t("damageObjectCategory")} ${object?.label}`, false);
                    break;
                case "object":
                    if (object?.object && objects.category?.objects?.length >= 0) {
                        const newCategory = objects.category;
                        const categoryIndex = newInsuranceType?.damagedObjectCategories?.findIndex(c => c.category === newCategory?.category);
                        //verify index has been found
                        if (categoryIndex !== -1) {
                            const objectIndex = newInsuranceType?.damagedObjectCategories[categoryIndex]?.objects?.findIndex(o => o.object === object?.object);
                            if (objectIndex !== -1) {
                                newCategory?.objects?.splice(objectIndex, 1, object);
                                newInsuranceType?.damagedObjectCategories?.splice(categoryIndex, 1, newCategory);
                            }
                        };
                    };
                    updateParent(newInsuranceType, `${t("damageObject")} ${object?.label}`, false);
                    break;
                default:
                    break;
            };
        };
    };

    const updateObject = newObject => {
        // console.log(newObject);
        objectRef.current = newObject ?? {};
    };

    const NoView = () => {
        return (
            <Box sx={{ height: "100%", width: belowsm ? "90vw" : "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography noWrap color={theme.palette.text.primary}>{t("noInsuranceTypeSelected")}</Typography>
            </Box>
        );
    };

    return (
        <Fragment>
            <Box ref={viewRef} sx={{ height: belowmd ? "calc(100vh - 223px)" : "calc(100vh - 165px)", overflow: "auto", borderRadius: "14.362px" }}>
                {openInsuranceType ?
                    <Insurance title={getPathFromContext()} data={getObjectFromContext()} updateObject={updateObject} /> :
                    openInsurance ?
                        <Product title={getPathFromContext()} data={getObjectFromContext()} updateObject={updateObject} /> :
                        openRisk ?
                            <Risk title={getPathFromContext()} data={getObjectFromContext()} updateObject={updateObject} /> :
                            openCause ?
                                <Cause title={getPathFromContext()} data={getObjectFromContext()} updateObject={updateObject} /> :
                                openDamageObjectCategory ?
                                    <Category title={getPathFromContext()} data={getObjectFromContext()} updateObject={updateObject} /> :
                                    openDamageObject ?
                                        <Object title={getPathFromContext()} data={getObjectFromContext()} updateObject={updateObject} /> :
                                        <NoView />
                }
            </Box>
            <Grid container sx={{ display: "flex", justifyContent: "flex-end", flexWrap: "nowrap", overflow: "auto" }}>
                {canCreate() && openInsuranceType ?
                    <Fragment>
                        <Button
                            disableElevation
                            onClick={() => handleCreate("product")}
                            variant="outlined"
                            size="large"
                            startIcon={<AddCircleOutlineOutlinedIcon />}
                            sx={{
                                mt: belowsm ? 1 : 1.9,
                                mb: belowsm ? 0 : 2,
                                mr: belowsm ? 0 : 1,
                                textTransform: "none",
                                border: 1.5,
                                borderRadius: "8px",
                                borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                "&:hover": {
                                    border: 1.5,
                                    borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                    color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                                }
                            }}>
                            <Typography fontSize="15px" fontWeight={500} noWrap>{getCreateText()[0]}</Typography>
                        </Button>
                        <Button
                            disableElevation
                            onClick={() => handleCreate("category")}
                            variant="outlined"
                            size="large"
                            startIcon={<AddCircleOutlineOutlinedIcon />}
                            sx={{
                                mt: belowsm ? 1 : 1.9,
                                mb: belowsm ? 0 : 2,
                                mr: belowsm ? 0 : 1,
                                textTransform: "none",
                                border: 1.5,
                                borderRadius: "8px",
                                borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                "&:hover": {
                                    border: 1.5,
                                    borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                    color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                                }
                            }}>
                            <Typography fontSize="15px" fontWeight={500} noWrap>{getCreateText()[1]}</Typography>
                        </Button>
                    </Fragment>
                    : canCreate() && !openInsuranceType ?
                        <Button
                            disableElevation
                            onClick={handleCreate}
                            variant="outlined"
                            size="large"
                            startIcon={<AddCircleOutlineOutlinedIcon />}
                            sx={{
                                mt: belowsm ? 1 : 1.9,
                                mb: belowsm ? 0 : 2,
                                mr: belowsm ? 0 : 1,
                                textTransform: "none",
                                border: 1.5,
                                borderRadius: "8px",
                                borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                "&:hover": {
                                    border: 1.5,
                                    borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                    color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                                }
                            }}>
                            <Typography fontSize="15px" fontWeight={500} noWrap>{getCreateText()}</Typography>
                        </Button>
                        : null}
                <Button
                    disableElevation
                    onClick={handleDelete}
                    variant="contained"
                    size="large"
                    endIcon={<DeleteOutlinedIcon />}
                    disabled={!openInsuranceType && !openInsurance && !openRisk && !openCause && !openDamageObjectCategory && !openDamageObject}
                    sx={{
                        mt: 2,
                        mb: belowsm ? 0 : 2,
                        mr: belowsm ? 0 : 1,
                        textTransform: "none",
                        borderRadius: "8px",
                        bgcolor: theme.palette.widget.main,
                        "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                    }}>
                    <Typography fontSize="15px" fontWeight={500} noWrap>{t("delete")}</Typography>
                </Button>
                <Button
                    disableElevation
                    onClick={updateEdit}
                    variant="contained"
                    size="large"
                    endIcon={<CheckCircleOutlineIcon />}
                    disabled={!openInsuranceType && !openInsurance && !openRisk && !openCause && !openDamageObjectCategory && !openDamageObject}
                    sx={{
                        mt: belowsm ? 1 : 2,
                        mb: 2,
                        textTransform: "none",
                        borderRadius: "8px",
                        bgcolor: theme.palette.widget.main,
                        "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                    }}>
                    <Typography fontSize="15px" fontWeight={500} noWrap>{t("save")}</Typography>
                </Button>
            </Grid>
            <InsuranceTypeDetailDialog update={update} ref={dialogRef} />
            <InsuranceTypeDeleteDialog updateDelete={updateDelete} ref={deleteRef} />
        </Fragment>
    );

}), (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data));
export default ViewHandler;