import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

const ExpiredDialog = forwardRef((props, ref) => {

    const theme = useTheme();

    const { t } = useTranslation();

    const { signinRedirect } = useAuth();

    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        open() {
            setOpen(true);
        }
    }));

    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={open}
            PaperProps={{
                sx: { borderRadius: "14.362px" }
            }}
        >
            <DialogTitle fontWeight={600} sx={{ textAlign: "center" }}>{t("sessionExpired")}</DialogTitle>
            <DialogContent sx={{ justifyContent: "center" }}>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, lineHeight: "normal", textAlign: "center" }}>{t("sessionExpiredText")}</Typography>
            </DialogContent>
            <DialogActions sx={{ px: 2, mb: 1 }}>
                <Button variant="contained" onClick={() => signinRedirect()} fullWidth disableElevation sx={{ textTransform: "none", bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main", "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" } }}>{t("logInAgain")}</Button>
            </DialogActions>
        </Dialog>
    );

});

export default ExpiredDialog;