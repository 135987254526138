import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Avatar, Backdrop, Box, Button, CircularProgress, Collapse, Grid, List, ListItemButton, ListItemText, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import hexToRgba from 'hex-to-rgba';
import { isEqual } from "lodash";
import { Fragment, memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from 'react-oidc-context';
import TriangleIcon from '../../../icons/Triangle';
import { useAuthorityCheck } from "../../../utils/useAuthorityCheck";
import { useAxios } from "../../../utils/useAxios";
import ConditionDialog from '../../Dialog/ConditionDialog';
import ConditionDetail from './ConditionDetail';

const Condition = memo(() => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const { t } = useTranslation();

    const [conditions, setConditions] = useState([]);
    const [insuranceTypes, setInsuranceTypes] = useState([]);
    const [versions, setVersions] = useState([]);
    const [openListCondition, setOpenListCondition] = useState(true);
    const [refetch, setRefetch] = useState(false);
    const [saved, setSaved] = useState(false);
    //eslint-disable-next-line
    const [failed, setFailed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState("");

    const { verifyRedirect } = useAuthorityCheck();

    const attempted = useRef(false);
    const detailRef = useRef(null);
    const conditionRef = useRef(null);
    const conditionsRef = useRef([]);
    const newCondtionsRef = useRef([]);

    const axiosInstance = useAxios();

    useEffect(() => {
        document.title = t("ConditionTitle");
        verifyRedirect();
        const condition = conditions.find(c => c.version === selected);
        if (selected !== "" && condition && !belowsm && !refetch) {
            const matchingVersion = versions.find(v => v?.version === condition?.version);
            detailRef.current?.open(matchingVersion ? matchingVersion : condition, insuranceTypes);
        };
        //open newly created conditions immediately
        if (conditions?.length !== conditionsRef.current?.length) {
            setSelected(conditions[conditions?.length - 1]?.version);
            detailRef.current?.open(conditions[conditions?.length - 1], insuranceTypes);
            conditionsRef.current = conditions;
            newCondtionsRef.current?.push(conditions[conditions?.length - 1]?.version);
        };
        if (refetch && !saved) {
            setSelected("");
            detailRef.current?.reset();
        };
        if (!attempted.current || refetch) {
            fetchData();
        };
        //eslint-disable-next-line
    }, [verifyRedirect, refetch]);

    const fetchData = async () => {
        attempted.current = true;
        setLoading(true);
        try {
            const [conditionRes, insuranceTypeRes] = await Promise.all([
                axiosInstance.current({
                    url: "/admin/insuranceproductdefinitions/",
                    method: "GET"
                }),
                axiosInstance.current({
                    url: "/admin/insurancetypes",
                    method: "GET"
                })
            ]);
            setConditions(conditionRes.data);
            conditionsRef.current = conditionRes.data;
            newCondtionsRef.current = [];
            setInsuranceTypes(insuranceTypeRes.data);
        } catch (error) {
            setFailed(true);
        } finally {
            setSaved(false);
            setRefetch(false);
            setLoading(false);
        }
    };

    const fetchVersion = async (version) => {
        setLoading(true);

        await axiosInstance.current({
            url: `/admin/insuranceproductdefinitions/${version}`,
            method: "GET"
        }).then(res => {
            const index = versions.findIndex(v => v?.version === version)
            if (index === -1) {
                const versionList = [...versions];
                versionList.push(res.data);
                setVersions(versionList);
            } else {
                const versionList = [...versions];
                versionList?.splice(index, 1, res.data);
                setVersions(versionList);
            };
        }).catch(() => setFailed(true)).finally(() => {
            setLoading(false);
        });
    };

    const handleListCondition = () => setOpenListCondition(!openListCondition);

    const handleNewCondtion = () => conditionRef.current?.open(conditions);

    const ConditionListItem = ({ condition }) => {

        const openCondition = () => {
            const matchingVersion = versions.find(v => v?.version === condition?.version);
            const isNew = newCondtionsRef.current?.includes(condition?.version);
            if (!matchingVersion && !isNew) {
                fetchVersion(condition?.version);
            };
            detailRef.current.open(matchingVersion ? matchingVersion : condition, insuranceTypes);
            setSelected(condition?.version ?? "");
        };

        if (!condition?.version) {
            return null;
        };

        return (
            <ListItemButton
                onClick={openCondition}
                selected={selected === condition?.version}
                sx={{
                    py: .25,
                    pl: 4,
                    borderRadius: selected ? "0px" : "6px",
                    "&:hover": { bgcolor: hexToRgba(theme.palette.secondary.main, 0.1) },
                    "&.Mui-selected": {
                        bgcolor: hexToRgba(theme.palette.secondary.main, 0.1),
                        "&:hover": { bgcolor: hexToRgba(theme.palette.secondary.main, 0.1) }
                    }
                }}
            >
                <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? selected === condition?.version ? theme.palette.widget.main : theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: 1 }}>{condition?.version ?? ""}</Typography>} />
                <SaveAsIcon sx={{ display: newCondtionsRef.current?.includes(condition?.version) ? "flex" : "none", color: "text.secondary", fontSize: "16px", mr: selected === condition?.version ? .5 : 0 }} />
                <Avatar sx={{ height: "16px", width: "16px", bgcolor: "secondary.main", display: selected === condition?.version ? "flex" : "none", "& .MuiAvatar-fallback": { display: "none" } }} />
            </ListItemButton>
        );
    };

    if (belowsm) {
        return (
            <Grid container sx={{ height: "calc(100vh - 180px)", maxWidth: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography color="text.primary" sx={{ textAlign: "center", px: 2 }}>{t("adminDesktopOnly")}</Typography>
            </Grid>
        );
    };

    return (
        <Fragment>
            <Grid container sx={{ p: 2, pt: 0, height: belowsm ? "calc(100vh - 277px)" : belowmd ? "calc(100vh - 178px)" : "calc(100vh - 124px)", maxWidth: "1440px", visibility: loading ? "hidden" : "visible" }}>
                <Grid item xs={2} sx={{ height: belowmd && !belowsm ? "calc(100vh - 218px)" : "calc(100vh - 160px)", mt: 2, pb: 1 }}>
                    <Paper
                        elevation={2}
                        sx={{
                            color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary",
                            height: "100%",
                            background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                            borderRadius: "14.362px",
                            boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none",
                        }}
                    >
                        <List disablePadding sx={{ height: "100%", overflow: "auto" }}>
                            <ListItemButton onClick={handleListCondition} sx={{ pb: 0, pt: 1, borderTopLeftRadius: "14.362px", borderTopRightRadius: "14.362px", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                                <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color="inherit">{t("conditions")}</Typography>} />
                                <Box sx={{ transition: 'transform 250ms ease-in-out', transform: openListCondition ? 'rotate(0deg)' : 'rotate(180deg)' }}>
                                    <TriangleIcon sx={{ color: "inherit", ml: 1, mt: .5 }} />
                                </Box>
                            </ListItemButton>
                            <Collapse in={openListCondition} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {conditions?.map((t, i) => { return <ConditionListItem key={`condition-${i}`} condition={t ?? {}} /> })}
                                </List>
                            </Collapse>
                        </List>
                    </Paper>
                    <Button
                        onClick={handleNewCondtion}
                        disableElevation
                        variant="contained"
                        size="large"
                        fullWidth
                        color="secondary"
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        sx={{
                            mt: 2,
                            mb: 2,
                            textTransform: "none",
                            borderRadius: "8px",
                            // bgcolor: theme.palette.widget.main,
                            // "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                        }}>
                        <Typography color="inherit" fontSize={15} noWrap>{t("newCondition")}</Typography>
                    </Button>
                </Grid>
                <Grid item xs={10} sx={{ height: belowmd ? "calc(100vh - 178px)" : "calc(100vh - 124px)", pl: 2, pt: 2 }}>
                    <ConditionDetail setRefetch={setRefetch} setSaved={setSaved} fetchVersion={fetchVersion} ref={detailRef} />
                </Grid>
            </Grid>
            <ConditionDialog setConditions={setConditions} ref={conditionRef} />
            <Backdrop
                sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Fragment>
    );

}, (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data));
export default withAuthenticationRequired(Condition);