import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

const InsuranceTypeDetailDialog = forwardRef(({ update }, ref) => {

    const theme = useTheme();

    const { t } = useTranslation();

    const validTypes = ["product", "risk", "cause", "category", "object"];

    const [open, setOpen] = useState(false);
    const [type, setType] = useState("");
    const [error, setError] = useState(false);
    const [identifer, setIdentifier] = useState("");

    useImperativeHandle(ref, () => ({
        open(type) {
            setError(false);
            setType(type ?? "");
            setOpen(true);
            if (!validTypes.includes(type)) {
                setError(true);
            };
        }
    }));

    const handleClose = () => {
        setOpen(false);
    };

    const convertToId = value => {
        if (!value) return '';

        let newValue = value.toLowerCase();

        const replacements = {
            'ä': 'ae',
            'ö': 'oe',
            'ü': 'ue',
            'ß': 'ss'
        };

        for (const [char, replacement] of Object.entries(replacements)) {
            newValue = newValue.replace(new RegExp(char, 'g'), replacement);
        }

        newValue = newValue
            .replace(/\s+/g, '-')
            .replace(/[^a-z0-9-]/g, '')
            .replace(/-+/g, '-');

        return newValue;
    };

    const handleNewType = () => {
        const name = convertToId(identifer);
        let object;

        switch (type) {
            case "product":
                object = {
                    "product": name,
                    "label": identifer,
                    "risks": []

                };
                update(object);
                break;
            case "risk":
                object = {
                    "risk": name,
                    "label": identifer,
                    "causes": []
                };
                update(object);
                break;
            case "cause":
                object = {
                    "cause": name,
                    "label": identifer
                };
                update(object);
                break;
            case "category":
                object = {
                    "category": name,
                    "label": identifer,
                    "objects": []
                };
                update(object);
                break;
            case "object":
                object = {
                    "object": name,
                    "label": identifer
                };
                update(object);
                break;
            default:
                object = {};
                update(object);
                break;
        };
        setIdentifier("");
        setOpen(false);
    };

    const getTitleText = () => {
        switch (type) {
            case "product":
                return t("addNewProduct");
            case "risk":
                return t("addNewRisk");
            case "cause":
                return t("addNewCause");
            case "category":
                return t("addNewCategory");
            case "object":
                return t("addNewObject");
            default:
                return t("addNewError");
        };
    };

    const getDescriptionText = () => {
        switch (type) {
            case "product":
                return t("enterNewProduct");
            case "risk":
                return t("enterNewRisk");
            case "cause":
                return t("enterNewCause");
            case "category":
                return t("enterNewCategory");
            case "object":
                return t("enterNewObject");
            default:
                return t("enterNewError");
        };
    };

    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: { borderRadius: "14.362px" }
            }}
        >
            <DialogTitle fontWeight={600} sx={{ textAlign: "center" }}>{getTitleText()}</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ lineHeight: "normal", textAlign: "center" }}>{getDescriptionText()}</Typography>
                <TextField
                    value={identifer}
                    onChange={(event) => setIdentifier(event.target.value)}
                    required
                    slotProps={{
                        inputLabel: {
                            shrink: true
                        }
                    }}
                    sx={{
                        mt: 2,
                        width: "360px",
                        "& label.Mui-focused": {
                            color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                        },
                        "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                                borderColor: theme.palette.mode === "dark" ? theme.palette.action.disabled : theme.palette.action.active,
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                            }
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleNewType} disableElevation disabled={identifer === "" || error} sx={{ textTransform: "none", borderRadius: "8px", bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main", "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" } }}>{t("create")}</Button>
            </DialogActions>
        </Dialog>
    );

});

export default InsuranceTypeDetailDialog;