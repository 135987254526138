import { jwtDecode } from "jwt-decode";
import { useContext } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { AdminContext } from './AdminContext';

export const useAuthorityCheck = () => {

    const { user } = useAuth();
    const { admin, setAdmin } = useContext(AdminContext);

    const navigate = useNavigate();

    const decoded = user?.access_token ? jwtDecode(user.access_token) : { roles: [] };
    //SPA has token and roles array is populated
    const role = decoded?.roles?.length > 0 ? decoded?.roles[0] : "";
    const adminRegex = new RegExp(`\\.admin$`);

    const verify = () => {
        return adminRegex.test(role);
    };

    const verifyRedirect = () => {
        if (user?.access_token && !adminRegex.test(role)) {
            setAdmin({ ...admin, "enabled": false });
            const claimPage = localStorage.getItem("redirect");
            navigate(claimPage ? claimPage : "/login");
        };
        return;
    };

    return { verify, verifyRedirect };

};