import { useTheme } from "@emotion/react";
import { Box, Collapse, Grid, IconButton, Paper, Typography, useMediaQuery } from "@mui/material";
import { memo, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import TriangleIcon from '../../../icons/Triangle';
import { ClaimContext } from "../../../utils/ClaimContext";
import WidgetTextField from "../WidgetTextField";

const SummaryWidget = memo(() => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const [collapse, setCollapse] = useState(true);

    const { t } = useTranslation();

    const { claim } = useContext(ClaimContext);

    const handleCollapse = () => setCollapse(!collapse);

    return (
        <Grid item xs={12} sx={{ mt: 2 }}>
            <Paper
                elevation={2}
                sx={{
                    height: belowsm ? "auto" : "100%",
                    background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                    borderRadius: "14.362px",
                    boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                }}
            >
                <Grid container
                    sx={{
                        px: 1,
                        pb: 1,
                        borderBottom: collapse ? 1 : 0,
                        borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                    }}
                >
                    <Grid item xs={11}>
                        <Typography
                            noWrap
                            sx={{
                                color: collapse ? theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary" : theme.palette.widget.main,
                                fontSize: belowsm ? "20px" : "24px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                mt: belowsm ? .75 : .5,
                                pr: 2
                            }}
                        >
                            {t("completenessSummary")}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end", pr: belowsm ? 0 : 2 }}>
                        <IconButton
                            onClick={handleCollapse}
                            sx={{
                                transform: collapse ? 'rotate(0deg)' : 'rotate(180deg)',
                                transition: 'transform 250ms ease-in-out',
                            }}>
                            <TriangleIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Collapse
                    in={collapse}
                    mountOnEnter
                >
                    <Box sx={{ py: 1 }}>
                        <WidgetTextField
                            color="widget"
                            value={claim?.completenessData?.completenessMessage ?? t("noSummaryText")}
                            variant="standard"
                            fullWidth
                            focused
                            multiline
                            InputLabelProps={{
                                shrink: true,
                                sx: { px: 1.5 }
                            }}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                sx: {
                                    px: 1.5,
                                    color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"
                                }
                            }}
                            sx={{ mt: 1 }}
                        />
                    </Box>
                </Collapse>
            </Paper>
        </Grid>
    );
});

export default SummaryWidget;