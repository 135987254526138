import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Box, Grid, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { forwardRef, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { ClaimContext } from '../../../utils/ClaimContext';
import WidgetTextField from '../WidgetTextField';

const OverviewWidget = ({ obj }) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));

    const { t } = useTranslation();

    const { claim } = useContext(ClaimContext);

    const TextField = ({ value }) => {
        return <WidgetTextField
            color="widget"
            value={value?.api ?? ""}
            variant="standard"
            fullWidth
            focused
            multiline
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                    pl: 1,
                    pr: belowsm ? 2 : 4,
                    color: theme.palette.mode === "light" ? "rgba(255, 255, 255, 0.8)" : "#fff",
                }
            }}
        />
    };

    const SkeletonTextField = () => {
        return <WidgetTextField
            color="widget"
            variant="standard"
            fullWidth
            focused
            multiline
            InputProps={{
                inputComponent: forwardRef((props, ref) => { return <Skeleton variant="text" animation="wave" sx={{ width: "100%", pb: .1, mr: 1 }} /> }),
                readOnly: true,
                disableUnderline: true,
                sx: {
                    pl: 1,
                    pr: belowsm ? 2 : 4,
                    color: theme.palette.mode === "light" ? "rgba(255, 255, 255, 0.8)" : "#fff",
                }
            }}
        />
    };

    return (
        <Grid item xs={12} sx={{ display: "block" }}>
            <Grid container>
                <Grid item xs={12} sm={6} sx={{ pr: belowsm ? 0 : .75 }}>
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "14.362px",
                            backgroundColor: obj?.successCount === 0 ? theme.palette.mode === "dark" ? "#282828" : theme.palette.widget.paper : "success.main"
                        }}
                    >
                        <Grid container sx={{ px: 1, pt: .5, pb: 0, borderBottom: 2, borderBottomColor: theme.palette.mode === "light" ? obj?.successCount === 0 ? theme.palette.widget.divider : "rgba(255, 255, 255, 0.4)" : "divider" }}>
                            <Grid item>
                                <CheckCircleOutlineIcon
                                    sx={{
                                        color: obj?.successCount === 0 ? theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary" : "#fff",
                                        fontSize: belowsm ? "36px" : "40px"
                                    }}
                                />
                            </Grid>
                            <Grid item xs zeroMinWidth sx={{ height: belowsm ? "36px" : "42px", display: "flex", alignItems: "center" }}>
                                <Typography
                                    noWrap
                                    sx={{
                                        color: obj?.successCount === 0 ? theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary" : "#fff",
                                        fontSize: belowsm ? "20px" : "24px",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "normal",
                                        pl: 1
                                    }}
                                >
                                    {obj?.successCount === 1 ? t("oneAspectSuccess") : t("aspectSuccess", { count: obj?.successCount })}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box sx={{ p: 1, pt: 0 }}>
                            {claim?.successfulStates?.length <= 1 && claim?.currentStage !== "COMPLETED" && claim?.lastSuccessfulState !== "BILLING_STATISTICS" ?
                                Object.entries(obj?.data?.success ?? {}).map(([key, value]) => { return <SkeletonTextField key={`SkeletonTextField-${key}`} value={value} /> }) :
                                Object.entries(obj?.data?.success ?? {}).map(([key, value]) => { return <TextField key={`TextField-${key}`} value={value} /> })
                            }
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ pl: belowsm ? 0 : .75, mt: belowsm ? 2 : 0, }}>
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "14.362px",
                            backgroundColor: obj?.errorCount === 0 ? theme.palette.mode === "dark" ? "#282828" : theme.palette.widget.paper : "error.main"
                        }}
                    >
                        <Grid container sx={{ px: 1, pt: .5, pb: 0, borderBottom: 2, borderBottomColor: theme.palette.mode === "light" ? obj?.errorCount === 0 ? theme.palette.widget.divider : "rgba(255, 255, 255, 0.4)" : "divider" }}>
                            <Grid item>
                                <CancelOutlinedIcon
                                    sx={{
                                        color: obj?.errorCount === 0 ? theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary" : "#fff",
                                        fontSize: belowsm ? "36px" : "40px"
                                    }}
                                />
                            </Grid>
                            <Grid item xs zeroMinWidth sx={{ height: belowsm ? "36px" : "42px", display: "flex", alignItems: "center" }}>
                                <Typography
                                    noWrap
                                    sx={{
                                        color: obj?.errorCount === 0 ? theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary" : "#fff",
                                        fontSize: belowsm ? "20px" : "24px",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "normal",
                                        pl: 1
                                    }}
                                >
                                    {obj?.errorCount === 1 ? t("oneAspectError") : t("aspectError", { count: obj?.errorCount })}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box sx={{ p: 1, pt: 0 }}>
                            {claim?.successfulStates?.length <= 1 && claim?.currentStage !== "COMPLETED" && claim?.lastSuccessfulState !== "BILLING_STATISTICS" ?
                                Object.entries(obj?.data?.error ?? {}).map(([key, value]) => { return <SkeletonTextField key={`SkeletonTextField-${key}`} value={value} /> }) :
                                Object.entries(obj?.data?.error ?? {}).map(([key, value]) => { return <TextField key={`TextField-${key}`} value={value} /> })
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default OverviewWidget;