import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { isEqual } from "lodash";
import { memo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClaimContext } from "../../../utils/ClaimContext";
import { useConvertDto } from '../../../utils/useConvertDto';
import Widget from '../Widget';
import ConfidenceWidget from "./ConfidenceWidget";
import ContactWidget from "./ContactWidget";
import DamageObjectsWidget from "./DamageObjectsWidget";
import OverviewWidget from './OverviewWidget';
import ComplexWidget from "./ComplexWidget";

const Overview = memo(() => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const { claim } = useContext(ClaimContext);

    const { t } = useTranslation();

    const carCondition = claim?.type === "VEHICLE" ? true : false;
    const phvCondition = claim?.type === "PROPERTY" ? true : false;
    const processing = claim?.successfulStates?.length < 3 && claim?.currentStage !== "COMPLETED" && claim?.lastSuccessfulState !== "BILLING_STATISTICS";
    const noClaim = claim?.flags?.claim ? false : true;
    const personalInjury = claim?.flags?.personalInjury ?? false

    const { getClaimIdentification, getClaimClassification, getClaimData, getClaimDescription, getClaimDamageObjects, getClaimComplex, getClaimCar, getClaimPhv, getClaimOverview, getClaimConfidence, getClaimContact } = useConvertDto();

    if (!claim?.claimUUID) {
        return (
            <Box sx={{ width: belowsm ? "90vw" : "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Typography variant={belowmd ? "h6" : "h5"} color={theme.palette.text.primary} sx={{ textAlign: "center", fontWeight: "bold" }}>{t("noClaimAvailable")}</Typography>
            </Box>
        )
    };

    if (processing) {
        return (
            <Box sx={{ width: belowsm ? "90vw" : "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Typography variant={belowmd ? "h6" : "h5"} color={theme.palette.text.primary} sx={{ textAlign: "center", fontWeight: "bold" }}>{t("reportClaimProcessing")}</Typography>
            </Box>
        )
    };

    if (noClaim) {
        return (
            <Box sx={{ width: belowsm ? "90vw" : "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Typography variant={belowmd ? "h6" : "h5"} color={theme.palette.text.primary} sx={{ textAlign: "center", fontWeight: "bold" }}>{t("reportNoClaim")}</Typography>
                <Typography color={theme.palette.text.primary} sx={{ textAlign: "center", fontWeight: "normal", mt: 1, width: "80%" }}>{claim?.notifications[0]?.summary ?? ""}</Typography>
            </Box>
        );
    };

    if (personalInjury) {
        return (
            <Box sx={{ width: belowsm ? "90vw" : "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant={belowmd ? "h6" : "h5"} color={theme.palette.text.primary} sx={{ textAlign: "center", fontWeight: "normal" }}>{t("claimPersonalInjury")}</Typography>
            </Box>
        );
    };

    return (
        <Grid container direction={belowsm ? "row" : "column"} sx={{ pb: 0.5 }}>
            <Grid item xs={12}>
                <OverviewWidget obj={getClaimOverview()} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Widget obj={getClaimIdentification()} oneLine={true} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Grid container>
                    <Widget obj={getClaimClassification()} initialOpen={true} size={{ left: false, right: true }} />
                    <Widget obj={getClaimData()} initialOpen={true} size={{ left: true, right: false }} />
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Widget obj={getClaimDescription()} fullSize={true} noMaxRows={true} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2, display: claim?.damagedObjects?.length > 0 ? "block" : "none" }}>
                <DamageObjectsWidget obj={getClaimDamageObjects()} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2, display: claim?.complex?.insurances?.length > 0 ? "block" : "none" }}>
                <ComplexWidget obj={getClaimComplex()} />
            </Grid>
            <Grid item xs={12} sx={{ mt: carCondition ? 2 : 0 }}>
                <Widget obj={getClaimCar()} display={carCondition} />
            </Grid>
            <Grid item xs={12} sx={{ mt: phvCondition ? 2 : 0 }}>
                <Widget obj={getClaimPhv()} display={phvCondition} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2, display: claim?.classification?.contacts?.length > 0 ? "block" : "none" }}>
                <ContactWidget obj={getClaimContact()} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2, display: claim?.confidences ? "block" : "none" }}>
                <ConfidenceWidget obj={getClaimConfidence()} />
            </Grid>
        </Grid>
    );
}, (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data));

export default Overview;