import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { Backdrop, Box, Button, CircularProgress, Grid, Link, List, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Tab, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import MdEditor from '@uiw/react-markdown-editor';
import { isEqual } from 'lodash';
import { Fragment, forwardRef, memo, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import rehypeSanitize from "rehype-sanitize";
import SnackBar from '../../../utils/SnackBar';
import { useAxios } from '../../../utils/useAxios';
import ConditionDeleteDialog from '../../Dialog/ConditionDeleteDialog';

const ConditionDetail = memo(forwardRef(({ setRefetch, setSaved, fetchVersion }, ref) => {

    const theme = useTheme();
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const { t } = useTranslation();

    const types = ["AVB", "BVB"];
    const [insuranceTypes, setInsuranceTypes] = useState([]);
    const [open, setOpen] = useState(false);
    const [tab, setTab] = useState("1");
    const [id, setId] = useState(null);
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    //eslint-disable-next-line
    const [condition, setCondition] = useState({});
    const [insuranceType, setInsuranceType] = useState([]);
    const [insurance, setInsurance] = useState("");
    const [risk, setRisk] = useState("");
    const [width, setWidth] = useState(window.innerWidth < 1440 ? `${window.innerWidth * .7}px` : "1107px");
    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [converting, setConverting] = useState(false);
    const [error, setError] = useState(false);

    const viewRef = useRef(null);
    const fileInputRef = useRef(null);
    const deleteRef = useRef(null);
    const snackBarRef = useRef(null);
    const markdownRef = useRef(``);

    const axiosInstance = useAxios();

    useLayoutEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth < 1440 ? `${window.innerWidth * .7}px` : "1107px");
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useImperativeHandle(ref, () => ({
        open(condition, insuranceTypes) {
            viewRef.current.scrollTo(0, 0);
            const mutualInsuranceTypes = condition?.insuranceType?.filter(type =>
                insuranceTypes?.some(insuranceTypeObj => insuranceTypeObj?.label === type)
            ) ?? [];
            setCondition(condition);
            setInsuranceTypes(insuranceTypes);
            setInsuranceType(mutualInsuranceTypes);
            setInsurance(condition?.insuranceType?.length === 1 ? condition?.insurance : "");
            setRisk(condition?.insuranceType?.length === 1 ? condition?.risk : "");
            setId(condition?.version);
            setType(condition?.conditionType ?? "");
            setName(condition?.product ?? "");
            markdownRef.current = condition?.markdown ?? ``;
            setOpen(true);
            setError(false);
            setTab("1");
        },
        reset() {
            setOpen(false);
            setError(false);
            setInsuranceType([]);
            setInsurance("");
            setRisk("");
            setId(null);
            setType("");
            setName("");
            markdownRef.current = ``;
        }
    }));

    const uploadPdf = async (file) => {
        setLoading(true);
        setConverting(true);
        const formData = new FormData();
        formData.append("file", file);

        await axiosInstance.current({
            url: "/admin/insuranceproductdefinitions/convert",
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(res => markdownRef.current = res.data).catch(err => console.error(err)).finally(() => {
            setTab("1");
            setLoading(false);
            setConverting(false);
        });
    };

    const save = async () => {
        if (!markdownRef.current || markdownRef.current === ``) {
            setError(true);
            setTab("1");
            snackBarRef.current.custom("error", t("markdownEmpty"));
            return;
        };
        if (!types?.includes(type) || name === "") {
            setError(true);
            setTab("2");
            return;
        };
        setSaveLoading(true);
        const body = {
            ...condition,
            avb: type === "AVB",
            bvb: type === "BVB",
            conditionType: type,
            product: name,
            insuranceType: insuranceType,
            insurance: insurance,
            risk: risk,
            markdown: markdownRef.current
        };

        await axiosInstance.current({
            url: "/admin/insuranceproductdefinitions",
            method: "PUT",
            data: body
        }).then(() => {
            setSaved(true);
            setRefetch(true);
            fetchVersion(id);
            snackBarRef.current.custom("success", t("conditionSaved", { version: id }));
        }).catch(err => console.error(err)).finally(() => setSaveLoading(false));
    };

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleTypeChange = event => {
        if (error && markdownRef.current !== `` && types?.includes(type) && name !== "") {
            setError(false);
        };
        setType(event.target.value);
    };

    const handleNameChange = event => {
        if (error && markdownRef.current !== `` && types?.includes(type) && name !== "") {
            setError(false);
        };
        setName(event.target.value);
    };

    const handleMarkdownChange = (value) => {
        if (error && value !== `` && types?.includes(type) && name !== "") {
            setError(false);
        };
        markdownRef.current = value
    };

    const handleFiles = () => fileInputRef.current.click();

    const onFileChange = (event) => uploadPdf(event.target.files[0]);

    const handleDelete = () => deleteRef.current.delete(id);

    const getMatchingInsurance = () => {
        const index = insuranceTypes?.findIndex(i => i?.label === insuranceType[0]);

        if (index === -1) {
            return [];
        };

        return insuranceTypes[index]?.products ?? [];
    };

    const getMatchingRisk = () => {
        const matchingInsurance = getMatchingInsurance();

        const index = matchingInsurance?.findIndex(i => i?.label === insurance);

        if (matchingInsurance.length === 0) {
            return [];
        };

        if (index === -1) {
            return [];
        };

        return matchingInsurance[index]?.risks ?? [];
    };

    const NoView = () => {
        return (
            <Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography noWrap color={theme.palette.text.primary}>{t("noConditionView")}</Typography>
            </Box>
        );
    };

    const InsuranceTypeListItem = ({ insuranceTypeObj }) => {

        const handleInsuranceType = () => {
            const insuranceTypeList = [...insuranceType];
            if (insuranceTypeList.includes(insuranceTypeObj?.label)) {
                insuranceTypeList.splice(insuranceTypeList.indexOf(insuranceTypeObj?.label), 1);
            } else {
                insuranceTypeList.push(insuranceTypeObj?.label);
            };
            setInsuranceType(insuranceTypeList);
            setInsurance("");
            setRisk("");
        };

        const isSelected = () => insuranceType?.includes(insuranceTypeObj?.label);

        if (!insuranceTypeObj?.label) {
            return null;
        };

        return (
            <ListItemButton selected={insuranceType?.includes(insuranceTypeObj?.label)} onClick={handleInsuranceType} disabled={saveLoading} sx={{ pr: 0 }}>
                <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? isSelected() ? theme.palette.widget.main : theme.palette.widget.contrastText : "text.primary"}>{insuranceTypeObj?.label ?? ""}</Typography>} />
                <ListItemIcon sx={{ display: isSelected() ? "inline-flex" : "none", color: theme.palette.mode === "dark" ? theme.palette.success.main : theme.palette.success.light }}>
                    <CheckIcon color="inherit" />
                </ListItemIcon>
            </ListItemButton>
        );
    };

    const InsuranceListItem = ({ insuranceObj }) => {

        const handleInsurance = () => {
            if (insuranceObj?.label === insurance) {
                setInsurance("");
            } else {
                setInsurance(insuranceObj?.label ?? "")
            };
            setRisk("");
        };

        if (!insuranceObj?.label || insuranceType.length !== 1) {
            return null;
        };

        return (
            <ListItemButton selected={insuranceObj?.label === insurance} onClick={handleInsurance} disabled={saveLoading} sx={{ pr: 0 }}>
                <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? insuranceObj?.label === insurance ? theme.palette.widget.main : theme.palette.widget.contrastText : "text.primary"}>{insuranceObj?.label ?? ""}</Typography>} />
                <ListItemIcon sx={{ display: insuranceObj?.label === insurance ? "inline-flex" : "none", color: theme.palette.mode === "dark" ? theme.palette.success.main : theme.palette.success.light }}>
                    <CheckIcon color="inherit" />
                </ListItemIcon>
            </ListItemButton>
        );
    };

    const RiskListItem = ({ riskObj }) => {

        const handleRisk = () => {
            if (riskObj?.label === risk) {
                setRisk("");
            } else {
                setRisk(riskObj?.label ?? "")
            };
        };

        if (!riskObj?.label || insuranceType.length !== 1 || insurance === "") {
            return null;
        };

        return (
            <ListItemButton selected={riskObj?.label === risk} onClick={handleRisk} disabled={saveLoading} sx={{ pr: 0 }}>
                <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? riskObj?.label === risk ? theme.palette.widget.main : theme.palette.widget.contrastText : "text.primary"}>{riskObj?.label ?? ""}</Typography>} />
                <ListItemIcon sx={{ display: riskObj?.label === risk ? "inline-flex" : "none", color: theme.palette.mode === "dark" ? theme.palette.success.main : theme.palette.success.light }}>
                    <CheckIcon color="inherit" />
                </ListItemIcon>
            </ListItemButton>
        );
    };

    return (
        <Fragment>
            <Box ref={viewRef} sx={{ height: belowmd ? "calc(100vh - 223px)" : "calc(100vh - 165px)", overflow: "auto", borderRadius: "14.362px" }}>
                {open ?
                    <Grid container direction="column" sx={{ width: "auto", height: belowmd ? "calc(100vh - 236px)" : "calc(100vh - 177px)" }}>
                        <Grid item xs={12} sx={{ height: theme.palette.mode === "light" ? "101%" : "auto", pb: theme.palette.mode === "light" ? .5 : 0 }}>
                            <Paper
                                elevation={2}
                                sx={{
                                    background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "14.362px",
                                    boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                                }}
                            >
                                <TabContext value={tab}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider', color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary" }}>
                                        <TabList textColor="inherit" variant="fullWidth" onChange={handleTabChange}>
                                            <Tab label={t("conditionsEdit")} value="1" sx={{ color: error && markdownRef.current === `` ? "error.main" : "inherit", textTransform: "none", fontSize: "18px", fontWeight: 600 }} />
                                            <Tab label={t("conditionsAssign")} value="2" sx={{ color: error && (!types?.includes(type) || name === "") ? "error.main" : "inherit", textTransform: "none", fontSize: "18px", fontWeight: 600 }} />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="2">
                                        <Grid container>
                                            <Grid item xs={2} sx={{ pr: 1 }}>
                                                <TextField
                                                    label={t("typeStandalone")}
                                                    value={type}
                                                    onChange={handleTypeChange}
                                                    fullWidth
                                                    select
                                                    required
                                                    error={error && !types?.includes(type)}
                                                    helperText={error && !types?.includes(type) ? t("invalidType") : " "}
                                                    InputLabelProps={{ shrink: true }}
                                                    disabled={saveLoading}
                                                    sx={{
                                                        "& label.Mui-focused": {
                                                            color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                                        },
                                                        "& .MuiOutlinedInput-root": {
                                                            "&:hover fieldset": {
                                                                borderColor: theme.palette.mode === "dark" ? theme.palette.action.disabled : theme.palette.action.active,
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                                            }
                                                        }
                                                    }}
                                                >
                                                    {types.map(t => (
                                                        <MenuItem key={t} value={t}>
                                                            {t}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={10} sx={{ pl: 1 }}>
                                                <TextField
                                                    label={t("name")}
                                                    value={name}
                                                    onChange={handleNameChange}
                                                    fullWidth
                                                    required
                                                    error={error && name === ""}
                                                    helperText={error && name === "" ? t("missingName") : " "}
                                                    InputLabelProps={{ shrink: true }}
                                                    disabled={saveLoading}
                                                    sx={{
                                                        "& label.Mui-focused": {
                                                            color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                                        },
                                                        "& .MuiOutlinedInput-root": {
                                                            "&:hover fieldset": {
                                                                borderColor: theme.palette.mode === "dark" ? theme.palette.action.disabled : theme.palette.action.active,
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                                            }
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ mt: 1 }}>
                                            <Grid item xs={4} sx={{ pr: 1.3 }}>
                                                <Box sx={{ height: belowmd ? "calc(100vh - 415px)" : "calc(100vh - 355px)", width: "100%", border: 1, borderColor: theme.palette.mode === "dark" ? "#565656" : "#bfbdbc", borderRadius: "6px" }}>
                                                    <Typography color="text.secondary" fontSize={12} sx={{ ml: 1, px: .5, mt: -1.2, bgcolor: theme.palette.mode === "light" ? theme.palette.widget.paper : "#232323", width: "38px" }}>{t("branch")}</Typography>
                                                    <List disablePadding sx={{ height: "100%", overflow: "auto" }}>
                                                        {insuranceTypes?.map((t, i) => { return <InsuranceTypeListItem key={`insuranceType-${i}`} insuranceTypeObj={t ?? {}} /> })}
                                                    </List>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} sx={{ px: .7 }}>
                                                <Box sx={{ height: belowmd ? "calc(100vh - 415px)" : "calc(100vh - 355px)", width: "100%", border: 1, borderColor: theme.palette.mode === "dark" ? "#565656" : "#bfbdbc", borderRadius: "6px" }}>
                                                    <Typography color="text.secondary" fontSize={12} sx={{ ml: 1, px: .5, mt: -1.2, bgcolor: theme.palette.mode === "light" ? theme.palette.widget.paper : "#232323", width: "75px" }}>{t("insurance")}</Typography>
                                                    {insuranceType?.length === 0 ?
                                                        <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", px: 1 }}>
                                                            <Typography color="text.secondary" sx={{ textAlign: "center" }}>{t("selectInsuranceTypeFirst")}</Typography>
                                                        </Box>
                                                        : insuranceType.length === 1 && getMatchingInsurance()?.length === 0 ?
                                                            <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", px: 1 }}>
                                                                <Typography color="text.secondary" sx={{ textAlign: "center" }}>{t("noInsuranceAvailable")}</Typography>
                                                            </Box>
                                                            : insuranceType.length === 1 ?
                                                                <List disablePadding sx={{ height: "100%", overflow: "auto" }}>
                                                                    {getMatchingInsurance()?.map((p, i) => { return <InsuranceListItem key={`insurance-${i}`} insuranceObj={p ?? {}} /> })}
                                                                </List>
                                                                : insuranceType.length > 1 ?
                                                                    <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", px: 1 }}>
                                                                        <Typography color="text.secondary" sx={{ textAlign: "center" }}>{t("selectOnlyOneInsuranceType")}</Typography>
                                                                    </Box>
                                                                    : null}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} sx={{ pl: 1.3 }}>
                                                <Box sx={{ height: belowmd ? "calc(100vh - 415px)" : "calc(100vh - 355px)", width: "100%", border: 1, borderColor: theme.palette.mode === "dark" ? "#565656" : "#bfbdbc", borderRadius: "6px" }}>
                                                    <Typography color="text.secondary" fontSize={12} sx={{ ml: 1, px: .5, mt: -1.2, bgcolor: theme.palette.mode === "light" ? theme.palette.widget.paper : "#232323", width: "35px" }}>{t("risk")}</Typography>
                                                    {insuranceType?.length === 0 && insurance === "" ?
                                                        <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", px: 1 }}>
                                                            <Typography color="text.secondary" sx={{ textAlign: "center" }}>{t("selectInsuranceTypeAndInsuranceFirst")}</Typography>
                                                        </Box>
                                                        : insuranceType.length === 1 && insurance === "" ?
                                                            <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", px: 1 }}>
                                                                <Typography color="text.secondary" sx={{ textAlign: "center" }}>{t("selectInsurance")}</Typography>
                                                            </Box>
                                                            : insuranceType.length === 1 && insurance !== "" && getMatchingRisk()?.length === 0 ?
                                                                <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    <Typography color="text.secondary" sx={{ textAlign: "center" }}>{t("noRiskAvailable")}</Typography>
                                                                </Box>
                                                                : insuranceType.length === 1 && insurance !== "" ?
                                                                    <List disablePadding sx={{ height: "100%", overflow: "auto" }}>
                                                                        {getMatchingRisk()?.map((r, i) => { return <RiskListItem key={`risk-${i}`} riskObj={r ?? {}} /> })}
                                                                    </List>
                                                                    : insuranceType.length > 1 ?
                                                                        <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", px: 1 }}>
                                                                            <Typography color="text.secondary" sx={{ textAlign: "center" }}>{t("selectOnlyOneInsuranceTypeRisk")}</Typography>
                                                                        </Box>
                                                                        : null}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value="1" sx={{ pb: 0 }}>
                                        <Grid container sx={{ mb: 2 }}>
                                            <Typography color="text.primary">
                                                {t("updateInsuranceTypeText")}
                                                <Link
                                                    onClick={() => window.open("https://www.markdownguide.org/cheat-sheet/", "_blank")}
                                                    component="span"
                                                    sx={{
                                                        ml: .5,
                                                        mt: .25,
                                                        fontFamily: "Ubuntu",
                                                        fontSize: "18px",
                                                        color: theme.palette.widget.main,
                                                        textDecoration: "underline",
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    {t("here")}
                                                </Link>
                                                .
                                            </Typography>
                                        </Grid>
                                        <Box sx={{ border: 1, borderColor: error && markdownRef.current === `` ? "error.main" : "divider", mb: 2, width: "auto" }}>
                                            <MdEditor
                                                value={markdownRef?.current}
                                                visible
                                                autoFocus
                                                readOnly={saveLoading}
                                                width={width}
                                                height={belowmd ? "calc(100vh - 436px)" : "calc(100vh - 353px)"}
                                                onChange={handleMarkdownChange}
                                                toolbars={[
                                                    // 'undo', 'redo', 'bold', 'italic', 'header', 'underline', 'olist', 'ulist', 'todo', 'quote', 'link'
                                                    'undo', 'redo', 'bold', 'header', 'olist', 'ulist'
                                                ]}
                                                previewProps={{
                                                    rehypePlugins: [[rehypeSanitize]]
                                                }}
                                            // toolbarsMode={['preview']}
                                            />
                                        </Box>
                                    </TabPanel>
                                </TabContext>
                            </Paper>
                        </Grid>
                    </Grid> :
                    <NoView />
                }
            </Box>
            <Backdrop
                sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <CircularProgress color="inherit" />
                    <Typography color={theme.palette.mode === "dark" ? "text.primary" : "#fff"} sx={{ mt: 2, display: converting ? "flex" : "none" }}>{t("pdfConverting")}</Typography>
                </Grid>
            </Backdrop>
            <SnackBar ref={snackBarRef} />
            <ConditionDeleteDialog setRefetch={setRefetch} setLoading={setLoading} snackBarRef={snackBarRef} ref={deleteRef} />
            <Grid container>
                <Box sx={{ flexGrow: 1 }} />
                <Button
                    onClick={handleFiles}
                    disableElevation
                    variant="outlined"
                    size="large"
                    disabled={!id || loading || saveLoading}
                    startIcon={<PictureAsPdfOutlinedIcon />}
                    sx={{
                        mt: 2,
                        mb: 2,
                        mr: 1,
                        textTransform: "none",
                        border: 1.5,
                        borderRadius: "8px",
                        borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                        color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                        "&:hover": {
                            border: 1.5,
                            borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                        }
                    }}>
                    <input
                        accept=".pdf"
                        type="file"
                        multiple
                        onChange={onFileChange}
                        onClick={event => event.target.value = null}
                        ref={fileInputRef}
                        hidden
                    />
                    <Typography fontSize="15px" fontWeight={500} noWrap>{t("uploadNewPdf")}</Typography>
                </Button>
                <Button
                    disableElevation
                    variant="contained"
                    size="large"
                    onClick={handleDelete}
                    disabled={!id || loading || saveLoading}
                    endIcon={<DeleteOutlinedIcon />}
                    sx={{
                        mt: 2,
                        mb: 2,
                        mr: 1,
                        textTransform: "none",
                        borderRadius: "8px",
                        bgcolor: theme.palette.widget.main,
                        "&:hover": { bgcolor: "error.main" }
                    }}>{t("delete")}
                </Button>
                <LoadingButton
                    disableElevation
                    variant="contained"
                    size="large"
                    onClick={save}
                    loading={saveLoading}
                    disabled={!id || loading || saveLoading || error}
                    endIcon={<CheckCircleOutlineIcon />}
                    sx={{
                        mt: 2,
                        mb: 2,
                        textTransform: "none",
                        borderRadius: "8px",
                        bgcolor: theme.palette.widget.main,
                        "&:hover": { bgcolor: "success.main" }
                    }}>{t("save")}
                </LoadingButton>
            </Grid>
        </Fragment>
    );

}), (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data));
export default ConditionDetail;