import { Backdrop, Box, CircularProgress, Collapse, Container, Grid, IconButton, List, ListItemButton, ListItemText, Paper, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { isEqual } from "lodash";
import { Fragment, memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from 'react-oidc-context';
import { useNavigate, useParams } from "react-router-dom";
import TriangleIcon from '../../icons/Triangle';
import noPreviewDark from '../../images/no-preview-available-dark.png';
import noPreviewLight from '../../images/no-preview-available-light.png';
import { ClaimContext } from '../../utils/ClaimContext';
import SnackBar from "../../utils/SnackBar";
import { useAxios } from "../../utils/useAxios";
import { useInterval } from "../../utils/useInterval";
import ViewHandler from './ViewHandler';

const Complete = memo(() => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const navigate = useNavigate();

    const { claim, setClaim } = useContext(ClaimContext);

    const { t } = useTranslation();

    // const query = new URLSearchParams(location.search);
    let { claimUUID } = useParams();
    const claimUUIDfromURL = claimUUID;
    const claimUUIDfromStorage = localStorage.getItem("claim");
    const [failed, setFailed] = useState(false);
    const [error, setError] = useState((!claimUUIDfromURL && !claimUUIDfromStorage) || failed);
    const [loading, setLoading] = useState(belowsm ? false : true);
    const [multiplePhotos, setMultiplePhotos] = useState(false);
    const [failedAttachments, setFailedAttachments] = useState([]);
    const [openListClaim, setOpenListClaim] = useState(belowsm ? false : true);
    const [openListAttachments, setOpenListAttachments] = useState(belowsm ? false : true);
    const [processing, setProcessing] = useState(false);

    const viewHandlerRef = useRef(null);
    const attempted = useRef(false);
    const preview = useRef([]);
    const noPreviewDarkURL = useRef(null);
    const noPreviewLightURL = useRef(null);
    const SnackBarRef = useRef(null);

    const axiosInstance = useAxios();

    //use memoization for claim stages processing
    const claimMemo = useMemo(() => claim, [claim]);

    useEffect(() => {
        document.title = t("completeTitle");
        if (!claimMemo?.claimUUID && !attempted.current && (claimUUIDfromURL || claimUUIDfromStorage)) {
            fetchClaim();
        } else if (!claimUUID) {
            setLoading(false);
            setError(true);
        }
        if (belowsm || error) {
            setOpenListClaim(false);
            setOpenListAttachments(false);
        } else {
            setOpenListClaim(true);
            setOpenListAttachments(true);
        };
        //eslint-disable-next-line
    }, [claimMemo, belowsm, failed])

    useInterval(() => {
        if (claimMemo?.successfulStates?.length === claimMemo?.scheduledStates || claimMemo?.lastSuccessfulState === "ERROR" || claimMemo?.lastSuccessfulState === "END_ERROR" || (!claimUUIDfromURL && !claimUUIDfromStorage) || failed) {
        } else {
            fetchClaim();
        }
    }, 3000);

    const createObjectUrl = (image, dark) => {
        fetch(image)
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                if (dark) {
                    noPreviewDarkURL.current = url;
                } else {
                    noPreviewLightURL.current = url;
                }
            })
            .catch(err => console.error(err));
    };

    const fetchFallback = async () => {
        await createObjectUrl(noPreviewDark, true);
        await createObjectUrl(noPreviewLight, false);
    };

    const fetchClaim = async () => {
        if (!attempted.current) {
            setLoading(true);
        };
        await axiosInstance.current({
            url: `/claims/${claimUUIDfromURL || claimUUIDfromStorage}`,
            method: "GET"
        }).then((res) => {
            const newClaim = res?.data;
            if (!isEqual(claim, newClaim)) {
                setClaim(newClaim ?? {});
                if (newClaim?.claimUUID) {
                    if (newClaim?.attachments?.length > 0) {
                        let count = 0;
                        for (let i = 0; i < newClaim?.attachments?.length; i++) {
                            const attachment = newClaim?.attachments[i];
                            if (attachment?.documentType === "Foto des Schadens") {
                                count += 1;
                                if (count >= 2) {
                                    setMultiplePhotos(true);
                                };
                            };
                        };
                    };
                    fetchAllAttachments(newClaim);
                    if (preview.current?.length === 0) {
                        fetchFallback();
                    }
                    setFailed(false);
                };
            };
            if (res?.data?.successfulStates?.length === res?.data?.scheduledStates && processing) {
                SnackBarRef.current.completed();
            } else if (res?.data?.lastSuccessfulState === "ERROR" || res?.data?.lastSuccessfulState === "END_ERROR" || res?.data?.currentStage === "FAILED") {
                SnackBarRef.current.failed();
            } else if (res?.data?.successfulStates?.length !== res?.data?.scheduledStates) {
                SnackBarRef.current.stage(res?.data?.successfulStates?.length + 1 ?? 0, res?.data?.scheduledStates);
                setProcessing(true);
            };
        }).catch(() => {
            if (belowsm) {
                navigate("/claims");
            }
            setFailed(true);
        }).finally(() => setLoading(false));
        attempted.current = true;
        //mock claim
        // setClaim({
        //     "claimUUID": "966c5fe2-68bd-4524-a561-f3ffed677d72",
        //     "foreignKey": null,
        //     "user": "efnol",
        //     "customer": "wuestenrot",
        //     "claimNumber": "",
        //     "policyNumber": "WW-12222 334 /A",
        //     "policySummary": null,
        //     "product": null,
        //     "insuredName": "Hubert Maier",
        //     "type": "PROPERTY",
        //     "confidences": [{
        //         "key": "insurancetype",
        //         "label": "Hausratversicherung",
        //         "confidence": 0.9,
        //         "description": "Die Beschädigung von Gartenzwergen als Hausrat ist eindeutig der Hausratversicherung zuzuordnen, was durch die BVB G23 bestätigt wird."
        //     }, {
        //         "key": "lossdate",
        //         "label": "Schadendatum",
        //         "confidence": 0.7,
        //         "description": "Das Datum wird aus der Formulierung 'Gestern' abgeleitet, was den 1.3.2025 ergibt. Konfidenz reduziert da indirekte Datumsangabe."
        //     }, {
        //         "key": "insurance",
        //         "label": "Versicherung",
        //         "confidence": 0.95,
        //         "description": "Die Polizennummer und Bedingungsverweise bestätigen eindeutig das Vorliegen einer Basis-Hausratversicherung."
        //     }, {
        //         "key": "risk",
        //         "label": "Risiko",
        //         "confidence": 0.85,
        //         "description": "Die Zuordnung zum Risiko Einbruch/Vandalismus ist durch die Schadensbeschreibung und BVB G23 klar belegt."
        //     }, {
        //         "key": "coverage",
        //         "label": "Deckung",
        //         "confidence": 0.8,
        //         "description": "Die Deckungseinschätzung ist durch BVB G23 gut begründet, aber der fehlende Nachweis der Polizeianzeige schafft Unsicherheit."
        //     }],
        //     "flags": {
        //         "consistent": false,
        //         "claim": true,
        //         "multipleConcerns": false,
        //         "selfInflicted": false,
        //         "personalInjury": false,
        //         "complex": false,
        //         "containsHandwrittenText": false
        //     },
        //     "classification": {
        //         "messageType": "Schadenerstmeldung",
        //         "insuranceType": "Hausratversicherung",
        //         "insuranceTypeReasoning": "Es handelt sich um einen Vandalismus-Schaden an beweglichen Gegenständen (Gartenzwerge) im Außenbereich des Grundstücks. Da es sich um bewegliche Gegenstände handelt, die zum Hausrat zählen und nicht fest mit dem Gebäude verbunden sind, fällt der Schaden in die Hausratversicherung. Der Schaden wurde durch einen unbekannten Dritten vorsätzlich verursacht (Vandalismus nach Einbruch in den Garten).",
        //         "insurance": "Basis-Hausratversicherung",
        //         "risk": "Einbruch",
        //         "cause": "Vandalismus",
        //         "damageSum": "Der geschätzte Betrag für die Wiederbeschaffung der beschädigten Gartenzwerge beträgt ca. 400 EUR. Der Betrag wurde ermittelt aus der Angabe des Versicherungsnehmers zu den ursprünglichen Anschaffungskosten.",
        //         "reserve": "400 EUR",
        //         "fraud": "Keine offensichtlichen Betrugsmerkmale, jedoch sollte eine Prüfung der Einbruchsspuren erfolgen.",
        //         "lossDate": "01.03.2025",
        //         "lossTime": "unbekannt",
        //         "lossLocation": "Garten des Versicherungsnehmers",
        //         "lossCountry": "unbekannt",
        //         "refersRiskLocation": true,
        //         "lossDescription": "Einbruch in den Garten nach einem Zeltfest, Gartenzwerge wurden beschädigt."
        //     },
        //     "complex": null,
        //     "damagedObjects": [{
        //         "insuranceType": "Hausratversicherung",
        //         "objectCategory": "Dekorationsgegenstände",
        //         "specificObject": "Figuren",
        //         "damageDescription": "Gartenzwerge wurden geköpft"
        //     }],
        //     "notifications": [{
        //         "message": "Hallo,\n\nGestern nach dem Zeltfest ist jemand im Garten eingebrochen und hat meine Gartenzwerge geköpft. War wohl betrunken.\n\nIst das versichert? Neu haben die über 400 Euro gekostet, die Rechnungen hab ich noch irgendwo.\n\nDanke\nHubert Maier\n\n\nAch ja: meine Police: WW-12222 334 /A\n\n\n",
        //         "sentAt": "2025-03-02T12:51:30.041Z",
        //         "sender": "office@deatech.at",
        //         "reporter": "Hubert Maier",
        //         "contactEmail": "office@deatech.at",
        //         "summary": "Der Versicherungsnehmer Hubert Maier meldet einen Schaden an seinen Gartenzwergen, die durch einen mutmaßlich betrunkenen Eindringling nach einem Zeltfest beschädigt wurden. Der Schaden beläuft sich auf ca. 400 EUR. Rechnungen sind laut Versicherungsnehmer vorhanden, aber nicht beigefügt."
        //     }],
        //     "vehicle": null,
        //     "property": {},
        //     "liability": null,
        //     "payment": {},
        //     "paymentSummary": "Da mir nur die Einschätzung von Experte 1 vorliegt, kann ich keinen Vergleich und keine Konsolidierung der Zahlungsinformationen aus zwei Dokumenten vornehmen. Ich benötige die zweite Experteneinschätzung, um:\n\n1. Die Schadensummen zu vergleichen\n2. Eventuelle Abweichungen zu analysieren\n3. Die Zahlungsinformationen zu konsolidieren\n4. Eine fundierte Gesamteinschätzung zu erstellen\n\nBitte stellen Sie die zweite Experteneinschätzung zur Verfügung, damit ich die gewünschte Analyse durchführen kann.",
        //     "paymentOverview": {
        //         "totalAmount": 0.0,
        //         "reimbursementAmount": 0.0,
        //         "payments": [{
        //             "type": "OTHER",
        //             "info": "Keine Zahlungsinformationen verfügbar - zweite Experteneinschätzung erforderlich",
        //             "amount": 0.0
        //         }]
        //     },
        //     "coverage": {
        //         "policyNumber": "WW-12222 334 /A",
        //         "insured": "Hubert Maier",
        //         "insuredObject": "Garten des Versicherungsnehmers",
        //         "summary": "Laut den Versicherungsunterlagen besteht eine Basis-Hausratversicherung. Während die allgemeinen Bedingungen (AVB) Vandalismusschäden nur bei Einbruch in Versicherungsräumlichkeiten decken, erweitern die besonderen Bedingungen (BVB G23) den Schutz explizit auf Vandalismusschäden an Gartenzwergen im Außenbereich. Die Entschädigung erfolgt zum Neuwert mit Höchstgrenzen von 500 EUR pro Gartenzwerg und 3.000 EUR gesamt. Voraussetzung ist eine polizeiliche Anzeige innerhalb von 48 Stunden nach Schadensfeststellung.\n\nBeurteilung: Der Schaden ist grundsätzlich durch die BVB G23 gedeckt, sofern eine fristgerechte polizeiliche Anzeige erfolgt ist. Da diese Information fehlt, muss der Versicherungsnehmer noch nachweisen, dass er die Anzeige rechtzeitig erstattet hat. Die Schadenhöhe von 400 EUR liegt innerhalb der Entschädigungsgrenzen. Eine Prüfung der Haftpflicht ist hier nicht relevant, da es sich um einen Vandalismusschaden am eigenen Eigentum handelt.",
        //         "conditionSources": [{
        //             "source": "Allgemeine Bedingungen für die Haushaltversicherung",
        //             "conditionType": "AVB",
        //             "version": "ABH 2022"
        //         }],
        //         "conditions": [{
        //             "source": "AVB Artikel 14 Punkt 5.1.4",
        //             "title": "Versicherte Gefahren - Einbruchdiebstahl und Vandalismus",
        //             "description": "Vandalismusschäden sind nur versichert, wenn der Täter anlässlich eines Einbruchdiebstahls in die Versicherungsräumlichkeiten eingedrungen ist.",
        //             "relevanceReason": "Nach AVB sind Vandalismusschäden nur bei Einbruch in Versicherungsräumlichkeiten versichert, wird aber durch BVB erweitert",
        //             "satisfied": "NOT_SATISFIED",
        //             "satisfiedReason": "Der Garten zählt nicht zu den Versicherungsräumlichkeiten, aber BVB erweitert Deckung"
        //         }, {
        //             "source": "BVB G23 Punkt 1",
        //             "title": "Erweiterter Versicherungsschutz für Gartenzwerge",
        //             "description": "In Erweiterung von Artikel 16 ABH wird für Gartenzwerge und ähnliche Gartenfiguren, die durch Vandalismus beschädigt oder zerstört werden, Ersatz zum Neuwert geleistet.",
        //             "relevanceReason": "BVB erweitert Versicherungsschutz explizit auf Vandalismusschäden an Gartenzwergen",
        //             "satisfied": "SATISFIED",
        //             "satisfiedReason": "Vandalismusschaden an Gartenzwergen liegt vor"
        //         }, {
        //             "source": "BVB G23 Punkt 2",
        //             "title": "Polizeiliche Anzeigepflicht",
        //             "description": "Bei Schäden durch Vandalismus ist eine polizeiliche Anzeige unverzüglich, spätestens jedoch innerhalb von 48 Stunden nach Feststellung des Schadens, zu erstatten.",
        //             "relevanceReason": "Fristgerechte Anzeige ist Voraussetzung für Versicherungsschutz",
        //             "satisfied": "UNDETERMINED",
        //             "satisfiedReason": "Keine Information über erfolgte Anzeige vorhanden"
        //         }],
        //         "limits": [{
        //             "source": "BVB G23 Punkt 1",
        //             "title": "Neuwertersatz für vandalisierte Gartenzwerge",
        //             "description": "Die Entschädigungshöchstgrenze beträgt EUR 500,00 pro Gartenzwerg und EUR 3.000,00 gesamt pro Schadenereignis.",
        //             "relevanceReason": "BVB sieht Neuwertersatz für Vandalismusschäden vor",
        //             "deductible": "0",
        //             "maxInsuredSum": "3000.00 EUR"
        //         }]
        //     },
        //     "metadata": null,
        //     "accountingDocuments": null,
        //     "attachments": null,
        //     "contracts": null,
        //     "sources": [],
        //     "createdAt": "2025-03-02T12:51:30.043Z",
        //     "updatedAt": "2025-03-02T12:51:30.043Z",
        //     "currentStage": "COMPLETED",
        //     "scheduledStates": 10,
        //     "lastSuccessfulState": "BILLING_STATISTICS",
        //     "successfulStates": ["INITIAL", "MEDIASPLIT", "ROUTING", "CLASSIFICATION", "MEDIA", "COVERAGE", "CONTROL_QUESTION", "COMPLETENESS", "PAYMENT", "BILLING_STATISTICS"],
        //     "completeness": {
        //         "Vertragsnummer": "WW-12222 334 /A",
        //         "Name Versicherungsnehmer": "Hubert Maier",
        //         "Schadendatum / Ereignisdatum": "01.03.2025",
        //         "Leistungsart / Kategorie": "Einbruch / Vandalismus",
        //         "Beschreibung Schadenhergang": "Einbruch in den Garten nach einem Zeltfest, Gartenzwerge wurden beschädigt.",
        //         "Ereignisland": "fehlt",
        //         "Ereignisort": "Garten des Versicherungsnehmers  (Verweis auf versicherte Adresse)",
        //         "Schadenfoto": "fehlt",
        //         "Name, Kontaktdaten eventuell Beteiligter (Geschädigter)": "fehlt"
        //     },
        //     "completenessData": {
        //         "completenessItems": [{
        //             "key": "policyNumber",
        //             "label": "Vertragsnummer",
        //             "reason": "Policennummer WW-12222 334 /A ist in der Meldung angegeben",
        //             "complete": true
        //         }, {
        //             "key": "insuredName",
        //             "label": "Name Versicherungsnehmer",
        //             "reason": "Versicherungsnehmer Hubert Maier ist angegeben",
        //             "complete": true
        //         }, {
        //             "key": "lossDate",
        //             "label": "Schadendatum / Ereignisdatum",
        //             "reason": "Schadendatum 01.03.2025 ist aus der Angabe 'Gestern' ableitbar",
        //             "complete": true
        //         }, {
        //             "key": "insuranceType",
        //             "label": "Leistungsart / Kategorie",
        //             "reason": "Hausratversicherung als Basis-Hausratversicherung ist angegeben",
        //             "complete": true
        //         }, {
        //             "key": "damageDescription",
        //             "label": "Beschreibung Schadenhergang",
        //             "reason": "Beschreibung des Vandalismusschadens an Gartenzwergen nach Zeltfest vorhanden",
        //             "complete": true
        //         }, {
        //             "key": "lossCountry",
        //             "label": "Ereignisland",
        //             "reason": "Kein explizites Ereignisland angegeben",
        //             "complete": false
        //         }, {
        //             "key": "lossLocation",
        //             "label": "Ereignisort",
        //             "reason": "Garten des Versicherungsnehmers als Ereignisort angegeben",
        //             "complete": true
        //         }, {
        //             "key": "damagePhotos",
        //             "label": "Schadenfoto",
        //             "reason": "Keine Schadenfotos beigefügt",
        //             "complete": false
        //         }, {
        //             "key": "contacts",
        //             "label": "Name, Kontaktdaten eventuell Beteiligter",
        //             "reason": "Vandalismusschaden ohne bekannte Täter, keine weiteren Kontakte erforderlich",
        //             "complete": true
        //         }, {
        //             "key": "conditions",
        //             "label": "Unterlagen laut Vertragsbedingungen",
        //             "reason": "Polizeiliche Anzeige laut BVB G23 erforderlich, aber nicht nachgewiesen",
        //             "complete": false
        //         }, {
        //             "key": "informationQuality",
        //             "label": "Prüfung der Informationsqualität",
        //             "reason": "Angaben sind plausibel und konsistent",
        //             "complete": true
        //         }],
        //         "completenessMessage": "Sehr geehrter Herr Maier,\n\nvielen Dank für Ihre Schadenmeldung. Zur weiteren Bearbeitung Ihres Falls benötigen wir noch folgende Informationen:\n\n- Fotos der beschädigten Gartenzwerge (bitte aus verschiedenen Perspektiven)\n- Die polizeiliche Anzeige des Vorfalls (diese ist laut Versicherungsbedingungen innerhalb von 48 Stunden nach Feststellung erforderlich)\n- Die erwähnten Rechnungen der Gartenzwerge\n- Die genaue Adresse des Schadenorts\n\nBitte übermitteln Sie uns diese Informationen per E-Mail oder über unser Kundenportal.\n\nMit freundlichen Grüßen"
        //     }
        // });
    };

    const fetchAllAttachments = async claim => {
        for (let i = 0; i < claim?.attachments?.length; i++) {
            await fetchPreview(claim?.attachments[i]);
        };
    };

    const fetchPreview = async (attachment) => {
        // setLoading(true);
        for (let i = 0; i < attachment?.src?.length; i++) {
            const current = attachment?.src[i] ?? undefined;
            if (preview.current.filter(p => p.id === current)?.length === 0) {
                await axiosInstance.current({
                    url: `/claims/${claimUUIDfromURL || claimUUIDfromStorage}/${current}`,
                    method: "GET",
                    responseType: "blob"
                }).then((res) => {
                    const previewObj = {
                        id: current,
                        data: URL.createObjectURL(res.data)
                    }
                    preview.current.push(previewObj);
                }).catch(() => setFailedAttachments([...failedAttachments, attachment?.attachmentUUID]));
            };
        };
        // setLoading(false);
    };

    const handleListClaim = () => setOpenListClaim(!openListClaim);
    const handleListAttachments = () => setOpenListAttachments(!openListAttachments);

    const getMatchingPhotos = () => {
        let matching = [];
        let blobOnly = [];
        for (let o = 0; o < claim?.attachments?.length; o++) {
            if (claim?.attachments[o]?.documentType === "Foto des Schadens") {
                for (let i = 0; i < preview.current?.length; i++) {
                    if (claim.attachments[o]?.src.filter(s => s === preview.current[i]?.id)?.length > 0 && preview.current[i]?.data) {
                        const alreadyAdded = matching.filter(m => claim?.attachments[o]?.src.filter(s => s === m.id)?.length > 0);
                        if (alreadyAdded?.length === 0) {
                            matching.push(preview.current[i]);
                        };
                    };
                };
            };
        };
        if (matching?.length === 0) {
            const fallback = {
                id: "fallback-preview",
                data: theme.palette.mode === "dark" ? noPreviewDarkURL.current : noPreviewLightURL.current
            };
            for (let i = 0; i < claim?.attachments?.length; i++) {
                if (claim?.attachments[i]?.documentType === "Foto des Schadens") {
                    matching.push(fallback);
                };
            };
        }
        for (let i = 0; i < matching?.length; i++) {
            blobOnly.push(matching[i].data);
        };
        return blobOnly;
    };

    const getContent = (attachments) => {
        if (attachments) {
            let matching = [];
            for (let i = 0; i < attachments?.length; i++) {
                if (attachments[i]?.documentType === "Foto des Schadens") {
                    matching.push(attachments[i]);
                }
            };
            return matching;
        };
        return [];
    }

    const openOverview = () => {
        viewHandlerRef.current.openOverview();
        if (belowsm) {
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };
    };

    const openCompleteness = () => {
        viewHandlerRef.current.openCompleteness();
        if (belowsm) {
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };
    };

    const openPolicy = () => {
        viewHandlerRef.current.openPolicy();
        if (belowsm) {
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };
    };

    const openCoverage = () => {
        viewHandlerRef.current.openCoverage();
        if (belowsm) {
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };
    };

    const openPayment = () => {
        viewHandlerRef.current.openPayment();
        if (belowsm) {
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };
    };

    const openInvoice = () => {
        viewHandlerRef.current.openInvoice();
        if (belowsm) {
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };
    };

    const openMessage = () => {
        viewHandlerRef.current.handleMessage();
        if (belowsm) {
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };
    };

    const openPhoto = () => {
        viewHandlerRef.current.handlePhoto(getContent(claim?.attachments), getMatchingPhotos());
        if (belowsm) {
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };
    };

    const backToMyClaims = () => navigate("/claim/myclaims");

    const AttachementListItem = ({ displayName, attachment }) => {

        const getMatching = () => {
            let matching = [];
            for (let i = 0; i < preview.current?.length; i++) {
                if ((preview.current[i]?.id === attachment?.attachmentUUID || (attachment.src && attachment?.src?.filter(s => s === preview.current[i]?.id)?.length > 0)) && preview.current[i]?.data) {
                    matching.push(preview.current[i].data);
                };
            };
            if (matching?.length === 0) {
                matching.push(theme.palette.mode === "dark" ? noPreviewDarkURL.current : noPreviewLightURL.current);
            };
            return matching;
        };

        const openAttachment = () => {
            viewHandlerRef.current.handleAttachment(attachment, getMatching());
            if (belowsm) {
                setOpenListClaim(false);
                setOpenListAttachments(false);
            }
        };

        if (attachment?.documentType === "Foto des Schadens") {
            return;
        }

        return (
            <ListItemButton onClick={(event) => { openAttachment(event) }} disabled={error || !claim?.claimUUID} sx={{ py: .25, pl: 4, borderRadius: "6px" }}>
                <Tooltip title={displayName} placement="right" sx={{ display: belowmd ? "block" : "none" }}>
                    <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{displayName}</Typography>} />
                </Tooltip>
                <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{displayName}</Typography>} sx={{ display: belowmd ? "none" : "block" }} />
            </ListItemButton>
        )
    };

    if (belowsm) {
        return (
            <Fragment>
                <Grid container sx={{ p: 2, pt: 0, height: belowsm ? "calc(100vh - 277px)" : belowmd ? "calc(100vh - 178px)" : "calc(100vh - 124px)", visibility: loading && !claim?.claimUUID ? "hidden" : "visible" }}>
                    <Paper
                        elevation={2}
                        sx={{
                            mt: 2,
                            width: "100%",
                            color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary",
                            background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                            borderRadius: "14.362px",
                            boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none",
                        }}
                    >
                        <List disablePadding>
                            <ListItemButton onClick={handleListClaim} sx={{ p: 0, px: 1, borderTopLeftRadius: "14.362px", borderTopRightRadius: "14.362px", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                                <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{t("claimTitle")}</Typography>} />
                                <IconButton sx={{ transform: openListClaim ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 250ms ease-in-out' }}>
                                    <TriangleIcon sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", ml: 1, mt: .5 }} />
                                </IconButton>
                            </ListItemButton>
                            <Collapse in={openListClaim} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton onClick={openOverview} disabled={error || !claim?.claimUUID} sx={{ py: .25, pl: 4, borderRadius: "6px" }}>
                                        <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{t("overview")}</Typography>} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openCompleteness} disabled={claimMemo?.completeness === null || error || !claim?.claimUUID} sx={{ py: .25, pl: 4, borderRadius: "6px" }}>
                                        <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{t("completeness")}</Typography>} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openPolicy} disabled={!claim?.claimUUID} sx={{ py: .25, pl: 4, borderRadius: "6px", display: claimMemo?.policySummary === null || error ? "none" : "flex" }}>
                                        <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{t("policy")}</Typography>} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openCoverage} disabled={claimMemo?.coverage === null || error || !claim?.claimUUID} sx={{ py: .25, pl: 4, borderRadius: "6px" }}>
                                        <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{t("coverage")}</Typography>} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openPayment} disabled={claimMemo?.paymentOverview === null || claimMemo?.paymentSummary === null || error || !claim?.claimUUID} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <ListItemText primary={<Typography noWrap color="inherit">{t("paymentTitle")}</Typography>} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openInvoice} disabled={claimMemo?.accountingDocuments === null || error || !claim?.claimUUID} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <ListItemText primary={<Typography noWrap color="inherit">{claimMemo?.accountingDocuments?.length > 1 ? t("invoices") : t("invoice")}</Typography>} />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                            <ListItemButton onClick={handleListAttachments} sx={{ p: 0, px: 1, borderBottom: openListAttachments ? 1 : 0, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                                <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{claimMemo?.attachments?.length > 0 ? t("attachmentsText") : t("attachmentText")}</Typography>} />
                                <IconButton sx={{ transform: openListAttachments ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 250ms ease-in-out' }}>
                                    <TriangleIcon sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", ml: 1, mt: .5 }} />
                                </IconButton>
                            </ListItemButton>
                            <Collapse in={openListAttachments} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding sx={{ height: "100%" }}>
                                    <ListItemButton onClick={openMessage} disabled={error || !claim?.claimUUID} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{t("announcement")}</Typography>} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openPhoto} disabled={error || !claim?.claimUUID} sx={{ py: .25, pl: 4, borderRadius: "6px", display: getMatchingPhotos()?.length > 0 ? "flex" : "none" }}>
                                        <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{multiplePhotos ? t("claimPhotos") : t("claimPhoto")}</Typography>} />
                                    </ListItemButton>
                                    {claimMemo?.attachments?.map((a, i) => { return <AttachementListItem key={`attachment-${i}`} displayName={a?.documentType ?? a?.filename ?? `Anhang ${i + 1}`} attachment={a} /> })}
                                </List>
                            </Collapse>
                        </List>
                    </Paper>
                    <Grid item xs={12} sx={{ height: "calc(100vh - 277px)" }}>
                        {error ?
                            <Container sx={{ height: "100%", width: "90vw", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <Typography color={theme.palette.text.primary}>{failed ? t("failedView") : t("unavailableView")}</Typography>
                                <Grid container columnGap={.5} sx={{ display: "flex", justifyContent: "center" }}>
                                    <Grid item>
                                        <Typography color={theme.palette.text.primary}>{t("backTo")}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography onClick={backToMyClaims} color={theme.palette.mode === "dark" ? "primary.light" : "primary.main"} sx={{ textDecoration: "underline", cursor: "pointer" }}>{t("myClaims")}</Typography>
                                    </Grid>
                                </Grid>
                            </Container>
                            :
                            <ViewHandler ref={viewHandlerRef} />
                        }
                    </Grid>
                </Grid>
                <SnackBar ref={SnackBarRef} />
                <Backdrop
                    sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Grid container sx={{ p: 2, pt: 0, height: belowsm ? "calc(100vh - 277px)" : belowmd ? "calc(100vh - 178px)" : "calc(100vh - 124px)", maxWidth: "1440px", visibility: loading && !claim?.claimUUID ? "hidden" : "visible" }}>
                <Grid item xs={2} sx={{ height: belowmd && !belowsm ? "calc(100vh - 218px)" : "calc(100vh - 160px)", mt: 2, pb: 1 }}>
                    <Paper
                        elevation={2}
                        sx={{
                            color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary",
                            height: "100%",
                            background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                            borderRadius: "14.362px",
                            boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none",
                        }}
                    >
                        <List disablePadding sx={{ height: "100%", overflow: "auto" }}>
                            <ListItemButton onClick={handleListClaim} sx={{ pb: 0, pt: 1, borderTopLeftRadius: "14.362px", borderTopRightRadius: "14.362px", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                                <Tooltip title={t("claimTitle")} placement="right" sx={{ display: belowmd ? "block" : "none" }}>
                                    <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color="inherit">{t("claimTitle")}</Typography>} />
                                </Tooltip>
                                <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color="inherit">{t("claimTitle")}</Typography>} sx={{ display: belowmd ? "none" : "block" }} />
                                <Box sx={{ transition: 'transform 250ms ease-in-out', transform: openListClaim ? 'rotate(0deg)' : 'rotate(180deg)' }}>
                                    <TriangleIcon sx={{ color: "inherit", ml: 1, mt: .5 }} />
                                </Box>
                            </ListItemButton>
                            <Collapse in={openListClaim} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton onClick={openOverview} disabled={error || !claim?.claimUUID} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <Tooltip title={t("overview")} placement="right" sx={{ display: belowmd ? "block" : "none" }}>
                                            <ListItemText primary={<Typography noWrap color="inherit">{t("overview")}</Typography>} />
                                        </Tooltip>
                                        <ListItemText primary={<Typography noWrap color="inherit">{t("overview")}</Typography>} sx={{ display: belowmd ? "none" : "block" }} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openCompleteness} disabled={claimMemo?.completeness === null || error || !claim?.claimUUID} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <Tooltip title={t("completeness")} placement="right" sx={{ display: belowmd ? "block" : "none" }}>
                                            <ListItemText primary={<Typography noWrap color="inherit">{t("completeness")}</Typography>} />
                                        </Tooltip>
                                        <ListItemText primary={<Typography noWrap color="inherit">{t("completeness")}</Typography>} sx={{ display: belowmd ? "none" : "block" }} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openPolicy} disabled={!claim?.claimUUID} sx={{ py: .25, pl: 4, borderRadius: "4px", display: claimMemo?.policySummary === null || error ? "none" : "flex" }}>
                                        <Tooltip title={t("policy")} placement="right" sx={{ display: belowmd ? "block" : "none" }}>
                                            <ListItemText primary={<Typography noWrap color="inherit">{t("policy")}</Typography>} />
                                        </Tooltip>
                                        <ListItemText primary={<Typography noWrap color="inherit">{t("policy")}</Typography>} sx={{ display: belowmd ? "none" : "block" }} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openCoverage} disabled={claimMemo?.coverage === null || error || !claim?.claimUUID} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <Tooltip title={t("coverage")} placement="right" sx={{ display: belowmd ? "block" : "none" }}>
                                            <ListItemText primary={<Typography noWrap color="inherit">{t("coverage")}</Typography>} />
                                        </Tooltip>
                                        <ListItemText primary={<Typography noWrap color="inherit">{t("coverage")}</Typography>} sx={{ display: belowmd ? "none" : "block" }} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openPayment} disabled={claimMemo?.paymentOverview === null || claimMemo?.paymentSummary === null || error || !claim?.claimUUID} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <Tooltip title={t("paymentTitle")} placement="right" sx={{ display: belowmd ? "block" : "none" }}>
                                            <ListItemText primary={<Typography noWrap color="inherit">{t("paymentTitle")}</Typography>} />
                                        </Tooltip>
                                        <ListItemText primary={<Typography noWrap color="inherit">{t("paymentTitle")}</Typography>} sx={{ display: belowmd ? "none" : "block" }} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openInvoice} disabled={claimMemo?.accountingDocuments === null || error || !claim?.claimUUID} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <Tooltip title={claimMemo?.accountingDocuments?.length > 1 ? t("invoices") : t("invoice")} placement="right" sx={{ display: belowmd ? "block" : "none" }}>
                                            <ListItemText primary={<Typography noWrap color="inherit">{claimMemo?.accountingDocuments?.length > 1 ? t("invoices") : t("invoice")}</Typography>} />
                                        </Tooltip>
                                        <ListItemText primary={<Typography noWrap color="inherit">{claimMemo?.accountingDocuments?.length > 1 ? t("invoices") : t("invoice")}</Typography>} sx={{ display: belowmd ? "none" : "block" }} />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                            <ListItemButton onClick={handleListAttachments} sx={{ py: 0, borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                                <Tooltip title={claimMemo?.attachments?.length > 0 ? t("attachmentsText") : t("attachmentText")} placement="right" sx={{ display: belowmd ? "block" : "none" }}>
                                    <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color="inherit">{claimMemo?.attachments?.length > 0 ? t("attachmentsText") : t("attachmentText")}</Typography>} />
                                </Tooltip>
                                <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color="inherit">{claimMemo?.attachments?.length > 0 ? t("attachmentsText") : t("attachmentText")}</Typography>} sx={{ display: belowmd ? "none" : "block" }} />
                                <Box sx={{ transition: 'transform 250ms ease-in-out', transform: openListAttachments ? 'rotate(0deg)' : 'rotate(180deg)' }}>
                                    <TriangleIcon sx={{ color: "inherit", ml: 1, mt: .5 }} />
                                </Box>
                            </ListItemButton>
                            <Collapse in={openListAttachments} timeout="auto" unmountOnExit>
                                <ListItemButton onClick={openMessage} disabled={error || !claim?.claimUUID} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                    <Tooltip title={t("announcement")} placement="right" sx={{ display: belowmd ? "block" : "none" }}>
                                        <ListItemText primary={<Typography noWrap color="inherit">{t("announcement")}</Typography>} />
                                    </Tooltip>
                                    <ListItemText primary={<Typography noWrap color="inherit">{t("announcement")}</Typography>} sx={{ display: belowmd ? "none" : "block" }} />
                                </ListItemButton>
                                <List component="div" disablePadding sx={{ height: "100%" }}>
                                    <ListItemButton onClick={openPhoto} disabled={error || !claim?.claimUUID} sx={{ py: .25, pl: 4, borderRadius: "4px", display: getMatchingPhotos()?.length > 0 ? "flex" : "none" }}>
                                        <Tooltip title={getMatchingPhotos()?.length > 1 ? t("claimPhotos") : t("claimPhoto")} placement="right" sx={{ display: belowmd ? "block" : "none" }}>
                                            <ListItemText primary={<Typography noWrap color="inherit">{getMatchingPhotos()?.length > 1 ? t("claimPhotos") : t("claimPhoto")}</Typography>} />
                                        </Tooltip>
                                        <ListItemText primary={<Typography noWrap color="inherit">{getMatchingPhotos()?.length > 1 ? t("claimPhotos") : t("claimPhoto")}</Typography>} sx={{ display: belowmd ? "none" : "block" }} />
                                    </ListItemButton>
                                    {claimMemo?.attachments?.map((a, i) => { return <AttachementListItem key={`attachment-${i}`} displayName={a?.documentType ?? a?.filename ?? `Anhang ${i + 1}`} attachment={a} /> })}
                                </List>
                            </Collapse>
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={10} sx={{ height: belowmd ? "calc(100vh - 178px)" : "calc(100vh - 124px)", pl: 2, pt: 2 }}>
                    {error ?
                        <Container sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <Typography color={theme.palette.text.primary}>{failed ? t("failedView") : t("unavailableView")}</Typography>
                            <Grid container columnGap={.5} sx={{ display: "flex", justifyContent: "center" }}>
                                <Grid item>
                                    <Typography color={theme.palette.text.primary}>{t("backTo")}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography onClick={backToMyClaims} color={theme.palette.mode === "dark" ? "primary.light" : "primary.main"} sx={{ textDecoration: "underline", cursor: "pointer" }}>{t("myClaims")}</Typography>
                                </Grid>
                            </Grid>
                        </Container>
                        :
                        <ViewHandler ref={viewHandlerRef} />
                    }
                </Grid>
            </Grid>
            <SnackBar ref={SnackBarRef} />
            <Backdrop
                sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Fragment>
    );

}, (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data));
// Complete.whyDidYouRender = true
export default withAuthenticationRequired(Complete, {
    OnRedirecting: () => {
        const redirect = `${window.location?.pathname ?? ""}${window.location?.search ?? ""}`;
        localStorage.setItem("redirect", redirect);
    }
});