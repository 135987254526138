import { Backdrop, CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Logout = () => {

    const theme = useTheme();

    const navigate = useNavigate();

    const { t } = useTranslation();

    const [open, setOpen] = useState(true);

    useEffect(() => {
        setOpen(true);
        const timer = new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 3000);
        });

        timer.then(() => {
            setOpen(false);
            navigate("/login");
        })
        //eslint-disable-next-line
    }, []);

    return (
        <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: theme.palette.background.default }}
            open={open}
        >
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <CircularProgress
                    size={72}
                    thickness={2}
                    sx={{ color: theme.palette.mode === "dark" ? "#fff" : "#000" }}
                />
                <Typography color="text.primary" sx={{ mt: 2 }}>{t("youAreBeingLoggedOut")}</Typography>
            </Grid>
        </Backdrop>
    );
};

export default Logout;