import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

const InsuranceTypeDialog = forwardRef(({ update }, ref) => {

    const theme = useTheme();

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [insuranceType, setInsuranceType] = useState("");

    useImperativeHandle(ref, () => ({
        open() {
            setOpen(true);
        }
    }));

    const handleClose = () => {
        setOpen(false);
    };

    const convertToId = value => {
        if (!value) return '';

        let newValue = value.toLowerCase();

        const replacements = {
            'ä': 'ae',
            'ö': 'oe',
            'ü': 'ue',
            'ß': 'ss'
        };

        for (const [char, replacement] of Object.entries(replacements)) {
            newValue = newValue.replace(new RegExp(char, 'g'), replacement);
        }

        newValue = newValue
            .replace(/\s+/g, '-')
            .replace(/[^a-z0-9-]/g, '')
            .replace(/-+/g, '-');

        return newValue;
    };

    const handleNewInsuranceTypeObject = () => {
        const insurancetype = convertToId(insuranceType);

        const newInsuranceType = {
            // id is set for frontend logic
            "id": insurancetype,
            "insuranceType": insurancetype,
            "label": insuranceType,
            "products": [],
            "damagedObjectCategories": []
        };

        update(newInsuranceType);
        setInsuranceType("");
        setOpen(false);
    };

    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: { borderRadius: "14.362px" }
            }}
        >
            <DialogTitle fontWeight={600} sx={{ textAlign: "center" }}>{t("addNewInsuranceType")}</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ lineHeight: "normal", textAlign: "center" }}>{t("enterNewInsuranceType")}</Typography>
                <TextField
                    // label={t("serviceKeySingle")}
                    value={insuranceType}
                    onChange={(event) => setInsuranceType(event.target.value)}
                    required
                    slotProps={{
                        inputLabel: {
                            shrink: true
                        }
                    }}
                    sx={{
                        mt: 2,
                        width: "360px",
                        "& label.Mui-focused": {
                            color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                        },
                        "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                                borderColor: theme.palette.mode === "dark" ? theme.palette.action.disabled : theme.palette.action.active,
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                            }
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleNewInsuranceTypeObject} disableElevation disabled={insuranceType === ""} sx={{ textTransform: "none", borderRadius: "8px", bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main", "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" } }}>{t("create")}</Button>
            </DialogActions>
        </Dialog>
    );

});

export default InsuranceTypeDialog;