import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { Backdrop, Box, Button, CircularProgress, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { isEqual } from 'lodash';
import { Fragment, forwardRef, memo, useContext, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ClaimContext } from '../../utils/ClaimContext';
import { useAxios } from "../../utils/useAxios";
import Attachment from "./Attachment/Attachment";
import Message from "./Attachment/Message";
import Photo from "./Attachment/Photo";
import Completeness from './Completeness/Completeness';
import Coverage from './Coverage/Coverage';
import Invoice from './Invoice/Invoice';
import Overview from "./Overview/Overview";
import Payment from './Payment';
import Policy from './Policy';

const ViewHandler = memo(forwardRef((props, ref) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const navigate = useNavigate();

    const [photo, setPhoto] = useState();
    const [attachment, setAttachment] = useState();
    const [photoPreview, setPhotoPreview] = useState([]);
    const [attachmentPreview, setAttachmentPreview] = useState([]);
    const [displayOverview, setDisplayOverview] = useState(true);
    const [displayMessage, setDisplayMessage] = useState(false);
    const [displayPhoto, setDisplayPhoto] = useState(false);
    const [displayAttachment, setDisplayAttachment] = useState(false);
    //eslint-disable-next-line
    const [displaySource, setDisplaySource] = useState(true);
    const [displayPolicy, setDisplayPolicy] = useState(false);
    const [displayCoverage, setDisplayCoverage] = useState(false);
    const [displayCompleteness, setDisplayCompleteness] = useState(false);
    const [displayPayment, setDisplayPayment] = useState(false);
    const [displayInvoice, setDisplayInvoice] = useState(false);
    const [loading, setLoading] = useState(false);

    const viewRef = useRef(null);

    const { claim } = useContext(ClaimContext);
    const finished = claim?.successfulStates?.length === claim?.scheduledStates || claim?.lastSuccessfulState === "ERROR" || claim?.lastSuccessfulState === "END_ERROR";
    const { t } = useTranslation();

    const axiosInstance = useAxios();

    //eslint-disable-next-line
    const fetchPdf = async () => {
        setLoading(true);

        await axiosInstance.current({
            url: `/pdf/${claim?.claimUUID}`,
            method: "GET",
            responseType: "blob"
        }).then((res) => {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `pdf-report-${claim?.policyNumber !== "" || null ? claim?.policyNumber : claim?.claimUUID}.pdf`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        }).catch(err => console.error(err));
        setLoading(false);
    };

    const fetchSource = async (data) => {
        setLoading(true);

        await axiosInstance.current({
            url: `${data?.path ?? ""}?filename=${data?.filename ? encodeURIComponent(data?.filename) : ""}&contentType=${data?.contentType ? encodeURIComponent(data?.contentType) : ""}`,
            method: "GET",
            responseType: "blob"
        }).then((res) => {
            const blob = new Blob([res.data], { type: data?.contentType || "text/plain" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${data?.filename || "download-failed.txt"}`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        }).catch(err => console.error(err));
        setLoading(false);
    };

    const fetchSourcesFromClaim = async () => {
        let sources = [];
        for (let i = 0; i < claim?.sources?.length; i++) {
            const sourcePath = `/claims/${claim?.claimUUID}/source/${claim?.sources[i]?.attachmentUUID}`;
            const sourceObj = {
                "path": sourcePath,
                "filename": claim?.sources[i]?.filename,
                "contentType": claim?.sources[i]?.contentType
            };
            sources.push(sourceObj);
        };
        for (let i = 0; i < sources?.length; i++) {
            await fetchSource(sources[i]);
        };
    };

    useImperativeHandle(ref, () => ({
        openOverview() {
            viewRef.current.scrollTo(0, 0);
            setDisplayOverview(true);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayAttachment(false);
            // setDisplaySource(false);
            setDisplayPolicy(false);
            setDisplayCoverage(false);
            setDisplayCompleteness(false);
            setDisplayPayment(false);
            setDisplayInvoice(false);
        },
        openCompleteness() {
            viewRef.current.scrollTo(0, 0);
            setDisplayCompleteness(true);
            setDisplayPolicy(false);
            setDisplayCoverage(false);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayAttachment(false);
            // setDisplaySource(false);
            setDisplayPayment(false);
            setDisplayInvoice(false);
        },
        openPolicy() {
            viewRef.current.scrollTo(0, 0);
            setDisplayPolicy(true);
            setDisplayCoverage(false);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayAttachment(false);
            // setDisplaySource(false);
            setDisplayCompleteness(false);
            setDisplayPayment(false);
            setDisplayInvoice(false);
        },
        openCoverage() {
            viewRef.current.scrollTo(0, 0);
            setDisplayCoverage(true);
            setDisplayPolicy(false);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayAttachment(false);
            // setDisplaySource(false);
            setDisplayCompleteness(false);
            setDisplayPayment(false);
            setDisplayInvoice(false);
        },
        openPayment() {
            viewRef.current.scrollTo(0, 0);
            setDisplayPayment(true);
            setDisplayInvoice(false);
            setDisplayPolicy(false);
            setDisplayCoverage(false);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayAttachment(false);
            // setDisplaySource(false);
            setDisplayCompleteness(false);
        },
        openInvoice() {
            viewRef.current.scrollTo(0, 0);
            setDisplayInvoice(true);
            setDisplayPayment(false);
            setDisplayPolicy(false);
            setDisplayCoverage(false);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayAttachment(false);
            // setDisplaySource(false);
            setDisplayCompleteness(false);
        },
        handleMessage() {
            viewRef.current.scrollTo(0, 0);
            setDisplayMessage(true);
            setDisplayOverview(false);
            setDisplayPhoto(false);
            setDisplayAttachment(false);
            // setDisplaySource(false);
            setDisplayPolicy(false);
            setDisplayCoverage(false);
            setDisplayCompleteness(false);
            setDisplayPayment(false);
            setDisplayInvoice(false);
        },
        handlePhoto(data, preview) {
            viewRef.current.scrollTo(0, 0);
            setPhoto(data);
            setPhotoPreview(preview);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayAttachment(false);
            setDisplayPhoto(true);
            // setDisplaySource(true);
            setDisplayPolicy(false);
            setDisplayCoverage(false);
            setDisplayCompleteness(false);
            setDisplayPayment(false);
            setDisplayInvoice(false);
        },
        handleAttachment(data, preview) {
            viewRef.current.scrollTo(0, 0);
            setAttachment(data);
            setAttachmentPreview(preview);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayAttachment(true);
            // setDisplaySource(true);
            setDisplayPolicy(false);
            setDisplayCoverage(false);
            setDisplayCompleteness(false);
            setDisplayPayment(false);
            setDisplayInvoice(false);
        }
    }));

    const closeView = () => {
        const source = sessionStorage.getItem("source") || "/claim/myclaims";
        navigate(source);
    };

    const handleMobileStageView = () => {
        if (finished) {
            return false;
        } else {
            return true;
        }
    };

    const LoadingView = () => {
        return (
            <Backdrop
                sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    };

    const NoView = () => {
        return (
            <Box sx={{ height: "100%", width: belowsm ? "90vw" : "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography noWrap color={theme.palette.text.primary}>Keine Ansicht ausgewählt.</Typography>
            </Box>
        );
    };

    const StageActionButtons = memo(({ component }) => {
        return (
            <Box sx={{ height: belowsm ? displaySource ? handleMobileStageView() ? "calc(100vh - 361px)" : "calc(100vh - 299px)" : handleMobileStageView() ? "calc(100vh - 366px)" : "calc(100vh - 304px)" : belowmd ? "calc(100vh - 223px)" : "calc(100vh - 165px)" }}>
                {component}
            </Box>
        );
    }, (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data));

    return (
        <Fragment>
            <Box ref={viewRef} sx={{ height: belowsm ? displaySource ? handleMobileStageView() ? "calc(100vh - 361px)" : "calc(100vh - 299px)" : handleMobileStageView() ? "calc(100vh - 366px)" : "calc(100vh - 304px)" : belowmd ? "calc(100vh - 223px)" : "calc(100vh - 165px)", overflow: "auto", mt: belowsm ? handleMobileStageView() ? 10 : 2 : 0, borderRadius: "14.362px" }}>
                {
                    displayOverview ?
                        finished ?
                            <Overview /> :
                            <StageActionButtons component={<Overview />} /> :
                        displayCompleteness ?
                            finished ?
                                <Completeness /> :
                                <StageActionButtons component={<Completeness />} /> :
                            displayPolicy ?
                                finished ?
                                    <Policy /> :
                                    <StageActionButtons component={<Policy />} /> :
                                displayCoverage ?
                                    finished ?
                                        <Coverage /> :
                                        <StageActionButtons component={<Coverage />} /> :
                                    displayPayment ?
                                        finished ?
                                            <Payment /> :
                                            <StageActionButtons component={<Payment />} /> :
                                        displayInvoice ?
                                            finished ?
                                                <Invoice /> :
                                                <StageActionButtons component={<Invoice />} /> :
                                            displayPhoto ?
                                                finished ?
                                                    <Photo data={photo} photoPreview={photoPreview} /> :
                                                    <StageActionButtons component={<Photo data={photo} photoPreview={photoPreview} />} /> :
                                                displayAttachment ?
                                                    finished ?
                                                        <Attachment data={attachment} attachmentPreview={attachmentPreview} /> :
                                                        <StageActionButtons component={<Attachment data={attachment} attachmentPreview={attachmentPreview} />} /> :
                                                    displayMessage ?
                                                        finished ?
                                                            <Message /> :
                                                            <StageActionButtons component={<Message />} /> :
                                                        <NoView />
                }
                <LoadingView />
            </Box>
            <Grid container justifyContent={belowsm ? "center" : "flex-end"} flexWrap={belowsm ? "wrap" : "nowrap"}>
                <Grid item xs={12} sm="auto" zeroMinWidth sx={{ display: "none" }}>
                    <Button
                        onClick={fetchPdf}
                        disableElevation
                        variant="contained"
                        size="large"
                        fullWidth={belowsm ? true : false}
                        startIcon={<PictureAsPdfOutlinedIcon />}
                        // disabled={claim?.flags?.claim === false || claim?.flags?.personalInjury}
                        disabled
                        sx={{
                            mt: 2,
                            mb: belowsm ? 0 : 2,
                            mr: belowsm ? 0 : 1,
                            textTransform: "none",
                            borderRadius: "8px",
                            bgcolor: theme.palette.widget.main,
                            "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                        }}>
                        <Typography fontSize="15px" fontWeight={500} noWrap>{t("pdfReport")}</Typography>
                    </Button>
                </Grid>
                <Grid item xs={12} sm="auto" zeroMinWidth>
                    <Button
                        onClick={fetchSourcesFromClaim}
                        disableElevation
                        variant="outlined"
                        size="large"
                        fullWidth={belowsm ? true : false}
                        startIcon={belowsm ? null : <DownloadForOfflineOutlinedIcon />}
                        endIcon={belowsm ? <DownloadForOfflineOutlinedIcon /> : null}
                        disabled={claim?.sources === null || !claim?.claimUUID}
                        sx={{
                            mt: belowsm ? 2 : 1.9,
                            mb: belowsm ? 0 : 2,
                            mr: belowsm ? 0 : 1,
                            textTransform: "none",
                            border: 1.5,
                            borderRadius: "8px",
                            borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            "&:hover": {
                                border: 1.5,
                                borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                            }
                        }}>
                        <Typography fontSize="15px" fontWeight={500} noWrap>{claim?.sources?.length === 1 ? t("downloadOne") : t("downloadAll")}</Typography>
                    </Button>
                </Grid>
                <Grid item xs={12} sm="auto" zeroMinWidth>
                    <Button
                        onClick={closeView}
                        disableElevation
                        variant="contained"
                        size="large"
                        fullWidth={belowsm ? true : false}
                        endIcon={<ArrowCircleRightOutlinedIcon />}
                        sx={{
                            mt: belowsm ? 1 : 2,
                            mb: 2,
                            textTransform: "none",
                            borderRadius: "8px",
                            bgcolor: theme.palette.widget.main,
                            "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                        }}>
                        <Typography fontSize="15px" fontWeight={500} noWrap>{t("closeView")}</Typography>
                    </Button>
                </Grid>
            </Grid>
        </Fragment>
    );

}), (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data));
export default ViewHandler;