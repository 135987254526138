import { Button, Dialog, DialogActions, DialogTitle, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

const InsuranceTypeDeleteDialog = forwardRef(({ updateDelete }, ref) => {

    const theme = useTheme();

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [label, setLabel] = useState("");

    useImperativeHandle(ref, () => ({
        delete(label) {
            setOpen(true);
            setLabel(label ?? t("unkown"));
        }
    }));

    const handleDelete = () => {
        updateDelete(label);
        setLabel("");
        setOpen(false);
    };

    const handleClose = () => {
        setLabel("");
        setOpen(false);
    };

    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: { borderRadius: "14.362px" }
            }}
        >
            <DialogTitle fontWeight={600} sx={{ textAlign: "center" }}>{t("deleteInsuranceTypeOrChild", { label: label })}</DialogTitle>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }} >
                <Button variant="contained" disableElevation fullWidth onClick={handleDelete} sx={{ textTransform: "none", bgcolor: "error.main", "&:hover": { bgcolor: "error.main" } }}>{t("confirm")}</Button>
                <Button variant="contained" disableElevation fullWidth onClick={handleClose} sx={{ textTransform: "none", bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main", "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" } }}>{t("cancel")}</Button>
            </DialogActions>
        </Dialog>
    );

});

export default InsuranceTypeDeleteDialog;