import { Avatar, Backdrop, Box, Button, CircularProgress, Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useAuth, hasAuthParams } from "react-oidc-context";
import { Navigate } from "react-router-dom";
import LogoIcon from '../icons/Logo';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Login = () => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));

    const { signinRedirect, signinSilent, isLoading, isAuthenticated, activeNavigator } = useAuth();

    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t("loginTitle");
        if (!hasAuthParams() &&
            !isAuthenticated && !activeNavigator && !isLoading &&
            !hasTriedSignin
        ) {
            signinSilent();
            setHasTriedSignin(true);
        }
        //eslint-disable-next-line
    }, [isAuthenticated, activeNavigator, isLoading, signinSilent, hasTriedSignin]);

    if (isAuthenticated) {
        return <Navigate replace to={localStorage.getItem("redirect") ?? "/claim/report"} />;
    };

    if (isLoading) {
        return (
            <Backdrop
                sx={{ zIndex: theme.zIndex.drawer + 1 }}
                invisible
                open={true}
            >
                <CircularProgress
                    size={108}
                    thickness={2}
                    sx={{ color: theme.palette.mode === "dark" ? "#fff" : "#000" }}
                />
            </Backdrop>
        )
    }

    if (!isAuthenticated) {
        return (
            <Container sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh", width: belowsm ? "320px" : "350px", maxWidth: "1440px" }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 2,
                }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            height: "64px",
                            width: "64px",
                            ml: belowsm ? -1 : -1.5,
                            mr: 2,
                            p: 1
                        }}>
                        {/* <img alt="logo" src={swiftRobin} style={{ height: "65%" }} /> */}
                        <LogoIcon />
                    </Avatar>
                    <Typography variant="h4" fontWeight={700} color={theme.palette.text.primary} sx={{ ml: 2, fontSize: belowsm ? "32px" : "34px" }}>Swift Robin</Typography>
                </Box>
                <Button
                    variant="contained"
                    disableElevation
                    onClick={signinRedirect}
                    size="large"
                    fullWidth
                    sx={{
                        textTransform: "none",
                        bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                        fontWeight: 600,
                        "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                    }}>
                    Anmelden
                </Button>
            </Container>
        );
    }
};

export default Login;