import { createContext, useMemo, useState } from "react";

export const ProcessingContext = createContext({
    processing: {},
    setProcessing: () => { }
});

export default function ClaimProvider({ children }) {

    const [processing, setProcessing] = useState({});

    const value = useMemo(
        () => ({ processing, setProcessing }),
        [processing]
    );

    return <ProcessingContext.Provider value={value}>{children}</ProcessingContext.Provider>
};